import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import SignUp from './components/connect/SignUp';
import SignIn from './components/connect/SignIn';
import Dashboard from './components/dashboard/Dashboard';
import AiChat from './components/dashboard/AiChat';
import Overview from './components/dashboard/Overview';
import DietPlan from './components/dashboard/DietPlan';
import Membership from './components/dashboard/Membership';
import MemberList from './components/dashboard/MemberList';
import AdminDashboard from './components/dashboard/AdminDashboard';
import MealPlans from './components/dashboard/MealPlans';
import Fitness from './components/dashboard/Fitness';
import Profile from './components/dashboard/Profile';
import Notifications from './components/dashboard/Notifications';
import ProfilePage from './pages/ProfilePage';
import OnBoarding from './components/connect/OnBoarding';
import AgeSelector from './components/connect/OnBoarding/AgeSelector';
import DietSelector from './components/connect/OnBoarding/DietSelector';
import FitnessLevel from './components/connect/OnBoarding/FitnessLevel';
import Help from './components/dashboard/Help';
import GenderComp from './components/connect/OnBoarding/GenderComp';
import GoalComp from './components/connect/OnBoarding/GoalComp';
import HeightComp from './components/connect/OnBoarding/HeightComp';
import WeightComp from './components/connect/OnBoarding/WeightComp';
import Details from './components/dashboard/Details';
import ChronicConditions from './components/dashboard/details/ChronicConditions';
import EnergyStress from './components/dashboard/details/EnergyLevel';
import HereditaryIssues from './components/dashboard/details/HereditaryIssues';
import LifeStyle from './components/dashboard/details/Lifestyle';
import LifeStyle2 from './components/dashboard/details/LifeStyle2';
import LifeStyle3 from './components/dashboard/details/LifeStyle3';
import Preference from './components/dashboard/details/Preference';
import Preference2 from './components/dashboard/details/Preference2';
import Preference3 from './components/dashboard/details/Preference3';
import SleepMeasures from './components/dashboard/details/SleepMeasures';
import UrineFlow from './components/dashboard/details/UrineFlow';
import OtherHealthIssues from './components/dashboard/details/OtherHealthIssues';
import MenstrualCycle from './components/dashboard/details/MenstrualCycle';
import MenstrualCycle2 from './components/dashboard/details/MenstrualCycle2';
import TargetWeightComp from './components/connect/OnBoarding/TargetWeightComp';
import { useEffect } from 'react';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const handleLogout = () => {
      // Navigate to login page
      navigate('/sign-in');
    };

    // Set a timer for 30 minutes (60 * 60 * 1000 = 1800000ms)
    const timeoutId = setTimeout(() => {
      handleLogout();
    }, 5 * 60 * 1000);

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [navigate]);
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/sign-in' element={<SignIn />} />
        {/* Start onboarding */}
        <Route path='/onboarding' element={<OnBoarding />} />
        <Route path='/age' element={<AgeSelector />} />
        <Route path='/diet' element={<DietSelector />} />
        <Route path='/fitness' element={<FitnessLevel />} />
        <Route path='/gender' element={<GenderComp />} />
        <Route path='/goal' element={<GoalComp />} />
        <Route path='/height' element={<HeightComp />} />
        <Route path='/weight' element={<WeightComp />} />
        <Route path='target' element={<TargetWeightComp />} />

        {/* End onboarding */}
        <Route path='/dashboard' element={<Dashboard />} >
          <Route index element={<Overview />} />
          <Route path="/dashboard/ai-chat" element={<AiChat />} />
          <Route path="/dashboard/diet-plan" element={<DietPlan />} />
          <Route path="/dashboard/membership" element={<Membership />} />
          <Route path="/dashboard/members-list" element={<MemberList />} />
          <Route path="/dashboard/admin-dashboard" element={<AdminDashboard />} />
          <Route path='/dashboard/meal-plans' element={<MealPlans />} />
          <Route path='/dashboard/fitness' element={<Fitness />} />
          <Route path='/dashboard/profile' element={<ProfilePage />} />
          <Route path='/dashboard/help' element={<Help />} />
          <Route path='/dashboard/notifications' element={<Notifications />} />
          <Route path='/dashboard/details' element={<Details />} />
          <Route path='/dashboard/chronic-conditions' element={<ChronicConditions />} />
          <Route path='/dashboard/energy-stress' element={<EnergyStress />} />
          <Route path='/dashboard/hereditary-issues' element={<HereditaryIssues />} />
          <Route path='/dashboard/lifestyle' element={<LifeStyle />} />
          <Route path='/dashboard/lifestyle2' element={<LifeStyle2 />} />
          <Route path='/dashboard/lifestyle3' element={<LifeStyle3 />} />
          <Route path='/dashboard/preference' element={<Preference />} />
          <Route path='/dashboard/preference2' element={<Preference2 />} />
          <Route path='/dashboard/preference3' element={<Preference3 />} />
          <Route path='/dashboard/sleep-measures' element={<SleepMeasures />} />
          <Route path='/dashboard/urine-flow' element={<UrineFlow />} />
          <Route path='/dashboard/other-issues' element={<OtherHealthIssues />} />
          {/* <Route path='/dashboard/cycle' element={<MenstrualCycle />} />
          <Route path='/dashboard/cycle2' element={<MenstrualCycle2 />} /> */}


        </Route>
        <Route path="*" element={<Home />} />

      </Routes>
    </div>
  );
}

export default App;
