import React, { useState } from 'react';
import ChangePassword from '../components/profile/ChangePassword';
import ProfileInfo from '../components/profile/ProfileInfo';
import NotificationComp from '../components/profile/NotificationComp';
import DeleteAccount from '../components/profile/DeleteAccount';

const ProfilePage = () => {
    const [activeTab, setActiveTab] = useState('profile');

    return (
        <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
            {/* Sidebar */}
            <aside className="w-full md:w-1/4 bg-rose-50 p-8">
                <nav className="space-y-4">
                    <div
                        className={`flex items-center space-x-2 cursor-pointer ${activeTab === 'profile' ? 'text-black' : ''}`}
                        onClick={() => setActiveTab('profile')}
                    >
                        <span className="text-xl">👤</span>
                        <span>Profile</span>
                    </div>
                    <div
                        className={`flex items-center space-x-2 cursor-pointer ${activeTab === 'password' ? 'text-black' : ''}`}
                        onClick={() => setActiveTab('password')}
                    >
                        <span className="text-xl">🔑</span>
                        <span>Password</span>
                    </div>
                    <div
                        className={`flex items-center space-x-2 cursor-pointer ${activeTab === 'notifications' ? 'text-black' : ''}`}
                        onClick={() => setActiveTab('notifications')}
                    >
                        <span className="text-xl">🔔</span>
                        <span>Notifications</span>
                    </div>
                    <div className={`flex items-center space-x-2 cursor-pointer ${activeTab === 'delete' ? 'text-black' : ''}`}
                        onClick={() => setActiveTab('delete')}>
                        <span className="text-xl">❌</span>
                        <span>Delete account</span>
                    </div>
                </nav>
            </aside>

            {/* Main Content */}
            <main className="w-full md:w-3/4 p-8">
                {activeTab === 'profile' && (
                    <ProfileInfo />
                )}

                {activeTab === 'password' && (
                    <>
                        <h1 className="text-2xl font-bold mb-6">Password</h1>

                        {/* Password Form */}
                        <ChangePassword />
                    </>
                )}
                {activeTab === 'notifications' && (
                    <NotificationComp />
                )}
                {activeTab === 'delete' && (
                    <DeleteAccount />
                )}
            </main>
        </div>
    );
};

export default ProfilePage;
