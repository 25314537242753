import { BackToTop } from "../common/BackToTop"
import Announcement from "../components/home/Announcement"
import Benefits from "../components/home/Benefits"
import ContactInfo from "../components/home/ContactInfo"
import CTA from "../components/home/CTA"
import Footer from "../components/home/Footer"
import Hero from "../components/home/Hero"
import HomeHeader from "../components/home/HomeHeader"
import Services from "../components/home/Services"
import Testimonials from "../components/home/Testimonials"
import Working from "../components/home/Working"

function Home() {
    return (
        <>
            <Announcement />
            <HomeHeader />
            <Hero />
            <Benefits />
            <ContactInfo />
            <Services />
            <Working />
            <Testimonials />
            <CTA />
            <Footer />
            <BackToTop />
        </>
    )
}

export default Home