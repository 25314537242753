import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { hideEmailAddress } from "../lib/utils";
import Base from "./Base";
import { Link } from "react-router-dom";
import { resendOtp, verifyOtp } from "../lib/actions/auth";
import { useNavigate } from 'react-router-dom';
import Loader from "./Loader";

function VerificationCode({ setClose, showClose, email }) {
    const navigate = useNavigate();
    const [failureMessage, setFailureMessage] = useState("");
    const [newOtpMessage, setNewOtpMessage] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const [newOtpError, setNewOtpError] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [isVerified, setIsVerified] = useState(false);

    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData("text");
        if (/^\d{6}$/.test(pastedText)) {
            [...pastedText].slice(0, 6).forEach((digit, i) => {
                const input = document.getElementById(`otp.${i}`);
                input.value = digit;
            });
        }
    };

    const handleChange = (index) => (e) => {
        if (/^\d$/.test(e.target.value) && index < 5) {
            const nextInput = document.getElementById(`otp.${index + 1}`);
            nextInput.focus();
        }
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        const otpString = data.otp.join("")
        const signUpId = sessionStorage.getItem('sign_up_id');
        const response = await verifyOtp({ otpString, email })
        setIsLoading(false);
        if (response) {
            setIsVerified(true)
            navigate('/sign-in');
        } else {
            setFailureMessage("OTP verification failed. Please try again.")
        }
    };

    useEffect(() => {
        const firstInput = document.getElementById("otp.0");
        firstInput?.focus();
    }, []);

    const resendOtpFunc = async () => {
        setIsLoading(true);
        const newOtp = await resendOtp({ email });
        setIsLoading(false);
        if (newOtp) {
            setNewOtpMessage("New OTP sent successfully!");
        } else {
            setNewOtpError("Failed to send new OTP. Please try again.");
        }
    }

    return (
        <Base setClose={setClose} showClose={showClose}>
            <div className="flex flex-col w-[596px] p-12 bg-white rounded-3xl shadow-[0_64px_64px_-48px_rgba(15,15,15,0.08)] gap-12">
                <div>
                    <h2 className="text-center text-[32px] font-medium leading-[40px] text-[#1c1c1e] mb-3.5">
                        Enter your verification code
                    </h2>
                    <p className="text-center text-[16px] font-normal leading-[24px] text-[#7D7C84]">
                        We texted your code to {hideEmailAddress(email)}
                    </p>
                    {loading && <Loader />}
                </div>
                {newOtpMessage && (
                    <div className="text-center text-green-600">{newOtpMessage}</div>
                )}
                {newOtpError && (
                    <div className="text-center text-green-600">{newOtpError}</div>
                )}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    onPaste={handlePaste}
                    className="flex flex-col w-full gap-12"
                >
                    <div className="flex gap-6">
                        {[...Array(6)].map((_, i) => (
                            <input
                                key={i}
                                id={`otp.${i}`}
                                type="text" // Change this to 'text' to make numbers visible
                                inputMode="numeric"
                                maxLength={1}
                                {...register(`otp.${i}`, {
                                    required: `Field ${i + 1} is missing a value`,
                                    pattern: {
                                        value: /^[0-9]$/,
                                        message: "Please enter numeric values only",
                                    },
                                    maxLength: {
                                        value: 1,
                                        message: "Only one digit is accepted per field",
                                    },
                                })}
                                onChange={handleChange(i)}
                                className="w-[56px] h-[56px] p-3 rounded-3xl border border-primary text-[#1c1c1e] text-[24px] font-medium leading-[32px] text-center"
                            />
                        ))}
                    </div>

                    <div className="text-red-600">
                        {(errors.otp &&
                            Object.values(errors.otp)[0]?.message?.toString()) ||
                            failureMessage}
                    </div>
                    <div className="flex justify-between ">
                        {/* <Link to="/sign-up"> */}
                        <button
                            onClick={() => resendOtpFunc()}
                            className="flex justify-center mr-5 items-center w-full rounded-3xl border border-[#ed5700] p-4 text-white bg-[#ed5700] cursor-pointer"
                        >
                            Resend
                        </button>
                        {/* </Link> */}
                        {/* <Link to="/onboarding"> */}
                        <button
                            className="mr-5 ms-auto xl:mr-10 flex justify-center items-center w-full rounded-3xl border border-[#ed5700] p-4 text-white bg-[#ed5700] cursor-pointer"
                            type="submit"
                        >
                            Continue
                        </button>
                        {/* </Link> */}


                    </div>
                </form>
            </div>
        </Base>
    );
}

export default VerificationCode;
