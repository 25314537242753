export const forgotPasswordForReset = async (data) => {
  const email = data.email

  try {
    const response = await fetch(
      `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/user/forget_password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email })
      }
    )

    const contentType = response.headers.get("content-type")

    if (contentType && contentType.includes("application/json")) {
      const jsonResponse = await response.json()
      // const cookieStore = cookies()
      // cookieStore.set("temp_user_id", encryptCookie(jsonResponse.id), {
      //   maxAge: 30 * 24 * 3600
      // })
      return jsonResponse
    } else {
      const textResponse = await response.text()
      console.error("Unexpected response format:", textResponse)
      throw new Error("Unexpected response format")
    }
  } catch (error) {
    console.error("Error during forgot password:", error)
    throw error
  }
}

export const forgotPassword = async (data) => {
  const email = data.email

  try {
    const response = await fetch(
      `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/user/reset_password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email })
      }
    )

    const contentType = response.headers.get("content-type")

    if (contentType && contentType.includes("application/json")) {
      const jsonResponse = await response.json()

      // const cookieStore = cookies()
      // cookieStore.set("temp_user_id", encryptCookie(jsonResponse.id), {
      //   maxAge: 30 * 24 * 3600
      // })
      return jsonResponse
    } else {
      const textResponse = await response.text()
      console.error("Unexpected response format:", textResponse)
      throw new Error("Unexpected response format")
    }
  } catch (error) {
    console.error("Error during forgot password:", error)
    throw error
  }
}

export const signInFunc = async (data) => {
  //const { email, password } = data
  const email = data.email;
  const password = data.password;

  // const cookieStore = cookies()

  try {
    const response = await fetch(
      `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password })
      }
    )

    if (!response.ok) {
      const errorResponse = await response.json()
      throw new Error(
        `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
      )
    }

    const jsonResponse = await response.json()

    // const user = {
    //   id: jsonResponse.user_id,
    //   otpVerification: "Successful",
    //   accessToken: jsonResponse.access_token
    // }

    // cookieStore.set("user", encryptCookie(user), { maxAge: 30 * 24 * 3600 })
    return jsonResponse
  } catch (error) {
    console.error("Error during sign in:", error)
    return null
  }
}

export const signUpFunc = async (formData) => {

  const username = formData.username
  const email = formData.email
  const password = formData.password
  const isClient = true;
  const isSuperAdmin = false;
  const isAdmin = false;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/register`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password, isClient, isSuperAdmin, isAdmin })
      }
    )
    const statusCode = response.status;

    const jsonResponse = await response.json()

    if (jsonResponse && jsonResponse.id && jsonResponse.otp_verification) {
      // const user = {
      //   id: jsonResponse.id,
      //   otpVerification: jsonResponse.otp_verification
      // }
      // const cookieStore = cookies()
      // cookieStore.set("user", encryptCookie(user), { maxAge: 30 * 24 * 3600 })
    }
    if (statusCode === 409) {
      return { statusCode, jsonResponse };
    }
    return jsonResponse
  } catch (error) {
    throw error
  }
}

// export const checkAuthorization = async (redirectLink) => {
//   const cookieStore = cookies()
//   const encryptedUser = cookieStore.get("user")

//   if (!encryptedUser) {
//     redirect(redirectLink)
//   }

//   const user = JSON.parse(decryptCookie(encryptedUser.value))

//   if (!(user && user.id && user.accessToken)) {
//     redirect(redirectLink)
//   }

//   try {
//     const decodedToken = jwt.decode(user.accessToken)

//     if (decodedToken && decodedToken.exp) {
//       const currentTime = Math.floor(Date.now() / 1000)
//       if (decodedToken.exp < currentTime) {
//         redirect(redirectLink)
//         return
//       }
//     } else {
//       redirect(redirectLink)
//       return
//     }
//   } catch (error) {
//     redirect(redirectLink)
//     return
//   }

//   return user
// }

// export const checkUserInCookies = async () => {
//   const cookieStore = cookies()
//   const encryptedUser = cookieStore.get("user")

//   if (!encryptedUser) return false

//   let user
//   try {
//     user = JSON.parse(decryptCookie(encryptedUser.value))
//   } catch (error) {
//     return false
//   }

//   if (!(user && user.id && user.accessToken)) return false

//   try {
//     const decodedToken = jwt.decode(user.accessToken)
//     const currentTime = Math.floor(Date.now() / 1000)
//     if (decodedToken.exp < currentTime) return false
//   } catch (error) {
//     return false
//   }

//   return true
// }

export const verifyOtp = async ({ otpString, email }) => {

  // const cookieStore = cookies()
  // const encryptedUser = cookieStore.get("user")

  // if (!encryptedUser) throw new Error("User is not logged in.")

  // const user = JSON.parse(decryptCookie(encryptedUser.value))
  // const userId = user.id

  const response = await fetch(
    `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/verify_otp/${email}/`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ otp: otpString })
    }
  ).then(res => res.json())

  // if (response) {
  //   user.accessToken = response.accessToken
  //   cookieStore.set("user", encryptCookie(user), { maxAge: 30 * 24 * 3600 })
  // }
  return response
}

export const resetPassword = async ({ password, password2, otp, email }) => {
  const response = await fetch(
    `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/user/reset_password`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email, otp, password, password2 })
    }
  );

  // Check if the status code is 200
  if (response.status === 200) {
    return 200; // Return the status code 200
  } else {
    // If not 200, return the parsed response
    const jsonResponse = await response.json();
    return jsonResponse;
  }
};


export const logOut = () => {

}


export const resendOtp = async (data) => {
  const email = data.email
  // const cookieStore = cookies()

  try {
    const response = await fetch(
      `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/resend_otp/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email })
      }
    )

    if (!response.ok) {
      const errorResponse = await response.json()
      throw new Error(
        `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
      )
    }

    const jsonResponse = await response.json()

    // const user = {
    //   id: jsonResponse.user_id,
    //   otpVerification: "Successful",
    //   accessToken: jsonResponse.access_token
    // }

    // cookieStore.set("user", encryptCookie(user), { maxAge: 30 * 24 * 3600 })
    return jsonResponse
  } catch (error) {
    console.error("Error during sign in:", error)
    return null
  }
}