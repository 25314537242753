import { useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { checkActiveFit } from '@/lib/actions/planType';
import herediaryImg from "../../../media/details/hereditaty.png";
import bgImg from "../../../media/bg-meal.png";
const HereditaryIssues = () => {
    //const router = useRouter();

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const allowRoute = await checkActiveFit();
        //         if (!allowRoute) {
        //             router.push("/en/dashboard");
        //         }
        //     } catch (error) {
        //         // Handle error
        //     }
        // };

        // fetchData();
    }, []);

    return (
        <div className="2xl:mt-10 max-w-xl mx-auto p-8 bg-white rounded-3xl shadow-xl text-center space-y-8" style={{ backgroundImage: `url(${bgImg})` }}>
            <h1 className="text-2xl font-semibold">Hereditary issues?</h1>
            <img
                src={herediaryImg}
                alt="Hereditary issues"
                width={100}
                height={100}
                className="mx-auto"
            />
            <div className="flex justify-between mt-8 space-x-4">
                <Link to="/dashboard/sleep-measures">
                    <button className="w-full px-4 py-2 bg-primary text-white rounded-md">Yes</button>
                </Link>
                <Link to="/dashboard/sleep-measures">
                    <button className="w-full px-4 py-2 bg-gray-200 rounded-md border border-primary">No</button>
                </Link>
            </div>
        </div>
    );
};

export default HereditaryIssues;
