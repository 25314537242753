
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { checkActiveFit } from '@/lib/actions/planType';
// import { useRouter } from 'next/navigation';
import otherImg from "../../../media/details/other-health-issues.png";
import bgImg from "../../../media/bg-meal.png";
import { useWellnessData } from '../../../context/WellnessDataContext';
import { addWellnessData } from '../../../lib/actions/preferences';
import { getProfileInCookies } from '../../../lib/actions/profile';
const OtherHealthIssues = () => {
    const navigate = useNavigate();
    const profileInCookies = getProfileInCookies();
    const { wellnessData, setWellnessData } = useWellnessData();
    // const router = useRouter();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await addWellnessData(wellnessData);
            if (response) {
                if (profileInCookies.gender === 'Female') {
                    navigate("/dashboard/cycle");
                }

                navigate("/dashboard/lifestyle");
            }
            //router.push('/en/dashboard');
        } catch (error) {
            setLoading(false);
            // Handle error
        }
    };


    return (
        <div className="2xl:mt-5 max-w-lg container mx-auto p-4" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="flex items-center mb-6">
                <img src={otherImg} alt="Logo" className="mr-4" />
                <h1 className="text-2xl font-bold">Other Health Issues</h1>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Please Choose</label>
                    <select className="block w-full p-2 border border-gray-300 rounded-md">
                        <option>Fever</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-6">
                <Link to="/dashboard/urine-flow">
                    <button className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 border border-primary">
                        Back
                    </button>
                </Link>
                {/* <Link to="/dashboard/cycle"> */}
                <Link to="/dashboard/lifestyle">
                    <button className="px-4 py-2 bg-primary text-white rounded-md " onClick={(e) => handleSubmit()}>
                        Save
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default OtherHealthIssues;
