import { getAccessToken, getProfileIsCookies, getUserId } from "./profile";

export const getMealGen = async (weekId) => {
    //const userProfileInCookies = getProfileIsCookies();

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/nutrition/meal/${getUserId()}`,
        {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),

        }
    );

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    // Optionally, you can handle the response data or store it in cookies/session

    return data;
};
export const postMealGen = async (weekId) => {
    //const userProfileInCookies = getProfileIsCookies();

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/nutrition/weekly-meal/${getUserId()}/${weekId}/`,
        {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),

        }
    );

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    // Optionally, you can handle the response data or store it in cookies/session

    return data;
};
