import phone from "../../media/icon-outline-phone.svg";
import mail from "../../media/icon-outline-mail.svg";
import twitter from "../../media/twitter.svg";
import facebook from "../../media/facebook.svg";
import instagram from "../../media/instagram.svg";
import github from "../../media/github.svg";
import { IoLogoGooglePlaystore } from "react-icons/io5";
function Announcement() {
  return (
    <section className="w-full py-[9px] bg-[#4D592B] relative">
      <div className="container flex justify-between w-full max-w-full px-4 box-border">
        <div className="flex justify-between gap-[25px] flex-wrap">
          <div className="flex items-center gap-[9px] cursor-pointer" >
            <IoLogoGooglePlaystore className="text-white" />
            <div className="text-[#F9F9F7] text-[16px] font-normal leading-[24px]">
              Download our App
            </div>
          </div>
          {/* {contact.map((item, index) => (
            <div className="flex items-center gap-[9px]" key={index}>
              <img
                className="w-[18px] h-[18px] object-contain"
                src={item.icon}
                alt={item.alt}
              />
              <div className="text-[#F9F9F7] text-[16px] font-normal leading-[24px]">
                {item.info}
              </div>
            </div>
          ))} */}
        </div>
        <div className="flex justify-between gap-[25px] flex-wrap">
          {socials.map((social, index) => (
            <a href={social.link} key={index}>
              <img
                className="w-[24px] h-[24px] object-contain"
                src={social.icon}
                alt={social.alt}
              />
            </a>
          ))}
        </div>
      </div>

      {/* Responsive Styles */}
      <style jsx>{`
        @media (max-width: 768px) {
          section {
            padding: 8px 0;
          }
          .flex {
            gap: 15px;
          }
          .text-base {
            font-size: 14px;
          }
        }
        @media (max-width: 480px) {
          section {
            padding: 6px 0;
          }
          .flex {
            gap: 10px;
          }
          .text-base {
            font-size: 12px;
          }
        }
      `}</style>
    </section>
  );
}

const contact = [
  { icon: phone, alt: "phone", info: "(414) 857 - 0107" },
  { icon: mail, alt: "mail", info: "Ruthsimplyway@gmail.com" },
];

const socials = [
  { icon: twitter, alt: "twitter", link: "/" },
  { icon: facebook, alt: "Facebook", link: "/" },
  { icon: instagram, alt: "Instagram", link: "/" },
  { icon: github, alt: "Github", link: "/" },
];

export default Announcement;
