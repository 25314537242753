import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bgImg from "../../../media/bg-meal.png";
import { useUserData } from "../../../context/UserDataContext";


const ActivityPage = () => {
    const [selected, setSelected] = useState(0);
    const { userData, setUserData } = useUserData(); // Get user data and the function to update it

    const choices = [
        { name: "sedentary", description: "A lifestyle with no or irregular physical activity." },
        { name: "lightly active", description: "Lifestyle with regular physical activity, but less than 2 ½ hours per week." },
        { name: "moderately active", description: "A lifestyle with regular physical activity everyday." },
        { name: "very active", description: "A lifestyle with extensive physical activity everyday." },
    ];

    // Update activity_level in the user data when selected changes
    useEffect(() => {
        setUserData({
            ...userData,
            activity_level: choices[selected].name, // Set the activity level in context
        });
    }, [selected]);

    return (
        <div
            className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="p-8 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                <h1 className="text-xl font-bold">Select Activity Level</h1>
                <p>We will make sure you get better personalized results.</p>
                {choices.map((choice, index) => (
                    <div
                        key={index}
                        onClick={() => setSelected(index)}
                        className={`p-4 rounded-md cursor-pointer ${selected === index ? "bg-orange-100 border border-orange-500" : "bg-gray-100"
                            }`}
                    >
                        <h2 className="font-semibold">{choice.name}</h2>
                        <p>{choice.description}</p>
                    </div>
                ))}
                <div className="flex justify-between">
                    <Link to="/age" className="px-4 py-2 bg-orange-500 text-white rounded-md ml-auto">
                        Next
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ActivityPage;
