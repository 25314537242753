import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeightSlider from "./HeightSlider";
import bgImg from "../../../media/bg-meal.png";
import { useUserData } from "../../../context/UserDataContext"; // Import context

const HeightComp = () => {
    const [height, setHeight] = useState(165);
    const { userData, setUserData } = useUserData(); // Access userData and setUserData from context
    const [height_uom, setHeight_uom] = useState("cm");
    // Update the userData context whenever the height changes
    useEffect(() => {
        setUserData({
            ...userData,
            height_uom: height_uom,
            height: height, // Set height in userData
        });
    }, [height]); // Runs whenever the height changes

    return (
        <div
            className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="p-8 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                <h1 className="text-xl font-bold">How tall are you?</h1>
                <p>We will make sure you get better personalized results.</p>
                <HeightSlider height={height} setHeight={setHeight} setHeight_uom={setHeight_uom} />
                <div className="flex justify-between mt-4">
                    <Link to="/goal">
                        <button className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400">
                            Back
                        </button>
                    </Link>
                    <Link to="/weight">
                        <button className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600">
                            Next
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default HeightComp;
