import { useEffect, useState } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { Link } from "react-router-dom";
import background from "../../media/pinstripe.png";
import Failure from "../../common/Failure";
import { REGEX } from "../../context/constants";
import ForgotPassword from "../../common/ForgotPassword";
import VerificationOtp from "../../common/VerificationOtp";
import { signInFunc } from "../../lib/actions/auth";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../lib/actions/profile";
import { useUserData } from "../../context/UserDataContext";
import Cookies from "js-cookie";
import ResetPassword from "./ResetPassword";
export default function SignIn() {
    useEffect(() => { sessionStorage.clear() }, []); // clear sessionSotage
    const { userData, setUserData } = useUserData();
    const [showForgotModal, setShowForgotModal] = useState(false);
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [resetPassword, setResetPassword] = useState(false);
    const [otpString, setOtpString] = useState("");
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const signInWithEmailPassword = async (data) => {
        try {
            setLoading(true);
            const signInResponse = await signInFunc({
                email: data.email,
                password: data.password,
            });
            setLoading(false);
            if (signInResponse.id) {
                sessionStorage.setItem("user_id", signInResponse.id);
                sessionStorage.setItem("access_token", signInResponse.access);
                const expiryDate = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour
                Cookies.set("user", JSON.stringify(signInResponse), { expires: expiryDate });

                //get User Profile
                setLoading(true);
                const userProfile = await getUserProfile();
                if (userProfile.id) {
                    console.log('11111s');

                    const profileInCookies = {
                        name: userProfile.name,
                        gender: userProfile.gender,
                        fitness_goal: userProfile.fitness_goal,
                        bmi: userProfile.bmi,
                        location: userProfile.location,
                        weight: userProfile.weight,
                        height: userProfile.height,
                        age: userProfile.age,
                        activity_level: userProfile.activity_level,
                        fitness_level: userProfile.fitness_level,
                        bio: userProfile.bio,
                        // id: '',
                        diet_type: userProfile.diet_type,
                        height_uom: userProfile.height_uom,
                        weight_uom: userProfile.weight_uom,
                        target_weight: userProfile.target_weight,
                        photo_url: userProfile.photo_url,
                        // profile_picture: userProfile.profile_picture,
                    }
                    setUserData(profileInCookies);
                    Cookies.set("profile", JSON.stringify(profileInCookies), { expires: expiryDate });

                }
                setLoading(false);

                if (!userProfile.id) {
                    navigate("/onboarding")
                } else {
                    //navigate to dashboard
                    navigate("/dashboard");
                }

            }
            else {
                setError(true)
                console.error("Sign-in failed");
            }
        } catch (error) {
            setError(true)
            console.error("Error signing in:", error);
            // Handle the error, e.g., show a user-friendly error message
        }
    };

    return (
        <div className="min-h-screen flex flex-col md:flex-row">

            {/* First Column */}
            <div
                className="flex-1 flex items-center justify-center bg-cover bg-center p-8 text-white"
                style={{ backgroundImage: `url(${background})` }}
            >
                <div className="text-center">
                    <h1 className="text-4xl font-bold">
                        <span className="text-[#2563eb]">Welcome to Your</span> Personalized AI Diet Dashboard!
                    </h1>
                    <p className="mt-4">
                        Congratulations on taking the first step towards a healthier lifestyle! Our AI diet dashboard is here to empower you on your journey to better nutrition and well-being.
                    </p>
                </div>
            </div>

            {/* Second Column (Sign-In Form) */}
            {!resetPassword ? <div className="bg-white p-8 flex flex-col items-center justify-center">
                <div>
                    <h1 className="text-4xl font-bold text-center mb-8">
                        Welcome to our AI Meal Planner
                    </h1>
                </div>

                <form
                    className="w-full max-w-md"
                    onSubmit={handleSubmit(signInWithEmailPassword)}
                >
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Email
                        </label>
                        <input
                            className="mt-1 block w-full border border-gray-300 focus:border-primary rounded-2xl min-h-[2.5rem] p-2 focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter your email"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: REGEX.email,
                                    message: "Please provide a valid email",
                                },
                            })}
                        />
                        {errors.email && (
                            <div className="text-red-500 text-sm mt-2">
                                {errors.email.message?.toString()}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Password
                        </label>
                        <input
                            className="mt-1 block w-full border border-gray-300 focus:border-primary rounded-2xl min-h-[2.5rem] p-2 focus:outline-none focus:ring-2 focus:ring-primary"
                            type="password"
                            placeholder="Enter your password"
                            {...register("password", {
                                minLength: {
                                    value: 8,
                                    message: "Password is 8 characters minimum",
                                },
                                validate: {
                                    lowercase: (v) =>
                                        Boolean(v.match(REGEX.lowercase)) ||
                                        "Password should have at least one lowercase character",

                                    uppercase: (v) =>
                                        Boolean(v.match(REGEX.uppercase)) ||
                                        "Password should have at least one uppercase character",

                                    number: (v) =>
                                        Boolean(v.match(REGEX.number)) ||
                                        "Password should have at least one numeric character",

                                    specialCharacter: (v) =>
                                        Boolean(v.match(REGEX.specialCharacter)) ||
                                        "Password should have at least one special character",
                                },
                            })}
                        />
                        {errors.password && (
                            <div className="text-red-500 text-sm mt-2">
                                {errors.password.message?.toString()}
                            </div>
                        )}
                    </div>

                    <button
                        className="w-full bg-primary text-white py-2 rounded-2xl"
                        type="submit"
                    >
                        Login
                    </button>
                </form>
                {error && <div className="text-red-500 text-sm mt-2">Error! Please check your credentials</div>}
                <button className="mt-3 text-primary" onClick={() => setShowForgotModal(true)}>
                    Forgot Password?
                </button>
                <h3 className="mt-5">Don't you have an account? Please <Link to="/sign-up" className="text-primary">Sign up</Link></h3>
            </div> : <ResetPassword email={forgotEmail} otp={otpString} setResetPassword={setResetPassword} />}

            {loading && <Loader />}
            {showForgotModal && (
                <ForgotPassword
                    showClose
                    setClose={setShowForgotModal}
                    setEmail={setForgotEmail}
                    setVerificationModal={setShowVerificationModal}
                />
            )}

            {showVerificationModal && (
                <VerificationOtp
                    showClose
                    setClose={setShowVerificationModal}
                    setClose2={setShowForgotModal}
                    email={forgotEmail}
                    setResetPassword={setResetPassword}
                    setOtpString={setOtpString}
                />
            )}

        </div>
    );
}
