import React from 'react'

function NotificationComp() {
    return (
        <>
            <h1 className="text-2xl font-bold mb-6">Notifications</h1>
            <div className="space-y-4">
                <div className="p-4 bg-gray-50 rounded-lg flex items-center justify-between">
                    <label htmlFor="preferred-email" className="text-sm font-medium text-gray-700">
                        Preferred email
                    </label>
                    <div className="flex space-x-2">
                        <input
                            type="email"
                            name="preferred-email"
                            id="preferred-email"
                            placeholder="Enter your email"
                            className="border-gray-300 focus:ring-rose-500 focus:border-rose-500 block w-full pl-7 pr-12 sm:text-sm border rounded-md"
                        />
                        <button className="text-orange-600 hover:text-orange-800 font-semibold text-sm">
                            + Add new email
                        </button>
                    </div>
                </div>

                {/* Notification Toggles */}
                <div className="space-y-4">
                    {[
                        {
                            label: 'Activity updates',
                            description: 'Nec, posuere non felis duis massa vitae aliquet interdum scelerisque. Neque ullamcorper.',
                        },
                        {
                            label: 'Account activity',
                            description: 'Nec, posuere non felis duis massa vitae aliquet interdum scelerisque. Neque ullamcorper.',
                        },
                        {
                            label: 'Info letters from our partners',
                            description: 'Nec, posuere non felis duis massa vitae aliquet interdum scelerisque. Neque ullamcorper.',
                        },
                        {
                            label: 'Promotions, recommendations & helpful resources',
                            description: 'Nec, posuere non felis duis massa vitae aliquet interdum scelerisque. Neque ullamcorper.',
                        },
                        {
                            label: 'Promotions, recommendations & helpful resources',
                            description: 'Nec, posuere non felis duis massa vitae aliquet interdum scelerisque. Neque ullamcorper.'
                        },
                        {
                            label: 'Daily summary emails',
                            description: 'Nec, posuere non felis duis massa vitae aliquet interdum scelerisque. Neque ullamcorper.'
                        }
                    ].map((item, index) => (
                        <div key={index} className="flex items-start space-x-4">
                            <input type="checkbox" className="h-6 w-6 text-orange-500 rounded-full accent-primary" />
                            <div>
                                <p className="text-sm font-semibold">{item.label}</p>
                                <p className="text-sm text-gray-500">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <button className='bg-primary text-white px-5 py-2 rounded-xl mt-5'>Save</button></>
    )
}

export default NotificationComp