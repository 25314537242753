// "use client";
// import Link from 'next/link';
// import { useWellnessData } from '@/context/WellnessDataContext';
// import { addWellnessData } from '@/lib/actions/preferences';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import sleepImg from "../../../media/details/sleep_measure.png";
import bgImg from "../../../media/bg-meal.png";
import { useWellnessData } from '../../../context/WellnessDataContext';
// import SuccessModal from '@/components/modal/successModal';
// import { useRouter } from "next/navigation";

const UrineFlow = () => {
    const { wellnessData, setWellnessData } = useWellnessData();
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const router = useRouter();

    const handleUrinateMetricChange = (e) => {
        setWellnessData({
            ...wellnessData,
            urinate_metric: e.target.value,
        });
    };

    const addWellnessFunc = async () => {
        // const wellnessRes = await addWellnessData(wellnessData);
        // if (wellnessRes) {
        //     setIsModalVisible(true);
        //     setTimeout(() => {
        //         router.push("/en/dashboard");
        //     }, 2000);
        // }
    };

    return (
        <div className="2xl:mt-5 max-w-lg container mx-auto p-4" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="flex items-center mb-6">
                <img src={sleepImg} alt="Logo" className=" mr-4" />
                <h1 className="text-2xl font-bold">Urine Flow Test</h1>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Please Choose</label>
                    <select
                        // value={wellnessData.urinate_metric}
                        onChange={handleUrinateMetricChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option>12ml/s</option>
                        <option>15ml/s</option>
                        <option>18ml/s</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-6">
                <Link to="/dashboard/sleep-measures">
                    <button className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 border border-primary">
                        Back
                    </button>
                </Link>
                <Link to="/dashboard/other-issues">
                    <button
                        className="px-4 py-2 bg-primary text-white rounded-md"
                        onClick={addWellnessFunc}
                    >
                        Next
                    </button>
                </Link>

            </div>
            {/* {isModalVisible && (
                <SuccessModal onClose={() => setIsModalVisible(false)} message="Your data has been saved successfully!" />
            )} */}
        </div>
    );
};

export default UrineFlow;
