import photo1 from "../../media/profile-pic1.jpg";
import photo2 from "../../media/profile-pic2.jpg";
import photo3 from "../../media/profile-pic3.jpg";
import Testimonial from "./Testimonial";

function Testimonials() {
    return (
        <div className="flex flex-wrap justify-center gap-6 w-full">
            <div className="flex flex-col gap-16 max-w-full box-border">
                <h2 className="text-neutral-800 text-4xl font-semibold leading-[3.75rem] text-center mx-auto max-w-full">
                    What Our Customers Say
                </h2>

                <div className="flex flex-col gap-6 w-full overflow-hidden md:flex-row md:overflow-x-auto md:pb-4">
                    {testimonials.map((item, index) => (
                        <Testimonial {...item} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
}

const testimonials = [
    {
        title: "“Simply delicious”",
        description:
            "Since using I've lost 15 pounds and feel more energized than ever before. The meal plans are easy to follow, and the recipes are delicious!",
        author: {
            name: "Sophire Robson",
            address: "Los Angeles, CA",
            profilePhoto: {
                src: photo1,
                alt: "Sophire Robson"
            }
        }
    },
    {
        title: "“Simply delicious”",
        description:
            "I love how customizable [Your Diet Planner Name] is. It adapts to my dietary restrictions and preferences, making healthy eating enjoyable and sustainable.",
        author: {
            name: "Matt Cannon",
            address: "San Diego, CA",
            profilePhoto: {
                src: photo2,
                alt: "Matt Cannon"
            }
        }
    },
    {
        title: "“Simply delicious”",
        description:
            "The culinary experience at place is first to none. The atmosphere is vibrant, the food - nothing short of extraordinary. The food was the highlight of our evening. Highly recommended.",
        author: {
            name: "Andy Smith",
            address: "San Francisco, CA",
            profilePhoto: {
                src: photo3,
                alt: "Andy Smith"
            }
        }
    }
];

export default Testimonials;
