import { BackToTop } from "../../common/BackToTop";

const Help = () => {
    return (
        <div className="p-5  mx-auto font-sans leading-relaxed">
            <h1 className="text-center mb-5 text-2xl text-gray-800">Help and Support</h1>

            <section className="mb-5">
                <h2 className="text-xl text-gray-700 mb-2">FAQs</h2>
                <div className="mb-4">
                    <h3 className="text-lg text-gray-600">How do I reset my password?</h3>
                    <p>
                        To reset your password, go to the login Help and click on the
                        "Forgot Password" link. Follow the instructions sent to your
                        email.
                    </p>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg text-gray-600">How can I contact support?</h3>
                    <p>
                        You can contact support via the "Contact Us" form available on the
                        contact page or email us directly at support@example.com.
                    </p>
                </div>
            </section>

            <section className="mb-5">
                <h2 className="text-xl text-gray-700 mb-2">Getting Started</h2>
                <p>Welcome to our application! Here’s how you can get started:</p>
                <ul className="list-disc list-inside ml-5">
                    <li>Sign up for an account.</li>
                    <li>Complete your profile information.</li>
                    <li>Explore the main features and settings.</li>
                </ul>
            </section>

            <section>
                <h2 className="text-xl text-gray-700 mb-2">Troubleshooting</h2>
                <p>If you encounter any issues, please try the following steps:</p>
                <ol className="list-decimal list-inside ml-5">
                    <li>Clear your browser cache and cookies.</li>
                    <li>Ensure you are using the latest version of your browser.</li>
                    <li>Check the known issues section for any ongoing problems.</li>
                </ol>
            </section>
            <BackToTop />
        </div>
    );
};

export default Help;
