import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { MdCheck } from "react-icons/md"; // Import the check icon from react-icons
import { BackToTop } from "../../common/BackToTop";

const Membership = () => {
    const [userMembership, setUserMembership] = useState("free");
    const [alertVisible, setAlertVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            // Your data fetching logic here
        };

        fetchData();
    }, []);

    const updateMemberShipFunc = useCallback(async (newMembership) => {
        // Your membership update logic here
    }, [userMembership]);

    return (
        <div className="p-8 mx-auto">
            <h2 className="text-2xl font-bold mb-6 text-center">Membership Plans</h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                {/* Free Plan */}
                <div className="border-2 border-gray-300 p-6 rounded-lg shadow-lg text-center bg-white flex flex-col justify-between">
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Free</h3>
                        <div className="text-4xl font-bold mb-2">
                            $0 <span className="text-lg font-medium">/month</span>
                        </div>
                        <div className="text-lg font-medium mb-4">What you get</div>
                        <ul className="text-left mb-6">
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Basic meal plan generation</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Water tracking</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Women cycle tracking</li>
                        </ul>
                    </div>
                    <button className={`bg-gray-300 text-white py-2 px-4 rounded ${userMembership === "free" ? "cursor-not-allowed" : "hover:bg-gray-400"}`} disabled={userMembership === "free"}>
                        {userMembership === "free" ? "Current plan" : "Upgraded"}
                    </button>
                </div>

                {/* ActiveFit Plan */}
                <div className="border-2 border-blue-400 p-6 rounded-lg shadow-lg text-center bg-white flex flex-col justify-between">
                    <div>
                        <h3 className="text-xl font-semibold mb-4">ActiveFit</h3>
                        <div className="text-4xl font-bold mb-2">
                            $3 <span className="text-lg font-medium">/month</span>
                        </div>
                        <div className="text-lg font-medium mb-4">Everything in Free, plus:</div>
                        <ul className="text-left mb-6">
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Adv meal plan generation</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Google Fit integration</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Collect wellness records</li>
                        </ul>
                    </div>
                    {userMembership === "activeFit" ? (
                        <button className="bg-blue-400 text-white py-2 px-4 rounded cursor-not-allowed">
                            Current plan
                        </button>
                    ) : userMembership === "free" ? (
                        <Link to="/dashboard/membership">
                            <button className="bg-blue-400 text-white py-2 px-4 rounded hover:bg-blue-500">
                                Get started
                            </button>
                        </Link>
                    ) : (
                        <button className="bg-blue-400 text-white py-2 px-4 rounded cursor-not-allowed">
                            Upgraded
                        </button>
                    )}
                </div>

                {/* ProFit Plan */}
                <div className="border-2 border-green-400 p-6 rounded-lg shadow-lg text-center bg-white flex flex-col justify-between">
                    <div>
                        <h3 className="text-xl font-semibold mb-4">ProFit</h3>
                        <div className="text-4xl font-bold mb-2">
                            $5 <span className="text-lg font-medium">/month</span>
                        </div>
                        <div className="text-lg font-medium mb-4">Everything in ActiveFit, plus:</div>
                        <ul className="text-left mb-6">
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Fitness module for posture</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Meal + exercises suggestions</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Fun activities suggestions</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Shopping list feature</li>
                        </ul>
                    </div>
                    {userMembership === "proFit" ? (
                        <button className="bg-green-400 text-white py-2 px-4 rounded cursor-not-allowed">
                            Current plan
                        </button>
                    ) : userMembership === "activeFit" || userMembership === "free" ? (
                        <Link to="/dashboard/membership">
                            <button className="bg-green-400 text-white py-2 px-4 rounded hover:bg-green-500">
                                Get started
                            </button>
                        </Link>
                    ) : (
                        <button className="bg-green-400 text-white py-2 px-4 rounded cursor-not-allowed">
                            Upgraded
                        </button>
                    )}
                </div>

                {/* EliteFit Plan */}
                <div className="border-2 border-purple-400 p-6 rounded-lg shadow-lg text-center bg-white flex flex-col justify-between">
                    <div>
                        <h3 className="text-xl font-semibold mb-4">EliteFit</h3>
                        <div className="text-4xl font-bold mb-2">
                            $10 <span className="text-lg font-medium">/month</span>
                        </div>
                        <div className="text-lg font-medium mb-4">Everything in ProFit, plus:</div>
                        <ul className="text-left mb-6">
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Blood report scanning</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Complete follow-up</li>
                            <li><MdCheck className="text-green-500 mr-2 inline-block" /> Fridge scanning feature</li>
                        </ul>
                    </div>
                    {userMembership === "eliteFit" ? (
                        <button className="bg-purple-400 text-white py-2 px-4 rounded cursor-not-allowed">
                            Current plan
                        </button>
                    ) : (
                        <Link to="/dashboard/membership">
                            <button className="bg-purple-400 text-white py-2 px-4 rounded hover:bg-purple-500">
                                Get started
                            </button>
                        </Link>
                    )}
                </div>
            </div>

            {/* Alert message */}
            {alertVisible && (
                <div className="mt-6 p-4 bg-green-500 text-white rounded-lg text-center" aria-live="assertive">
                    Membership updated to {userMembership} plan!
                </div>
            )}
            <BackToTop />
        </div>
    );
};

export default Membership;
