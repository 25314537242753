import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import chronicImg from "../../../media/details/chronic-desease.png";
import bgImg from "../../../media/bg-meal.png";
import { useWellnessData } from '../../../context/WellnessDataContext';
import { getProfileInCookies } from '../../../lib/actions/profile';

const ChronicConditions = () => {
    const { wellnessData, setWellnessData } = useWellnessData();
    const profileInCookies = getProfileInCookies();
    const navigate = useNavigate();

    const checkAutorization = () => {

        if (profileInCookies.membership_type === "free" && profileInCookies.gender === "Male") {
            navigate("/dashboard/membership");
        } else if (profileInCookies.membership_type === "free" && profileInCookies.gender === "Female") {
            navigate("/dashboard/cycle")
        }
    };
    useEffect(() => {
        //checkAutorization();
    }, []);

    const handleChronicConditionChange = (e) => {
        setWellnessData({
            ...wellnessData,
            chronic_conditions: e.target.value,
        });
    };

    return (
        <div className="lg:mt-10 max-w-xl mx-auto p-8 bg-white rounded-3xl shadow-xl text-center" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="mb-8">
                <div className="flex justify-center items-center space-x-4">
                    <img src={chronicImg} alt="Chronic Conditions" className="h-12" />
                    <h1 className="text-2xl font-semibold">Chronic Conditions</h1>
                </div>
            </div>
            <div className="space-y-6">
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Please Choose</label>
                    <select
                        value={wellnessData.chronic_conditions} // Bind the value
                        onChange={handleChronicConditionChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="">Select</option> {/* Add a default option */}
                        <option value="Arthritis">Arthritis</option>
                        <option value="Diabetes">Diabetes</option>
                        <option value="Hypertension">Hypertension</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
            </div>
            <div className="flex justify-end mt-8">
                <Link to="/dashboard/energy-stress">
                    <button className="px-4 py-2 bg-primary text-white rounded-md">Next</button>
                </Link>
            </div>
        </div>
    );
};

export default ChronicConditions;
