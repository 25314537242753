import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import prefImg from "../../../media/details/preference-br.png";
import bgImg from "../../../media/bg-meal.png";
import { usePreferences } from '../../../context/PreferenceDataContext';
import { addPreferenceData } from '../../../lib/actions/preferences';
// import { usePreferences } from '@/context/PreferenceDataContext';
// import { addPreferenceData } from '@/lib/actions/preferences';
// import SuccessModal from '@/components/modal/successModal';
// import { checkElitFit } from '@/lib/actions/planType';
// import { useRouter } from 'next/navigation';

const LifeStyle3 = () => {
    //const router = useRouter();
    const navigate = useNavigate();
    const { preferences, setPreferences } = usePreferences();
    const [isModalVisible, setIsModalVisible] = useState(false);

    // useEffect(() => {
    //     // const fetchData = async () => {
    //     //     try {
    //     //         const allowRoute = await checkElitFit();
    //     //         if (!allowRoute) {
    //     //             router.push("/en/dashboard");
    //     //         }
    //     //     } catch (error) {
    //     //         console.error('Error checking elit fit:', error);
    //     //     }
    //     // };

    //     // fetchData();
    // }, [router]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPreferences(prevPreferences => ({
            ...prevPreferences,
            [name]: value
        }));
    };

    const addPreferenceFunc = async () => {
        const addPreferenceRes = await addPreferenceData(preferences);
        if (addPreferenceRes) {
            navigate("/dashboard");
            //setIsModalVisible(true);
            // setTimeout(() => {
            //     router.push("/en/dashboard/details-gathering/sleep-measures");
            // }, 2000);
        }
    };

    return (
        <div className="2xl:mt-5 max-w-xl container mx-auto p-4" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="flex justify-between mb-6">
                <div className="flex items-center mb-2">
                    <img src={prefImg} alt="Logo" className=" mr-4" />
                    <h1 className="text-2xl font-bold">Preference and Blood Report</h1>
                </div>
                <div className="text-sm text-gray-600 mr-5">3 over 3</div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Target Weight</label>
                    <select name="target_weight"
                        // value={preferences.target_weight} 
                        onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded-md">
                        <option value="75 kg">75 kg</option>
                        {/* Add other options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Fruit Preference</label>
                    <select name="fruit_preference"
                        // value={preferences.fruit_preference} 
                        onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded-md">
                        <option value="Orange">Orange</option>
                        {/* Add other options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Favorite Fruit Preference</label>
                    <select name="favorite_fruit_frequency"
                        // value={preferences.favorite_fruit_frequency}
                        onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded-md">
                        <option value="2 times/day">2 times/day</option>
                        {/* Add other options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Food Weakness</label>
                    <select name="food_weakness"
                        // value={preferences.food_weakness} 
                        onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded-md">
                        <option value="Yoghurt">Yoghurt</option>
                        {/* Add other options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Food Weakness Reason</label>
                    <select name="food_weakness_reason"
                        // value={preferences.food_weakness_reason} 
                        onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded-md">
                        <option value="Love It">Love It</option>
                        {/* Add other options as needed */}
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-6">
                <Link to="/dashboard/preference2">
                    <button className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 border border-primary">
                        Back
                    </button>
                </Link>
                {/* <Link to="/dashboard/sleep-measures"> */}
                <button className="px-4 py-2 bg-primary text-white rounded-md 0" onClick={addPreferenceFunc}>
                    Save
                </button>
                {/* </Link> */}

            </div>
            {/* {isModalVisible && <SuccessModal onClose={() => setIsModalVisible(false)} message="Your preference data has been saved successfully!" />} */}
        </div>
    );
};

export default LifeStyle3;
