import { useEffect, useState } from "react";
import { FaTachometerAlt, FaUtensils, FaDumbbell, FaUserFriends, FaUserShield, FaCogs, FaInfoCircle, FaSignOutAlt, FaBars, FaDeezer, FaRobot, FaTelegramPlane } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getProfileInCookies, getUser } from "../../lib/actions/profile";

const SideBar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const profileInCookies = getProfileInCookies();
    const userInCookies = getUser();

    const navigate = useNavigate();
    const [userMembership, setUserMembership] = useState(profileInCookies?.membership_type || "free");
    const location = useLocation();
    const getLinkClass = (href) => {
        return location.pathname === href
            ? "bg-primary text-white"
            : "text-gray-400 hover:bg-primary hover:text-white";
    };
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const clearAllCookies = () => {
        // Get all cookies
        const allCookies = Cookies.get();

        // Loop through all cookies and remove them
        for (let cookieName in allCookies) {
            Cookies.remove(cookieName);
        }
        navigate("/")
    };

    return (
        <div className="">

            {/* Sidebar */}
            <div
                className={`fixed top-0 left-0 w-60 bg-gray-800 text-gray-200 shadow-lg h-full transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } transition-transform lg:translate-x-0 z-50 lg:relative lg:min-h-screen`}
            >
                <div className="p-6">
                    <div className="mb-8">
                        <Link to="/" className="text-xl font-bold text-white" onClick={() => toggleSidebar()}>
                            Logo
                        </Link>
                    </div>
                    <div className="space-y-4">
                        <Link
                            onClick={() => toggleSidebar()}
                            to="/dashboard"
                            className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                "/dashboard"
                            )}`}
                        >
                            <FaTachometerAlt />
                            <span>Overview</span>
                        </Link>
                        <Link
                            onClick={() => toggleSidebar()}
                            to="/dashboard/diet-plan"
                            className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                "/dashboard/diet-plan"
                            )}`}
                        >
                            <FaUtensils />
                            <span>Diet Plan</span>
                        </Link>
                        <Link
                            onClick={() => toggleSidebar()}
                            to="/dashboard/ai-chat"
                            className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                "/dashboard/ai-chat"
                            )}`}
                        >
                            <FaRobot />
                            <span>AI Chat</span>
                        </Link>
                        <Link
                            onClick={() => toggleSidebar()}
                            to="/dashboard/membership"
                            className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                "/dashboard/membership"
                            )}`}
                        >
                            <FaUserShield />
                            <span>Membership</span>
                        </Link>
                        {!userInCookies.isClient && <Link
                            onClick={() => toggleSidebar()}
                            to="/dashboard/members-list"
                            className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                "/dashboard/members-list"
                            )}`}
                        >
                            <FaUserFriends />
                            <span>Members List</span>
                        </Link>}
                        {!userInCookies.isClient && <Link
                            onClick={() => toggleSidebar()}
                            to="/dashboard/admin-dashboard"
                            className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                "/dashboard/admin-dashboard"
                            )}`}
                        >
                            <FaCogs />
                            <span>Admin Dashboard</span>
                        </Link>}
                        {!userInCookies.isClient && <Link
                            onClick={() => toggleSidebar()}
                            to="/dashboard/meal-plans"
                            className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                "/dashboard/meal-plans"
                            )}`}
                        >
                            <FaTelegramPlane />
                            <span>Meal Plans</span>
                        </Link>}
                        <Link
                            onClick={() => toggleSidebar()}
                            to="/dashboard/fitness"
                            className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                "/dashboard/fitness"
                            )}`}
                        >
                            <FaDumbbell />
                            <span>Fitness</span>
                        </Link>
                        {!userInCookies.isClient &&
                            <Link
                                onClick={() => toggleSidebar()}
                                to="/dashboard/details"
                                className={`flex items-center space-x-2 p-2 rounded ${getLinkClass(
                                    "/dashboard/details"
                                )}`}
                            >
                                <FaDeezer />
                                <span>Details</span>
                            </Link>}
                    </div>
                </div>
                {/* <div>
                    {userMembership !== "eliteFit" && (
                        <div className="p-6 bg-gray-800 text-white">
                            <Link to="/dashboard/membership" onClick={() => toggleSidebar()}>
                                <div className="text-center">Upgrade now</div>
                            </Link>
                        </div>
                    )}
                </div> */}
                <div className="p-6 bg-gray-800 flex items-center justify-between">
                    <Link to="/dashboard/help" className="flex items-center space-x-2" onClick={() => toggleSidebar()}>
                        <FaInfoCircle />
                        <span>Help</span>
                    </Link>
                    <button className="flex items-center space-x-2" onClick={() => { clearAllCookies() }}>
                        <FaSignOutAlt />
                        <span>Logout</span>
                    </button>
                </div>
            </div>

            {/* Overlay for small screens */}
            {isSidebarOpen && (
                <div
                    className="fixed inset-0 bg-black opacity-50 lg:hidden z-40"
                    onClick={toggleSidebar}
                ></div>
            )}
        </div>
    );
};

export default SideBar;
