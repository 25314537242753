import { useState } from "react";

const WeightSlider = ({ weight = 85, setWeight, setWeight_uom }) => {
    const [unit, setUnit] = useState("kg"); // Default unit is kg

    // Convert kg to lb
    const kgToLb = (kg) => kg * 2.20462;

    // Convert lb to kg
    const lbToKg = (lb) => lb / 2.20462;

    // Handle weight change from slider or input
    const handleWeightChange = (newWeight) => {
        // Convert the weight to kilograms if in pounds
        const updatedWeight = unit === "kg" ? newWeight : lbToKg(newWeight);
        setWeight(updatedWeight);
        setWeight_uom(unit);
    };

    // Handle slider change
    const handleSliderChange = (e) => {
        const newWeight = parseFloat(e.target.value);
        handleWeightChange(newWeight);
    };

    // Handle input change
    const handleInputChange = (e) => {
        const newWeight = parseFloat(e.target.value);
        if (!isNaN(newWeight)) {
            handleWeightChange(newWeight);
        }
    };

    // Toggle weight unit
    const toggleUnit = (newUnit) => {
        if (newUnit === unit) return; // No change if the unit is the same
        setUnit(newUnit);
        setWeight_uom(newUnit); // Update the unit of measure in the parent state
    };

    // Determine slider min and max values based on unit
    const sliderMin = unit === "kg" ? 10 : kgToLb(10).toFixed(1);
    const sliderMax = unit === "kg" ? 200 : kgToLb(200).toFixed(1);

    // Displayed weight (convert to lb if necessary)
    const displayedWeight = unit === "kg" ? weight : kgToLb(weight);

    return (
        <div className="text-center my-5">
            {/* Unit toggle buttons */}
            <div className="flex justify-center mb-5">
                <button
                    className={`border border-gray-300 py-2 px-5 cursor-pointer text-lg mx-1 ${unit === "kg" ? "bg-gray-300" : ""}`}
                    onClick={() => toggleUnit("kg")}
                >
                    KG
                </button>
                <button
                    className={`border border-gray-300 py-2 px-5 cursor-pointer text-lg mx-1 ${unit === "lb" ? "bg-gray-300" : ""}`}
                    onClick={() => toggleUnit("lbs")}
                >
                    LB
                </button>
            </div>

            {/* Display current weight */}
            <div className="text-5xl font-bold mb-5">
                {displayedWeight.toFixed(1)} {unit}
            </div>

            {/* Input field to enter weight */}
            <div className="flex justify-center items-center mb-5">
                <input
                    type="number"
                    step="0.1"
                    value={displayedWeight.toFixed(1)}
                    onChange={handleInputChange}
                    className="border border-gray-300 px-4 py-2 rounded-lg text-lg w-24 text-center"
                    min={sliderMin}
                    max={sliderMax}
                />
            </div>

            {/* Slider for weight */}
            <div className="flex justify-center items-center">
                <input
                    type="range"
                    step="0.1" // Allow decimal steps for fine adjustments
                    min={sliderMin}
                    max={sliderMax}
                    value={displayedWeight.toFixed(1)} // Use decimals for both units
                    className="slider w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    onChange={handleSliderChange}
                    style={{
                        background: `linear-gradient(to right, #ED5700 ${((displayedWeight - sliderMin) / (sliderMax - sliderMin)) * 100}%, #ddd 0%)`,
                    }}
                />
            </div>

            <style jsx>{`
                .slider::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 20px;
                    height: 20px;
                    background: #ED5700;
                    border-radius: 5px;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    cursor: pointer;
                }
                .slider::-moz-range-thumb {
                    width: 20px;
                    height: 20px;
                    background: #ED5700;
                    border-radius: 5px;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default WeightSlider;
