import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bgImg from "../../../media/bg-meal.png";
import { useUserData } from "../../../context/UserDataContext";

const AgeSelector = () => {
    const [selectedAge, setSelectedAge] = useState(27);
    const [manualAge, setManualAge] = useState(""); // State for manual input
    const { userData, setUserData } = useUserData(); // Get user data and setUserData from context

    const ageOptions = [];
    for (let age = 1; age <= 140; age++) {
        ageOptions.push(age);
    }

    const selectedIndex = ageOptions.indexOf(selectedAge);
    const startIndex = Math.max(selectedIndex - 1, 0);
    const endIndex = Math.min(startIndex + 3, ageOptions.length);

    const currentAges = ageOptions.slice(startIndex, endIndex);

    // Handlers for age selection
    const handlePrevious = () => {
        if (selectedIndex > 0) {
            const newAge = ageOptions[selectedIndex - 1];
            setSelectedAge(newAge);
            setManualAge(newAge); // Sync manual age input
        }
    };

    const handleNext = () => {
        if (selectedIndex < ageOptions.length - 1) {
            const newAge = ageOptions[selectedIndex + 1];
            setSelectedAge(newAge);
            setManualAge(newAge); // Sync manual age input
        }
    };

    // Handle manual age input
    const handleManualAgeChange = (e) => {
        const value = e.target.value;
        if (value === "") {
            setManualAge("");
            setSelectedAge(""); // Allow the field to be empty
        } else {
            const numValue = Number(value);
            if (numValue >= 1 && numValue <= 140) {
                setManualAge(numValue);
                setSelectedAge(numValue);
            }
        }
    };

    // Update age in the user data context whenever selectedAge changes
    useEffect(() => {
        if (selectedAge !== "") { // Only update if selectedAge is valid
            setUserData({
                ...userData,
                age: selectedAge,
            });
        }
    }, [selectedAge]);

    return (
        <div
            className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="p-8 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                <h1 className="text-xl font-bold">How old are you?</h1>
                <p>We will make sure we get better personalized results.</p>
                <div className="flex justify-center items-center space-x-4">
                    <button
                        onClick={handlePrevious}
                        disabled={selectedIndex === 0}
                        className="p-2 bg-gray-200 rounded-md"
                    >
                        {"<"}
                    </button>
                    <div className="flex space-x-2">
                        {currentAges.map((age) => (
                            <div
                                key={age}
                                className={`p-2 cursor-pointer ${age === selectedAge ? "text-orange-500 font-bold" : ""}`}
                                onClick={() => {
                                    setSelectedAge(age);
                                    setManualAge(age); // Sync manual age input
                                }}
                            >
                                {age}
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={handleNext}
                        disabled={selectedIndex === ageOptions.length - 1}
                        className="p-2 bg-gray-200 rounded-md"
                    >
                        {">"}
                    </button>
                </div>
                <div className="flex flex-col items-center mt-4">
                    <label htmlFor="manual-age" className="text-lg font-medium mb-2">Or enter your age:</label>
                    <input
                        id="manual-age"
                        type="number"
                        value={manualAge || ""}
                        onChange={handleManualAgeChange}
                        className="border border-gray-300 py-2 px-3 mx-2 w-full text-center"
                        min="5"
                        max="140"
                    />
                </div>
                <div className="flex justify-between mt-4">
                    <Link to="/onboarding" className="px-4 py-2 bg-gray-300 rounded-md">
                        Back
                    </Link>
                    <Link to="/diet" className="px-4 py-2 bg-orange-500 text-white rounded-md">
                        Next
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AgeSelector;
