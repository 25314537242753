import { getAccessToken, getProfileIsCookies, getUserId } from "./profile";

export const getNutrition = async (day) => {
    //const userProfileInCookies = getProfileIsCookies();

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/today-nutrition-budget/${getUserId()}/?day=${day}`,
        {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),

        }
    );

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    // Optionally, you can handle the response data or store it in cookies/session

    return data;
};
export const putNutrition = async (updateData) => {
    //const userProfileInCookies = getProfileIsCookies();

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/today-nutrition-budget/${getUserId()}/?day=${updateData.day}`,
        {
            method: "PUT",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(updateData),

        }
    );

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    // Optionally, you can handle the response data or store it in cookies/session

    return data;
};