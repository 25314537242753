import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import avatar from "../../media/avatar-w-photo.png";
import chart from "../../media/chart.svg";
import { getProfilePictureInCookies, getProfileInCookies, getUser } from "../../lib/actions/profile";
import { FaTint } from 'react-icons/fa'; // Water droplet icon
import { useEffect, useState } from "react";
import { getNutrition } from "../../lib/actions/nutrition";
import { getCurrentDay } from "../../lib/utils";
import { BackToTop } from "../../common/BackToTop";

// Helper function to get color based on progress
const getColorForProgress = (percentage) => {
    if (percentage <= 30) return "#f87171"; // Red
    if (percentage <= 80 && percentage > 30) return "#eab308"; // Yellow
    if (percentage <= 100 && percentage > 80) return "#10b981"; // Green
    if (percentage >= 100) return "#10b981"; // Green
    return "#3b82f6"; // Blue
};

const Overview = () => {
    const user = getUser();
    //const profilePicBase64 = getProfilePictureInCookies();
    const profileInCookies = getProfileInCookies();

    const [mealData, setMealData] = useState({
        Breakfast: { current: 0, target: 0 },
        Snacks: { current: 0, target: 0 },
        Lunch: { current: 0, target: 0 },
        Dinner: { current: 0, target: 0 },
    });

    const [waterIntakeCurrent, setWaterIntakeCurrent] = useState(0);
    const [waterIntakeGoal, setWaterIntakeGoal] = useState(5.0); // Default value for water goal

    const waterIntakePercentage = (waterIntakeCurrent / waterIntakeGoal) * 100;

    // Function to handle water droplet click
    const handleWaterClick = (index) => {
        setWaterIntakeCurrent(index + 1); // Update water intake when user clicks a droplet
    };

    // Fetch nutrition data based on current day
    const getOverviewData = async () => {
        const currentDay = getCurrentDay();
        const getOverviewData = await getNutrition(currentDay);
        //console.log("getOverviewData::", getOverviewData);

        // Update meal and water data from API response
        setMealData({
            Breakfast: {
                current: getOverviewData.calories_breakfast || 0,
                target: getOverviewData.calories_breakfast_goal || 0,
            },
            Snacks: {
                current: getOverviewData.calories_snacks || 0,
                target: getOverviewData.calories_snacks_goal || 0,
            },
            Lunch: {
                current: getOverviewData.calories_lunch || 0,
                target: getOverviewData.calories_lunch_goal || 0,
            },
            Dinner: {
                current: getOverviewData.calories_dinner || 0,
                target: getOverviewData.calories_dinner_goal || 0,
            }
        });

        // Update water intake values from API response
        setWaterIntakeCurrent(getOverviewData.current_water_intake || 0);
        setWaterIntakeGoal(getOverviewData.daily_water_goal || 5.0); // Default to 5L if not available
    };

    useEffect(() => {
        const fetchData = async () => {
            await getOverviewData();
        };

        fetchData(); // Call the async function inside useEffect
    }, []); // Empty dependency array to run only once when component mounts

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            {/* Meal Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                {Object.keys(mealData).map((meal, index) => {
                    const { current, target } = mealData[meal];
                    const percentage = (current / target) * 100;

                    return (
                        <div
                            key={index}
                            className="bg-white p-4 rounded-lg shadow flex flex-col items-center"
                        >
                            <div className="w-20 h-20 mb-4">
                                <CircularProgressbar
                                    value={percentage}
                                    text={`${current}/${target} Cal`}
                                    styles={buildStyles({
                                        textSize: "15px",
                                        pathColor: getColorForProgress(percentage),
                                        textColor: getColorForProgress(percentage),
                                        trailColor: "#d1d5db", // Tailwind's gray-300
                                    })}
                                />
                            </div>
                            <div className="text-xl font-semibold text-center">{meal}</div>
                        </div>
                    );
                })}
            </div>

            {/* Weight Loss Chart Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-semibold mb-4">Weight Loss Calories</h2>
                    <div className="h-48 rounded-lg flex items-center justify-center">
                        <img src={chart} alt="Chart" className="w-full h-full object-contain" />
                    </div>
                </div>

                {/* Welcome and Reminders */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <img src={profileInCookies?.photo_url} alt="Profile" className="w-20 h-20 rounded-full mx-auto mb-4" />
                    <h2 className="text-xl font-semibold text-center">Welcome {user.username}!</h2>
                    <p className="text-gray-600 text-center">You are doing Awesome</p>
                    <h3 className="mt-4 text-lg font-semibold">Upcoming Reminders</h3>
                    <ul className="list-disc list-inside text-gray-600">
                        <li>Start your day with a glass of water and aim to stay hydrated throughout the day.</li>
                        <li>Stick to Your Meal Plan: Review your personalized meal plan for the day.</li>
                        <li>Snack Smart: Reach for healthy snacks like fruits if feeling hungry.</li>
                    </ul>
                </div>
            </div>

            {/* Water Intake Section */}
            <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">Water Intake</h2>
                <p className="text-gray-600">
                    Drinking fluids is crucial to staying healthy and maintaining the function of every system in your body.
                </p>
                <div className="flex items-center mt-4">
                    <span className="text-lg font-bold">Daily Goal:</span>
                    <span className="ml-2 text-2xl font-bold text-blue-500">{`${waterIntakeCurrent}L / ${waterIntakeGoal}L`}</span>
                </div>

                {/* Progress Bar */}
                <div className="relative mt-4 h-6 bg-gray-200 rounded-full overflow-hidden">
                    <div
                        className="absolute top-0 left-0 h-full rounded-full"
                        style={{
                            width: `${waterIntakePercentage}%`,
                            backgroundColor: getColorForProgress(waterIntakePercentage),
                        }}
                    ></div>
                </div>

                {/* Clickable Water Droplets */}
                <div className="mt-4 flex justify-center space-x-2">
                    {[...Array(Math.ceil(waterIntakeGoal))].map((_, index) => (
                        <FaTint
                            key={index}
                            onClick={() => handleWaterClick(index)}
                            className={`cursor-pointer ${index < waterIntakeCurrent ? 'text-blue-500' : 'text-gray-400'
                                }`}
                            size={30}
                        />
                    ))}
                </div>

                <div className="mt-4 text-gray-600 text-center">
                    {waterIntakePercentage < 100
                        ? `Keep it up! Only ${(waterIntakeGoal - waterIntakeCurrent).toFixed(1)}L to go.`
                        : "Great job! You've met your water intake goal for the day."}
                </div>
            </div>
            <BackToTop />
        </div>
    );
};

export default Overview;
