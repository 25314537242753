import React, { useState } from "react";

const HeightSlider = ({ height, setHeight, setHeight_uom }) => {
    const [unit, setUnit] = useState("CM");
    const [inches, setInches] = useState(0);

    // Convert cm to feet and inches
    const cmToFtInches = (cm) => {
        const totalInches = Math.round(cm / 2.54);
        const feet = Math.floor(totalInches / 12);
        const inches = totalInches % 12;
        return { feet, inches };
    };

    // Convert feet and inches to cm
    const ftInchesToCm = (feet, inches) => {
        return Math.round((feet * 12 + inches) * 2.54);
    };

    // Handle height change from slider
    const handleHeightChange = (e) => {
        let newHeight = Number(e.target.value);
        if (unit === "FT") {
            newHeight = ftInchesToCm(newHeight, inches);
        }
        setHeight(newHeight);
        setHeight_uom(unit === "CM" ? "cm" : "ft");
    };

    // Handle inches change from the separate slider
    const handleInchesChange = (e) => {
        const newInches = Number(e.target.value);
        setInches(newInches);
        const totalHeightInCm = ftInchesToCm(Math.floor(height / 30.48), newInches);
        setHeight(totalHeightInCm);
    };

    // Handle height input change
    const handleHeightInputChange = (e) => {
        let inputHeight = Number(e.target.value);
        if (unit === "CM") {
            setHeight(inputHeight);
        } else {
            const feet = Math.floor(inputHeight / 12);
            const inputInches = inputHeight % 12;
            setHeight(ftInchesToCm(feet, inputInches));
            setInches(inputInches);
        }
    };

    // Toggle between CM and FT
    // const toggleUnit = () => {
    //     if (unit === "CM") {
    //         const { feet, inches } = cmToFtInches(height);
    //         setUnit("FT");
    //         setHeight(feet);
    //         setInches(inches);
    //     } else {
    //         setUnit("CM");
    //         setHeight(ftInchesToCm(height, inches));
    //     }
    // };

    const toggleUnit = () => {
        if (unit === "CM") {
            const { feet, inches } = cmToFtInches(height);
            setUnit("FT");
            setHeight(feet);
            setInches(inches);
        } else {
            // Use both feet and inches to correctly convert to centimeters
            const totalHeightInCm = ftInchesToCm(displayedHeight, displayedInches);
            setUnit("CM");
            setHeight(totalHeightInCm);
        }
    };


    // Determine slider min/max values based on unit
    const sliderMin = unit === "CM" ? 0 : 0;
    const sliderMax = unit === "CM" ? 250 : 7; // 250 cm or 7 feet

    // Displayed height (convert to feet if necessary)
    const displayedHeight = unit === "CM" ? height : Math.floor(height / 30.48);
    const displayedInches = unit === "CM" ? 0 : inches;

    return (
        <div className="text-center my-5">
            {/* Unit toggle buttons */}
            <div className="flex justify-center mb-5">
                <button
                    className={`border border-gray-300 py-2 px-5 cursor-pointer text-lg mx-1 ${unit === "CM" ? "bg-gray-300" : ""}`}
                    onClick={toggleUnit}
                >
                    CM
                </button>
                <button
                    className={`border border-gray-300 py-2 px-5 cursor-pointer text-lg mx-1 ${unit === "FT" ? "bg-gray-300" : ""}`}
                    onClick={toggleUnit}
                >
                    FT
                </button>
            </div>

            {/* Height Display */}
            <div className="text-5xl font-bold mb-5">
                {unit === "CM"
                    ? height
                    : `${displayedHeight}' ${displayedInches}"`} {unit}
            </div>

            {/* Input field for height */}
            <div className="mb-5">
                <label className="text-lg font-medium">
                    Enter height ({unit === 'FT' ? 'Inches' : 'cm'}):
                </label>
                <input
                    type="number"
                    value={unit === "CM" ? height : displayedHeight * 12 + inches}
                    onChange={handleHeightInputChange}
                    className="border border-gray-300 py-2 px-3 mx-2"
                />
            </div>

            {/* Height slider */}
            <div className="flex flex-col justify-end ">
                <div>
                    {unit === "FT" && (<span className="text-lg font-medium">Feet: </span>)}
                    < input
                        type="range"
                        min={sliderMin}
                        max={sliderMax}
                        value={displayedHeight}
                        className="slider ml-3 w-full h-1 bg-gray-200 rounded-lg cursor-pointer appearance-none"
                        onChange={handleHeightChange}
                        style={{
                            background: `linear-gradient(to right, #ED5700 ${(displayedHeight - sliderMin) / (sliderMax - sliderMin) * 100}%, #ddd 0%)`,
                            width: "300px",
                            height: "10px",
                        }}
                    />
                </div>

                {unit === "FT" && (
                    <div className="mt-5">
                        <span className="text-lg font-medium">Inches :</span>
                        <input
                            type="range"
                            min="0"
                            max="11"
                            value={inches}
                            className="slider w-full h-1 bg-gray-200 rounded-lg cursor-pointer appearance-none"
                            onChange={handleInchesChange}
                            style={{
                                background: `linear-gradient(to right, #ED5700 ${(inches / 11) * 100}%, #ddd 0%)`,
                                width: "300px",
                                height: "10px",
                            }}
                        />
                    </div>
                )}
            </div>

            <style jsx>{`
                .slider::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 20px;
                    height: 20px;
                    background: #ED5700;
                    border-radius: 5px;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    cursor: pointer;
                }
                .slider::-moz-range-thumb {
                    width: 20px;
                    height: 20px;
                    background: #ED5700;
                    border-radius: 5px;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default HeightSlider;
