import { createContext, useState, useContext, ReactNode } from 'react';

// Default values for wellness data
const defaultWellnessData = {
    hereditary_ailments: 'No',
    sleep_pattern: 'Regular',
    sleep_quality: 'Good',
    energy_level: 'Medium', // Fixed typo from 'mediam' to 'Medium'
    urinate_metric: 'Yes', // Capitalized 'Yes'
    chronic_conditions: 'No', // Capitalized 'No'
    daily_symptoms: 'No symptoms',
    recent_illness: 'Malaria',
    mental_health: 'None',
};



// Create the context with a default value
const WellnessContext = createContext(undefined);

// Create a provider component
export const WellnessProvider = ({ children }) => {
    const [wellnessData, setWellnessData] = useState(defaultWellnessData);

    return (
        <WellnessContext.Provider value={{ wellnessData, setWellnessData }}>
            {children}
        </WellnessContext.Provider>
    );
};

// Custom hook to use the context
export const useWellnessData = () => {
    const context = useContext(WellnessContext);
    if (context === undefined) {
        throw new Error('useWellnessData must be used within a WellnessProvider');
    }
    return context;
};

