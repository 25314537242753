import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import background from "../../media/pinstripe.png";
import Failure from "../../common/Failure";
import { REGEX } from "../../context/constants";
import ForgotPassword from "../../common/ForgotPassword";
import VerificationOtp from "../../common/VerificationOtp";
import { resetPassword } from "../../lib/actions/auth";
import Loader from "../../common/Loader";
import { useUserData } from "../../context/UserDataContext";
import Cookies from "js-cookie";

export default function ResetPassword({ email, otp, setResetPassword }) {
    useEffect(() => { sessionStorage.clear(); }, []); // clear sessionStorage
    const { userData, setUserData } = useUserData();
    const [showForgotModal, setShowForgotModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const password = watch("password"); // Watch the password field for real-time value access

    const signInWithEmailPassword = async (data) => {
        try {
            setLoading(true);
            const resetPasswordResponse = await resetPassword({
                password: data.password,
                password2: data.password2,
                otp: otp,
                email: email,
            });

            if (resetPasswordResponse === 200) {
                setResetPassword(false);
            }
            setLoading(false);
        } catch (error) {
            setError(true);
            console.error("Error signing in:", error);
        }
    };

    return (
        <div className="bg-white p-8 flex flex-col items-center justify-center w-full max-w-2xl">
            <div>
                <h1 className="text-4xl font-bold text-center mb-8">
                    Reset Password
                </h1>
            </div>

            <form
                className="w-full max-w-md"
                onSubmit={handleSubmit(signInWithEmailPassword)}
            >
                <div className="mb-4">
                    <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Password
                    </label>
                    <input
                        className="mt-1 block w-full border border-gray-300 focus:border-primary rounded-2xl min-h-[2.5rem] p-2 focus:outline-none focus:ring-2 focus:ring-primary"
                        type="password"
                        placeholder="Enter your password"
                        {...register("password", {
                            minLength: {
                                value: 8,
                                message: "Password is 8 characters minimum",
                            },
                            validate: {
                                lowercase: (v) =>
                                    Boolean(v.match(REGEX.lowercase)) ||
                                    "Password should have at least one lowercase character",
                                uppercase: (v) =>
                                    Boolean(v.match(REGEX.uppercase)) ||
                                    "Password should have at least one uppercase character",
                                number: (v) =>
                                    Boolean(v.match(REGEX.number)) ||
                                    "Password should have at least one numeric character",
                                specialCharacter: (v) =>
                                    Boolean(v.match(REGEX.specialCharacter)) ||
                                    "Password should have at least one special character",
                            },
                        })}
                    />
                    {errors.password && (
                        <div className="text-red-500 text-sm mt-2">
                            {errors.password.message?.toString()}
                        </div>
                    )}
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="password2"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Confirm Password
                    </label>
                    <input
                        className="mt-1 block w-full border border-gray-300 focus:border-primary rounded-2xl min-h-[2.5rem] p-2 focus:outline-none focus:ring-2 focus:ring-primary"
                        type="password"
                        placeholder="Confirm your password"
                        {...register("password2", {
                            validate: (value) =>
                                value === password || "Passwords do not match",
                        })}
                    />
                    {errors.password2 && (
                        <div className="text-red-500 text-sm mt-2">
                            {errors.password2.message?.toString()}
                        </div>
                    )}
                </div>

                <button
                    className="w-full bg-primary text-white py-2 rounded-2xl"
                    type="submit"
                >
                    Reset Password
                </button>
            </form>

            {error && (
                <div className="text-red-500 text-sm mt-2">
                    Error! Please check your credentials
                </div>
            )}
            {/* <button
                className="mt-3 text-primary"
                onClick={() => setShowForgotModal(true)}
            >
                Forgot Password?
            </button> */}
            {loading && <Loader />}
        </div>
    );
}
