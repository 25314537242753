import { Link } from "react-router-dom";
import avatar from "../../media/avatar-w-photo.png";
import { getProfileInCookies, getProfilePictureInCookies, getUser } from "../../lib/actions/profile";
import { capitalizeFirstLetter } from "../../lib/utils";
import { useEffect, useState } from "react";
import { useProfilePhoto } from "../../context/ProfilePhotoContext";

function Header() {
    const [user, setUser] = useState(getUser());
    const { isProfilePhotoUpdated } = useProfilePhoto();
    const profile = getProfileInCookies();
    const [greeting, setGreeting] = useState('');
    useEffect(() => {
        const currentHour = new Date().getHours();

        if (currentHour < 12) {
            setGreeting('Good Morning!');
        } else if (currentHour < 18) {
            setGreeting('Good Afternoon!');
        } else {
            setGreeting('Good Evening!');
        }
    }, []);
    //console.log("profilePicBase64 :::", profilePicBase64);
    useEffect(() => {
        setUser(getUser());
    }, [isProfilePhotoUpdated]);
    return (
        <header className="flex flex-row sticky  top-0 z-100 bg-gray-800 border-b border-slate-300 w-full items-center justify-center py-4 px-2">
            <div className="flex flex-row w-full max-w-5xl items-center justify-between gap-4 md:px-4">
                <div className="flex flex-col items-start justify-start gap-1 tracking-wider">
                    <div className="text-sm text-white">{greeting} {capitalizeFirstLetter(user.username)}</div>
                    <div className="text-lg font-semibold text-white leading-6">
                        Welcome Back!
                    </div>
                </div>
                {/* <SearchBar /> */}
                <div className="flex items-center gap-4">
                    <Link to="/dashboard/profile" className="hidden sm:block">
                        <span>🔔<sup className="text-primary">2</sup></span>

                        {/* <img
                            alt="Notifications"
                            src={notificationsIcon}
                            width={24}
                            height={24}
                            className="w-6 h-6 sm:w-5 sm:h-5"
                        /> */}
                    </Link>
                    <Link to="/dashboard/profile">
                        <img
                            alt="Profile"
                            src={profile?.photo_url}
                            // src={avatar}
                            width={40}
                            height={40}
                            className="w-10 h-10 rounded-full"
                        />
                    </Link>
                </div>
            </div>
        </header>
    );
}

export default Header;
