import { useState } from "react"
import Base from "./Base"
import { useForm } from "react-hook-form"
import { hideEmailAddress } from "../lib/utils"
import { Link } from "react-router-dom"
import { resendOtp } from "../lib/actions/auth"
import Loader from "./Loader"

function VerificationOtp({
    setClose,
    showClose,
    email,
    setResetPassword,
    setOtpString,
    setClose2
}) {
    const [failureMessage, setFailureMessage] = useState("")
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [isVerified, setIsVerified] = useState(false)
    const [isResendOtp, setIsResendOtp] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData("text")
        if (/^\d{6}$/.test(pastedText)) {
            ;[...pastedText].slice(0, 6).forEach((digit, i) => {
                const input = document.getElementById(`otp.${i}`)
                input.value = digit
            })
        }
    }

    const handleChange = (index) => (e) => {
        if (index < 5 && e.target.value) {
            const nextInput = document.getElementById(`otp.${index + 1}`)
            nextInput?.focus()
        }
    }

    const onSubmit = async (data) => {
        const otpArray = Object.values(data.otp)
        console.log("otpArray:::", otpArray.join(""));

        setOtpString(otpArray.join(""));
        setResetPassword(true);
        setClose(false);
        setClose2(false);
    }

    const resendOtpFunc = async () => {
        setIsLoading(true);
        const newOtp = await resendOtp({ email })
        setIsLoading(false);
        if (newOtp) {
            setIsResendOtp(true);
        }

    }

    return (
        <Base setClose={setClose} showClose={showClose}>
            <div className="flex flex-col max-w-xl mx-auto p-12 bg-white rounded-2xl shadow-lg gap-12">
                <div className="text-center">
                    <h2 className="text-2xl font-semibold text-gray-900 mb-3.5 tracking-wide">
                        Enter your verification code
                    </h2>
                    <p className="text-base font-normal text-gray-500">
                        We texted your code to {hideEmailAddress(email)}
                    </p>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    onPaste={handlePaste}
                    className="flex flex-col gap-12"
                >
                    <div className="flex justify-between gap-6">
                        {[...Array(6)].map((_, i) => (
                            <input
                                key={i}
                                id={`otp.${i}`}
                                type="text"
                                inputMode="numeric"
                                maxLength={1}
                                {...register(`otp[${i}]`, {
                                    required: `Field ${i + 1} is required`,
                                    pattern: {
                                        value: /^[0-9]$/,
                                        message: "Please enter a numeric value"
                                    },
                                    maxLength: {
                                        value: 1,
                                        message: "Only one digit is allowed per field"
                                    }
                                })}
                                onChange={handleChange(i)}
                                className="w-14 h-14 p-3 rounded-3xl border border-primary text-gray-900 text-center text-xl"
                            />
                        ))}
                    </div>

                    <div className="text-red-600 text-sm">
                        {(errors.otp &&
                            Object.values(errors.otp).length > 0 &&
                            Object.values(errors.otp)[0].message?.toString()) ||
                            failureMessage}
                    </div>
                    <div className="flex justify-between gap-4">
                        <button
                            onClick={() => {
                                resendOtpFunc()
                            }}
                            type="button"
                            className="w-full bg-orange-500 text-white rounded-lg py-3 border border-orange-600 hover:bg-orange-600"
                        >
                            Resend
                        </button>
                        <button
                            type="submit"
                            className="w-full bg-orange-500 text-white rounded-lg py-3 border border-orange-600 hover:bg-orange-600"
                        >
                            Continue
                        </button>
                    </div>
                </form>
                {isResendOtp && (
                    <span className="text-center text-green-600">Otp resent successfully...</span>
                )}
                {isLoading && (
                    <Loader />
                )}
            </div>
        </Base>
    )
}

export default VerificationOtp

// //during login
// import { useState } from "react"
// import Base from "./Base"
// import { FieldValues, useForm } from "react-hook-form"
// //import { resetPassword } from "@/lib/actions/auth"

// import { hideEmailAddress } from "../lib/utils"
// import { Link } from "react-router-dom"

// function VerificationOtp({
//     setClose,
//     showClose,
//     email,
//     setResetPassword,
//     setOtpString
// }) {
//     const [failureMessage, setFailureMessage] = useState("")
//     const {
//         register,
//         handleSubmit,
//         formState: { errors }
//     } = useForm()
//     const [isVerified, setIsVerified] = useState(false)

//     const handlePaste = (e) => {
//         const pastedText = e.clipboardData.getData("text")
//         console.log("pasted", pastedText)
//         if (/^\d{6}$/.test(pastedText)) {
//             ;[...pastedText].slice(0, 6).forEach((digit, i) => {
//                 const input = document.getElementById(`otp.${i}`)
//                 input.value = digit
//             })
//         }
//     }

//     const handleChange = (index) => (e) => {
//         if (index < 5 && e.target.value) {
//             const nextInput = document.getElementById(
//                 `otp.${index + 1}`
//             )
//             nextInput.focus()
//         }
//     }

//     const onSubmit = async (data) => {
//         setOtpString(data.otp.join(""));
//         setResetPassword(true);

//     }

//     return (
//         <Base setClose={setClose} showClose={showClose}>
//             <div className="flex flex-col max-w-xl mx-auto p-12 bg-white rounded-2xl shadow-lg gap-12">
//                 <div className="text-center">
//                     <h2 className="text-2xl font-semibold text-gray-900 mb-3.5 tracking-wide">
//                         Enter your verification code
//                     </h2>
//                     <p className="text-base font-normal text-gray-500">
//                         We texted your code to {hideEmailAddress(email)}
//                     </p>
//                 </div>
//                 <form
//                     onSubmit={handleSubmit(onSubmit)}
//                     onPaste={handlePaste}
//                     className="flex flex-col gap-12"
//                 >
//                     <div className="flex justify-between gap-6">
//                         {[...Array(6)].map((_, i) => (
//                             <input
//                                 key={i}
//                                 id={`otp.${i}`}
//                                 type="password"
//                                 inputMode="numeric"
//                                 maxLength={1}
//                                 {...register(`otp.${i}`, {
//                                     required: `Field ${i + 1} is required`,
//                                     pattern: {
//                                         value: /^[0-9]$/,
//                                         message: "Please enter a numeric value"
//                                     },
//                                     maxLength: {
//                                         value: 1,
//                                         message: "Only one digit is allowed per field"
//                                     }
//                                 })}
//                                 onChange={handleChange(i)}
//                                 className="w-14 h-14 p-3 rounded-3xl border border-primary text-gray-900 text-center text-xl"
//                             />
//                         ))}
//                     </div>

//                     <div className="text-red-600 text-sm">
//                         {(errors.otp &&
//                             Object.values(errors.otp).length > 0 &&
//                             Object.values(errors.otp)[0].message?.toString()) ||
//                             failureMessage}
//                     </div>
//                     <div className="flex justify-between gap-4">
//                         <button
//                             type="button"
//                             // onClick={() => forgotPassword({ email })}
//                             className="w-full bg-orange-500 text-white rounded-lg py-3 border border-orange-600 hover:bg-orange-600"
//                         >
//                             Resend
//                         </button>
//                         <button
//                             onClick={() => onSubmit()}
//                             type="submit"
//                             className="w-full bg-orange-500 text-white rounded-lg py-3 border border-orange-600 hover:bg-orange-600"
//                         >
//                             Continue
//                         </button>
//                     </div>
//                 </form>
//                 {isVerified && (
//                     <Link to="/en/connect/password">
//                         <span>Redirecting to Dashboard...</span>
//                     </Link>
//                 )}
//             </div>
//         </Base>
//     )
// }

// export default VerificationOtp
