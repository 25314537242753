import photo1 from "../../media/kebab-set-table-1.jpg";
import photo2 from "../../media/watch-checking.jpg";
import photo3 from "../../media/egg-with-meat.png";
import photo4 from "../../media/progress-tracking.jpg";
import Service from "./Service";

function Services() {
    return (
        <div className="relative w-full bg-white py-32">
            <div className="container mx-auto flex flex-col items-start gap-18 px-5 max-w-screen-xl">
                <h2 className="text-gray-800 text-4xl font-semibold leading-tight mb-10 text-center md:text-left">
                    We also offer unique services for your events
                </h2>

                <div className="flex flex-wrap 2xl:flex-nowrap justify-center gap-6 w-full">
                    {services.map((item, index) => (
                        <Service {...item} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
}

const services = [
    {
        title: "Customized Meal Plans",
        description:
            "Receive customized meal plans tailored to your dietary preferences, lifestyle, and fitness goals.",
        image: {
            src: photo1,
            alt: "Four plates of veg meals",
        },
    },
    {
        title: "Nutrition Tracking",
        description:
            "Track your daily intake of calories, macronutrients, vitamins, and minerals effortlessly.",
        image: {
            src: photo2,
            alt: "Person checking his watch",
        },
    },
    {
        title: "Recipe Suggestions",
        description:
            "Discover delicious and healthy recipes curated by nutrition experts to keep your meals exciting.",
        image: {
            src: photo3,
            alt: "Delicious meal with egg and meat",
        },
    },
    {
        title: "Progress Tracking",
        description:
            "Monitor your progress with intuitive charts and graphs, helping you stay motivated and accountable.",
        image: {
            src: photo4,
            alt: "Tracking progress using graphs",
        },
    },
];

export default Services;
