import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { useLifestyleData } from '@/context/LifeStyleDataContext';
// import { checkProFit } from '@/lib/actions/planType';
import lifestyleImg from "../../../media/details/lifestyle.png";
import bgImg from "../../../media/bg-meal.png";
import { useLifestyleData } from '../../../context/LifeStyleDataContext';
const LifeStyle2 = () => {
    //const router = useRouter();

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const allowRoute = await checkProFit();
        //         if (!allowRoute) {
        //             router.push("/en/dashboard");
        //         }
        //     } catch (error) {
        //         // Handle error
        //     }
        // };
        // fetchData();
    }, []);

    const { lifestyleData, setLifestyleData } = useLifestyleData();

    const handleInputChange = (field, value) => {
        setLifestyleData(prevData => ({
            ...prevData,
            [field]: value,
        }));
    };

    return (
        <div className="2xl:mt-5 max-w-xl mx-auto p-8 bg-white rounded-3xl shadow-xl text-center" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="mb-8 flex justify-between">
                <div className="flex justify-center items-center space-x-4">
                    <img src={lifestyleImg} alt="Logo" className="h-12" />
                    <h1 className="text-2xl font-semibold">LifeStyle</h1>
                </div>
                <div className="mt-4 text-gray-500">2 of 3</div>
            </div>
            <div className="space-y-6">
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Equipment</label>
                    <select
                        //  value={lifestyleData.equipments}
                        onChange={(e) => handleInputChange('equipments', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Personal Gym Kit">Personal Gym Kit</option>
                        <option value="No Equipment">No Equipment</option>
                        <option value="Basic Equipment">Basic Equipment</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Physical Condition</label>
                    <select
                        //   value={lifestyleData.physical_condition}
                        onChange={(e) => handleInputChange('physical_condition', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Fit">Fit</option>
                        <option value="Average">Average</option>
                        <option value="Out of Shape">Out of Shape</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Athletic Level</label>
                    <select
                        //  value={lifestyleData.athletic_level}
                        onChange={(e) => handleInputChange('athletic_level', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Work Classification</label>
                    <select
                        // value={lifestyleData.work_classification}
                        onChange={(e) => handleInputChange('work_classification', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Self Employed">Self Employed</option>
                        <option value="Employee">Employee</option>
                        <option value="Student">Student</option>
                        <option value="Unemployed">Unemployed</option>
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-8">
                <Link to="/dashboard/lifestyle">
                    <button className="px-4 py-2 bg-gray-200 rounded-md border border-primary">Back</button>
                </Link>
                <Link to="/dashboard/lifestyle3">
                    <button className="px-4 py-2 bg-primary text-white rounded-md ">Next</button>
                </Link>
            </div>
        </div>
    );
};

export default LifeStyle2;
