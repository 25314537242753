
import closeIcon from "../media/icons-close-line.svg"

function Base({
    children,
    setClose,
    showClose
}) {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-lg z-[9999]">
            <div className="relative flex items-start justify-center">
                {children}

                {showClose && (
                    <div
                        className="absolute top-[-1.70rem] right-[-0.6rem] cursor-pointer w-[2rem] h-[2rem] flex items-center justify-center bg-white shadow-md rounded-full"
                        onClick={() => (setClose ? setClose(false) : null)}
                    >
                        <img src={closeIcon} alt="Close" className="w-[1rem] h-[1rem]" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Base
