const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/

const UPPERCASE_REGEX = /[A-Z]/

const LOWERCASE_REGEX = /[a-z]/

const NUMBER_REGEX = /[0-9]/

const SPECIAL_CHARACTER_REGEX = /\W/

export const REGEX = {
    email: EMAIL_REGEX,
    uppercase: UPPERCASE_REGEX,
    lowercase: LOWERCASE_REGEX,
    number: NUMBER_REGEX,
    specialCharacter: SPECIAL_CHARACTER_REGEX
}
