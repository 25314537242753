import { useState } from 'react';
import { changePassword } from "../../lib/actions/profile"; // Adjust import path as necessary
import Loader from '../../common/Loader';

function ChangePassword() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const changePasswordFunc = async () => {
        setLoading(true);
        if (newPassword !== confirmPassword) {
            setError('New password and confirmation do not match');
            setSuccess(false);
            setLoading(false);
            return;
        }

        const response = await changePassword({ currentPassword, newPassword });

        setLoading(false);
        if (response) {
            setSuccess(true);
            setError(false);
        } else {
            setSuccess(false);
            setError('Password change failed, check your current password');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        changePasswordFunc();
    };

    return (
        <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
                <label
                    htmlFor="current-password"
                    className="block text-sm font-medium text-gray-700"
                >
                    Current Password
                </label>
                <div className="mt-1  rounded-md shadow-sm">
                    <input
                        type="password"
                        name="current-password"
                        id="current-password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="focus:ring-rose-500 focus:border-rose-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md h-[40px]"
                        placeholder="Current Password"
                        required
                    />
                </div>
            </div>

            <div>
                <label
                    htmlFor="new-password"
                    className="block text-sm font-medium text-gray-700"
                >
                    New Password
                </label>
                <div className="mt-1  rounded-md shadow-sm">
                    <input
                        type="password"
                        name="new-password"
                        id="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="focus:ring-rose-500 focus:border-rose-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md h-[40px]"
                        placeholder="New Password"
                        required
                    />
                </div>
                <p className="text-xs text-gray-500 mt-1">
                    Minimum 8 characters
                </p>
            </div>

            <div>
                <label
                    htmlFor="confirm-password"
                    className="block text-sm font-medium text-gray-700"
                >
                    Confirm New Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <input
                        type="password"
                        name="confirm-password"
                        id="confirm-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="focus:ring-rose-500 focus:border-rose-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md h-[40px]"
                        placeholder="Confirm Password"
                        required
                    />
                </div>
                <p className="text-xs text-gray-500 mt-1">
                    Minimum 8 characters
                </p>
            </div>
            {loading && <Loader />}
            {error && <p className="text-xs text-red-500 mt-1">{error}</p>}
            {success && <p className="text-xs text-green-500 mt-1">Password Changed Successfully</p>}
            <button
                type="submit"
                className='bg-primary text-white px-3 py-2 rounded-xl'
                disabled={loading}
            >
                {loading ? 'Changing Password...' : 'Change Password'}
            </button>
        </form>
    );
}

export default ChangePassword;
