
import img1 from "../media/meal-1.png"
import img2 from "../media/meal-2.png"
import img3 from "../media/delicious-food.jpg"

const DietModal = ({ onClose }) => {
    // if (!isVisible) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 mt-5">
            <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3">
                <div className="flex justify-between items-center p-4 border-b border-gray-300">
                    <h2 className="text-xl font-semibold">Ultimate Burrito Bowl</h2>
                    <button className="text-gray-500 hover:text-gray-700" onClick={() => onClose(false)}>&times;</button>
                </div>
                <div className="p-4">
                    <div className="mb-4">
                        <img src={img1} alt="Burrito Bowl" className="w-full rounded-lg mb-4" />
                        <div className="flex space-x-2">
                            <img src={img2} alt="Burrito Bowl 1" className="w-20 h-20 object-cover rounded-lg" />
                            <img src={img3} alt="Burrito Bowl 2" className="w-20 h-20 object-cover rounded-lg" />
                            <img src={img1} alt="Burrito Bowl 3" className="w-20 h-20 object-cover rounded-lg" />
                        </div>
                    </div>
                    <div className="mb-4">
                        <p>This bowl contains Mexican tomato rice loaded with toppings to give you a full-satisfying meal...</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-lg font-medium">517 Kcal</p>
                        <p>Carbs: 85% (46g)</p>
                        <p>Protein: 56% (28g)</p>
                    </div>
                </div>
                {/* <div className="p-4 border-t border-gray-300">
                    <button className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600">Add food item</button>
                </div> */}
            </div>
        </div>
    );
};

export default DietModal;
