import { getAccessToken, getProfileInCookies, getThreadId, getUserId } from "./profile";

export const postChatWithAi = async (userMessage) => {

    // const userProfileInCookies = getProfileIsCookies();
    //console.log("`${process.env.kIRUSA_BACKEND_ENDPOINT}/${getUserId()}/chat`,", `${process.env.kIRUSA_BACKEND_ENDPOINT}/${getUserId()}/chat`);

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/assistant/${getUserId()}/chat/`,
        {
            method: "POST",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                message: userMessage.message
            }),
        }
    );

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
};

export const getThread = async () => {
    //  const userProfileInCookies = getProfileIsCookies(); // Ensure this is correct
    const threadId = await getThreadId(); // Ensure this returns a valid ID

    const url = `${process.env.REACT_APP_OPENAI_API_ENDPOINT}/threads/${threadId}/messages`;
    const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
            "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_TOKEN}`,
            "OpenAI-Beta": "assistants=v2"
        }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
};

