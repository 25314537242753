import { createContext, useState, useContext } from 'react';

const defaultUserData = {
    name: '',
    gender: 'Male',
    fitness_goal: 'Weight Loss',
    bmi: 0,
    location: '',
    weight: 85,
    height: 165,
    age: 0,
    activity_level: 'lightly active',
    fitness_level: 'Beginner',
    bio: '',
    // id: '',
    diet_type: 'vegetarian',
    height_uom: "cm",
    weight_uom: "kg",
    target_weight: 85,
    profile_picture: '',
    // allergies: 'Vegetarian Diet'
};

const UserDataContext = createContext({
    userData: defaultUserData,
    setUserData: (userData) => { }
});

export const UserDataProvider = ({ children }) => {
    const [userData, setUserData] = useState(defaultUserData);

    return (
        <UserDataContext.Provider value={{ userData, setUserData }}>
            {children}
        </UserDataContext.Provider>
    );
};

export const useUserData = () => useContext(UserDataContext);
