import React, { createContext, useContext, useState } from "react";

// Create the context
const ProfilePhotoContext = createContext();

// Custom hook to access the context
export const useProfilePhoto = () => useContext(ProfilePhotoContext);

// ProfilePhotoProvider component to wrap around parts of the app that need this context
export const ProfilePhotoProvider = ({ children }) => {
    // Define the state to track if the profile photo has been updated
    const [isProfilePhotoUpdated, setIsProfilePhotoUpdated] = useState(false);

    // Function to update the photo update status
    const updateProfilePhotoStatus = (status) => {
        setIsProfilePhotoUpdated(status);
    };

    return (
        <ProfilePhotoContext.Provider value={{ isProfilePhotoUpdated, updateProfilePhotoStatus }}>
            {children}
        </ProfilePhotoContext.Provider>
    );
};
