import clockIcon from "../../media/white-clock.svg";
import receiptIcon from "../../media/outline-receipt-tax.svg";
import shoppintCartIcon from "../../media/outline-shopping-cart.svg";
import image from "../../media/delicious-food.png";

function Working() {
    return (
        <section className="w-full bg-neutral-50 py-32 box-border overflow-hidden flex items-center">
            <div className="container flex justify-between gap-24 max-w-full box-border">
                <div className="flex justify-center basis-[745px] h-[627px] flex-grow-0 overflow-hidden">
                    <img
                        className="w-full h-full object-cover rounded-md"
                        src={image}
                        alt="Healthy food"
                    />
                </div>

                <div className="flex flex-col gap-12 max-w-[461px] 2xl:max-w-[800px] flex-grow-0 box-border">
                    <div className="flex flex-col items-start gap-5 w-full">
                        <h2 className="text-neutral-800 text-4xl font-semibold leading-[60.5px] mx-auto">
                            How our Food recipe works
                        </h2>
                        <p className="text-neutral-700 text-base font-normal leading-6 pr-6">
                            Welcome to your personalized solution for achieving your health
                            and fitness goals. Whether you&apos;re looking to lose weight,
                            gain muscle, or simply improve your overall well-being, our
                            comprehensive diet planner is here to guide you every step of the
                            way.
                        </p>
                    </div>
                    <ul className="flex flex-col items-start gap-5 w-full">
                        {list.map((item, index) => (
                            <li
                                className="flex items-start gap-5 max-w-full box-border"
                                key={index}
                            >
                                <div className="flex items-center justify-center w-8 h-8 bg-primary rounded-full">
                                    <img
                                        className="w-4 h-4 object-contain"
                                        src={item.icon}
                                        alt={item.alt}
                                    />
                                </div>
                                <span className="text-neutral-800 text-lg font-medium leading-6">
                                    {item.info}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
}

const list = [
    { icon: clockIcon, alt: "Clock", info: "Personalization" },
    { icon: receiptIcon, alt: "Receipt", info: "Meal Planning" },
    { icon: shoppintCartIcon, alt: "Cart", info: "Track And Adjust" },
    { icon: shoppintCartIcon, alt: "Cart", info: "Achieve Your Goals" }
];

export default Working;
