
function Service({ title, description, image, ...props }) {
    return (
        <div
            className="w-[306px] min-h-[467px] pb-9 rounded-md bg-white shadow-lg"
            {...props}
        >
            <div className="flex flex-col gap-7">
                <img
                    className="w-full h-[320px] object-cover rounded-md"
                    src={image.src}
                    alt={image.alt}
                />
                <div className="flex flex-col items-start gap-4 px-4">
                    <h3 className="text-gray-800 text-2xl font-bold leading-[30px] tracking-tight">
                        {title}
                    </h3>
                    <p className="text-gray-700 text-base font-normal leading-[24px]">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Service;
