
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { useLifestyleData } from '@/context/LifeStyleDataContext';
// import { checkProFit } from '@/lib/actions/planType';
import lifestyleImg from "../../../media/details/lifestyle.png";
import bgImg from "../../../media/bg-meal.png";
import { useLifestyleData } from '../../../context/LifeStyleDataContext';
const LifeStyle = () => {
    //const router = useRouter();
    const { lifestyleData, setLifestyleData } = useLifestyleData();

    // useEffect(() => {
    //     // const fetchData = async () => {
    //     //     try {
    //     //         const allowRoute = await checkProFit();
    //     //         if (!allowRoute) {
    //     //             router.push("/en/dashboard");
    //     //         }
    //     //     } catch (error) {
    //     //         // Handle error
    //     //         console.error("Error checking fit:", error);
    //     //     }
    //     // };
    //     // fetchData();
    // }, [router]);

    const handleInputChange = (field, value) => {
        setLifestyleData(prevData => ({
            ...prevData,
            [field]: value,
        }));
    };

    return (
        <div className="2xl:mt-5 max-w-xl mx-auto p-8 bg-white rounded-3xl shadow-xl text-center" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="mb-8 flex justify-between">
                <div className="flex justify-center items-center space-x-4">
                    <img src={lifestyleImg} alt="Lifestyle" className="h-12" />
                    <h1 className="text-2xl font-semibold">LifeStyle</h1>
                </div>
                <div className="mt-4 text-gray-500">1 of 3</div>
            </div>
            <div className="space-y-6">
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Water Intake</label>
                    <select
                        // value={lifestyleData.water_intake}
                        onChange={(e) => handleInputChange('water_intake', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="3.8L/day">3.8L/day</option>
                        <option value="2.5L/day">2.5L/day</option>
                        <option value="1.5L/day">1.5L/day</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Activity Level</label>
                    <select
                        // value={lifestyleData.activity_level}
                        onChange={(e) => handleInputChange('activity_level', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Level 1">Level 1</option>
                        <option value="Level 2">Level 2</option>
                        <option value="Level 3">Level 3</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Activity Count</label>
                    <select
                        // value={lifestyleData.activity_count}
                        onChange={(e) => handleInputChange('activity_count', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="5 miles/day">5 miles/day</option>
                        <option value="3 miles/day">3 miles/day</option>
                        <option value="1 mile/day">1 mile/day</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Rest Days</label>
                    <select
                        //  value={lifestyleData.rest_days}
                        onChange={(e) => handleInputChange('rest_days', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-8">
                {/* <Link to="/dashboard/other-issues"> */}
                <Link to="/dashboard/other-issues">
                    <button className="px-4 py-2 bg-gray-200 border border-primary rounded-md">Back</button>
                </Link>
                <Link to="/dashboard/lifestyle2">
                    <button className="px-4 py-2 bg-primary text-white rounded-md">Next</button>
                </Link>
            </div>
        </div>
    );
};

export default LifeStyle;
