import Cookies from "js-cookie";

export const getUser = () => {
    const userFromCookies = Cookies.get('user');
    let user;
    if (userFromCookies) {
        user = JSON.parse(userFromCookies)
    }
    return user
}
export const getUserId = () => {
    return getUser().id
}
export const getAccessToken = () => {
    return getUser().access
}
export const createProfile = async (data) => {

    let requestBody = {
        ...data,
        user_id: getUserId() // Include user ID in the payload
    };


    try {


        const response = await fetch(
            `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/create/profile/`,
            {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAccessToken()}`
                }),
                body: JSON.stringify({
                    ...data,
                    user: getUserId()
                })
            }
        )

        if (!response.ok) {
            const errorResponse = await response.json()
            throw new Error(
                `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
            )
        }

        const jsonResponse = await response.json()

        return jsonResponse
    } catch (error) {
        console.error("Error:", error)
        // Optionally, handle error response here
        return null
    }
}

export const updateProfile = async (data) => {
    const formData = new FormData();  // Create a new FormData object

    // Append form fields to FormData, including the user_id
    formData.append('user', getUserId());

    // Assuming `data` contains form fields (like name, location, bio, etc.), append each one
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
        }
    }

    console.log("FormData contents:", [...formData.entries()]);

    try {
        const response = await fetch(
            `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/update/profile/${getUserId()}`,
            {
                method: "PUT",
                headers: new Headers({
                    // Remove "Content-Type" header for FormData
                    "Authorization": `Bearer ${getAccessToken()}`
                }),
                body: formData  // Pass FormData directly as the body
            }
        );

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(
                `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
            );
        }

        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }
};



export const updateProfilePhoto = async (formData) => {
    formData.append('user', getUserId());
    try {
        const response = await fetch(
            `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/update/profile/${getUserId()}`,
            {
                method: "PUT",
                headers: new Headers({
                    // Remove "Content-Type" header for FormData
                    "Authorization": `Bearer ${getAccessToken()}`
                }),
                body: formData  // Pass FormData directly as the body
            }
        );

        return await response.json();
        // if (response.ok) {
        //     alert("Image uploaded successfully");
        // } else {
        //     alert("Image upload failed");
        // }
    } catch (error) {
        console.error("Error uploading image:", error);
        alert("An error occurred while uploading the image");
    }
};



// export const updateProfile = async (data) => {

//     let requestBody = {
//         ...data,
//         user_id: getUserId() // Include user ID in the payload
//     };

//     try {


//         const response = await fetch(
//             `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/update/profile/${getUserId()}`,
//             {
//                 method: "PUT",
//                 headers: new Headers({
//                     "Content-Type": "application/json",
//                     "Authorization": `Bearer ${getAccessToken()}`
//                 }),
//                 body: JSON.stringify({
//                     ...data,
//                     user: getUserId()
//                 })
//             }
//         )

//         if (!response.ok) {
//             const errorResponse = await response.json()
//             throw new Error(
//                 `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
//             )
//         }

//         const jsonResponse = await response.json()
//         //  console.log("ressssssss:", jsonResponse);

//         return jsonResponse
//     } catch (error) {
//         console.error("Error:", error)
//         // Optionally, handle error response here
//         return null
//     }
// }

export const getUserProfile = async () => {
    // console.log("rofrfkrfrofrkf");
    // console.log("${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/profiles/${getUserId()}::", `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/profiles/${getUserId()}}`);

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/profiles/${getUserId()}/`,
        {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json"
            }),
        }
    );

    const data = await response.json()

    return data

}
export const changePassword = async (data) => {
    const currentPassword = data.currentPassword;
    const newPassword = data.newPassword;
    const user_id = getUserId();
    try {
        const response = await fetch(
            `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/user/update_password`,
            {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json"
                }),
                body: JSON.stringify({
                    user_id,
                    password: currentPassword,
                    new_password: newPassword
                })
            }
        )

        if (!response.ok) {
            const errorResponse = await response.json()
            throw new Error(
                `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
            )
        }

        const jsonResponse = await response.json()

        return jsonResponse
    } catch (error) {
        console.error("Error:", error)
        // Optionally, handle error response here
        return null
    }
}

//Should be getProfileInCookies
export const getProfileInCookies = () => {
    const profileFromCookies = Cookies.get('profile');
    let profile;
    if (profileFromCookies) {
        profile = JSON.parse(profileFromCookies)
    }
    return profile
}
//Should be getProfilePictureInCookies
export const getProfilePictureInCookies = () => {
    const profile_url = Cookies.get('profile_url');
    // console.log(profilePicBase64);

    let profilePic;
    if (profile_url) {
        profilePic = JSON.parse(profile_url)
    } else {
        profilePic = JSON.parse(localStorage.getItem('profile_url'));
    }
    // console.log("======", profilePic);

    return profilePic;
}
export const deleteUSer = async () => {
    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/delete_account/${getUserId()}/`,
        {
            method: "DELETE",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`
            }),
        }
    );

    const data = await response.json();
    return data

}

export const getThreadId = async () => {

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/${getUserId()}/thread`,
        {
            method: "GET"
        }
    );

    const data = await response.json()

    return data.thread;

    //use Get ThreadId
    //UserID/thread
    //return "thread_kqTlQ5E28nGySiHhYSWtxBoe";
}


export const addWomanCycle = async (wonenCycleData) => {

    //add UserId
    let requestBody = {
        ...wonenCycleData,
        user_id: getUserId() // Include user ID in the payload
    };
    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/add_cycle`,
        {
            method: "POST",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                start_date: wonenCycleData.start_date,
                end_date: wonenCycleData.end_date,
                cycle_length: wonenCycleData.cycle_length,
                period_length: wonenCycleData.period_length,
                flow_intensity: wonenCycleData.flow_intensity,
                symptoms: wonenCycleData.symptoms,
                pre_cycle_symptoms: wonenCycleData.pre_cycle_symptoms,
                notes: wonenCycleData.notes,
                user_id: getUserId(),
            }),
        }
    );



    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();

    return data;
};

// import Cookies from "js-cookie";

// export const getUser = () => {
//     const userFromCookies = Cookies.get('user');
//     let user;
//     if (userFromCookies) {
//         user = JSON.parse(userFromCookies)
//     }
//     return user
// }
// export const getUserId = () => {
//     return getUser().id
// }
// export const getAccessToken = () => {
//     return getUser().access
// }
// export const createProfile = async (data) => {

//     let requestBody = {
//         ...data,
//         user_id: getUserId() // Include user ID in the payload
//     };


//     try {


//         const response = await fetch(
//             `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/create/profile/`,
//             {
//                 method: "POST",
//                 headers: new Headers({
//                     "Content-Type": "application/json",
//                     "Authorization": `Bearer ${getAccessToken()}`
//                 }),
//                 body: JSON.stringify({
//                     ...data,
//                     user: getUserId()
//                 })
//             }
//         )

//         if (!response.ok) {
//             const errorResponse = await response.json()
//             throw new Error(
//                 `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
//             )
//         }

//         const jsonResponse = await response.json()

//         return jsonResponse
//     } catch (error) {
//         console.error("Error:", error)
//         // Optionally, handle error response here
//         return null
//     }
// }

// export const updateProfile = async (data) => {
//     const formData = new FormData();  // Create a new FormData object

//     // Append form fields to FormData, including the user_id
//     formData.append('user_id', getUserId());

//     // Assuming `data` contains form fields (like name, location, bio, etc.), append each one
//     for (const key in data) {
//         if (data.hasOwnProperty(key)) {
//             formData.append(key, data[key]);
//         }
//     }

//     console.log("FormData contents:", [...formData.entries()]);

//     try {
//         const response = await fetch(
//             `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/update/profile/${getUserId()}`,
//             {
//                 method: "PUT",
//                 headers: new Headers({
//                     // Remove "Content-Type" header for FormData
//                     "Authorization": `Bearer ${getAccessToken()}`
//                 }),
//                 body: formData  // Pass FormData directly as the body
//             }
//         );

//         if (!response.ok) {
//             const errorResponse = await response.json();
//             throw new Error(
//                 `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
//             );
//         }

//         const jsonResponse = await response.json();
//         return jsonResponse;
//     } catch (error) {
//         console.error("Error:", error);
//         return null;
//     }
// };


// // export const updateProfile = async (data) => {

// //     let requestBody = {
// //         ...data,
// //         user_id: getUserId() // Include user ID in the payload
// //     };

// //     try {


// //         const response = await fetch(
// //             `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/update/profile/${getUserId()}`,
// //             {
// //                 method: "PUT",
// //                 headers: new Headers({
// //                     "Content-Type": "application/json",
// //                     "Authorization": `Bearer ${getAccessToken()}`
// //                 }),
// //                 body: JSON.stringify({
// //                     ...data,
// //                     user: getUserId()
// //                 })
// //             }
// //         )

// //         if (!response.ok) {
// //             const errorResponse = await response.json()
// //             throw new Error(
// //                 `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
// //             )
// //         }

// //         const jsonResponse = await response.json()
// //         //  console.log("ressssssss:", jsonResponse);

// //         return jsonResponse
// //     } catch (error) {
// //         console.error("Error:", error)
// //         // Optionally, handle error response here
// //         return null
// //     }
// // }

// export const getUserProfile = async () => {
//     // console.log("rofrfkrfrofrkf");
//     // console.log("${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/profiles/${getUserId()}::", `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/profiles/${getUserId()}}`);

//     const response = await fetch(
//         `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/user/profiles/${getUserId()}/`,
//         {
//             method: "GET",
//             headers: new Headers({
//                 "Authorization": `Bearer ${getAccessToken()}`,
//                 "Content-Type": "application/json"
//             }),
//         }
//     );

//     const data = await response.json()

//     return data

// }
// export const changePassword = async (data) => {
//     const currentPassword = data.currentPassword;
//     const newPassword = data.newPassword;
//     const user_id = getUserId();
//     try {
//         const response = await fetch(
//             `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/user/update_password`,
//             {
//                 method: "POST",
//                 headers: new Headers({
//                     "Content-Type": "application/json"
//                 }),
//                 body: JSON.stringify({
//                     user_id,
//                     password: currentPassword,
//                     new_password: newPassword
//                 })
//             }
//         )

//         if (!response.ok) {
//             const errorResponse = await response.json()
//             throw new Error(
//                 `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorResponse)}`
//             )
//         }

//         const jsonResponse = await response.json()

//         return jsonResponse
//     } catch (error) {
//         console.error("Error:", error)
//         // Optionally, handle error response here
//         return null
//     }
// }

// //Should be getProfileInCookies
// export const getProfileInCookies = () => {
//     const profileFromCookies = Cookies.get('profile');
//     let profile;
//     if (profileFromCookies) {
//         profile = JSON.parse(profileFromCookies)
//     }
//     return profile
// }
// //Should be getProfilePictureInCookies
// export const getProfilePictureInCookies = () => {
//     const profilePicBase64 = Cookies.get('profile_picture');
//     // console.log(profilePicBase64);

//     let profilePic;
//     if (profilePicBase64) {
//         profilePic = JSON.parse(profilePicBase64)
//     } else {
//         profilePic = JSON.parse(localStorage.getItem('profile_picture'));
//     }
//     // console.log("======", profilePic);

//     return profilePic;
// }
// export const deleteUSer = async () => {
//     const response = await fetch(
//         `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/api/delete_account/${getUserId()}/`,
//         {
//             method: "DELETE",
//             headers: new Headers({
//                 "Authorization": `Bearer ${getAccessToken()}`
//             }),
//         }
//     );

//     const data = await response.json();
//     return data

// }

// export const getThreadId = async () => {

//     const response = await fetch(
//         `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/${getUserId()}/thread`,
//         {
//             method: "GET"
//         }
//     );

//     const data = await response.json()

//     return data.thread;

//     //use Get ThreadId
//     //UserID/thread
//     //return "thread_kqTlQ5E28nGySiHhYSWtxBoe";
// }


// export const addWomanCycle = async (wonenCycleData) => {

//     //add UserId
//     let requestBody = {
//         ...wonenCycleData,
//         user_id: getUserId() // Include user ID in the payload
//     };
//     const response = await fetch(
//         `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/add_cycle`,
//         {
//             method: "POST",
//             headers: new Headers({
//                 "Authorization": `Bearer ${getAccessToken()}`,
//                 "Content-Type": "application/json",
//             }),
//             body: JSON.stringify({
//                 start_date: wonenCycleData.start_date,
//                 end_date: wonenCycleData.end_date,
//                 cycle_length: wonenCycleData.cycle_length,
//                 period_length: wonenCycleData.period_length,
//                 flow_intensity: wonenCycleData.flow_intensity,
//                 symptoms: wonenCycleData.symptoms,
//                 pre_cycle_symptoms: wonenCycleData.pre_cycle_symptoms,
//                 notes: wonenCycleData.notes,
//                 user_id: getUserId(),
//             }),
//         }
//     );



//     if (!response.ok) {
//         throw new Error(`Error: ${response.statusText}`);
//     }

//     const data = await response.json();

//     return data;
// };
