import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import lifestyleImg from "../../../media/details/lifestyle.png";
// import { useLifestyleData } from '@/context/LifeStyleDataContext';
// import { addLifeStyleData } from '@/lib/actions/lifestyle';
// import { checkProFit } from '@/lib/actions/planType';
import bgImg from "../../../media/bg-meal.png";
import { useLifestyleData } from '../../../context/LifeStyleDataContext';
import Loader from '../../../common/Loader';
import { addLifeStyleData } from '../../../lib/actions/preferences';
import { useNavigate } from "react-router-dom";

const LifeStyle3 = () => {
    // const router = useRouter();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const allowRoute = await checkProFit();
        //         if (!allowRoute) {
        //             router.push("/en/dashboard");
        //         }
        //     } catch (error) {
        //         // Handle error
        //     }
        // };
        // fetchData();
    }, []);

    const { lifestyleData, setLifestyleData } = useLifestyleData();

    const handleInputChange = (field, value) => {
        setLifestyleData(prevData => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await addLifeStyleData(lifestyleData);
            //TO DO
            //  if (response) {
            navigate("/dashboard/preference");
            // }
            //router.push('/en/dashboard');
        } catch (error) {
            setLoading(false);
            // Handle error
        }
    };

    return (
        <div className="2xl:mt-5 max-w-xl mx-auto p-8 bg-white rounded-3xl shadow-xl text-center" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="mb-8 flex justify-between">
                <div className="flex justify-center items-center space-x-4">
                    <img src={lifestyleImg} alt="Logo" className="h-12" />
                    <h1 className="text-2xl font-semibold">LifeStyle</h1>
                </div>
                <div className="mt-4 text-gray-500">3 of 3</div>
            </div>
            <div className="space-y-6">
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Diet History</label>
                    <select
                        //  value={lifestyleData.diet_history}
                        onChange={(e) => handleInputChange('diet_history', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Gym Experience</label>
                    <select
                        //   value={lifestyleData.gym_experience}
                        onChange={(e) => handleInputChange('gym_experience', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Regular">Regular</option>
                        <option value="Occasional">Occasional</option>
                        <option value="Never">Never</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Plan Preference</label>
                    <select
                        //   value={lifestyleData.plan_preference}
                        onChange={(e) => handleInputChange('plan_preference', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Home">Home</option>
                        <option value="Gym">Gym</option>
                        <option value="Outdoor">Outdoor</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Fun Activities</label>
                    <select
                        //   value={lifestyleData.fun_activities}
                        onChange={(e) => handleInputChange('fun_activities', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Hiking">Hiking</option>
                        <option value="Cycling">Cycling</option>
                        <option value="Swimming">Swimming</option>
                    </select>
                </div>
                <div>
                    <label className="block text-left mb-2 text-sm font-medium">Shopping Day</label>
                    <select
                        //    value={lifestyleData.shopping_day}
                        onChange={(e) => handleInputChange('shopping_day', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-8">
                <Link to="/dashboard/lifestyle2">
                    <button className="px-4 py-2 bg-gray-200  border border-primary rounded-md">Back</button>
                </Link>
                <Link to="/dashboard/preference">
                    <button
                        onClick={handleSubmit}
                        className={`px-4 py-2 bg-primary text-white rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </Link>
                {loading && <Loader />}
            </div>
        </div>
    );
};

export default LifeStyle3;
