import menu from "../../media/restaurant-menu.svg";
import plan from "../../media/plan.svg";
import timer from "../../media/timer.svg";

function Benefits() {
    return (
        <section className="relative w-full bg-white py-20">
            <div className="container flex justify-between items-start md:flex-nowrap flex-wrap">
                {info.map((item, index) => (
                    <div
                        className="flex justify-between items-start w-[366px] min-h-[90px] gap-6 mb-8"
                        key={index}
                    >
                        <img
                            className="w-[48px] h-[48px] object-contain"
                            src={item.icon}
                            alt={item.alt}
                        />
                        <div className="flex flex-col items-start flex-grow gap-4">
                            <h2 className="text-[#2C2F24] text-[30px] font-bold leading-[26px]">
                                {item.title}
                            </h2>
                            <p className="text-[#414536] text-[18px] font-normal leading-[26.7px]">
                                {item.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

const info = [
    {
        icon: menu,
        alt: "Personalisation",
        title: "Personalization",
        description:
            "In the new era of technology we look in the future with certainty life.",
    },
    {
        icon: plan,
        alt: "Meal Planning",
        title: "Meal Planning",
        description:
            "In the new era of technology we look in the future with certainty life.",
    },
    {
        icon: timer,
        alt: "Achieve Your Goals",
        title: "Achieve Your Goals",
        description:
            "In the new era of technology we look in the future with certainty life.",
    },
];

export default Benefits;
