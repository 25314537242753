import { createContext, useState, useContext, ReactNode } from 'react';

// Define default values for cycle data
const defaultCycleData = {
    start_date: '18/08/2024',
    end_date: '21/08/2024',
    cycle_length: '5',
    period_length: '28',
    flow_intensity: 'medium',
    symptoms: 'pain',
    pre_cycle_symptoms: 'No symptoms',
    // notes: 'None',
};


// Create the context with a default value
const CycleDataContext = createContext(undefined);

// Define the provider component
export const CycleDataProvider = ({ children }) => {
    const [cycleData, setCycleData] = useState(defaultCycleData);

    return (
        <CycleDataContext.Provider value={{ cycleData, setCycleData }}>
            {children}
        </CycleDataContext.Provider>
    );
};

// Custom hook to use the context
export const useCycleData = () => {
    const context = useContext(CycleDataContext);
    if (context === undefined) {
        throw new Error('useCycleData must be used within a CycleDataProvider');
    }
    return context;
};