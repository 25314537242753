import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { FaBars } from 'react-icons/fa';
import { useState } from 'react';

function Dashboard() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <div className="dashboard-layout flex">
            {/* Sidebar with the toggle logic */}
            <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            <div className="flex-1">
                <Navbar />
                <div className="p-4">
                    {/* Toggle button for small screens */}
                    <FaBars className="text-xl text-primary cursor-pointer mb-5 lg:hidden" onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
                    {/* Outlet will render the matched child route component */}
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
