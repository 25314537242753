import React from 'react';
import food1 from "../../media/food1.png";
import food2 from "../../media/food2.png";

const mealPlans = [
    {
        name: 'EGG-VEGETABLES SALAD',
        energy: 400,
        description: 'make a salad using 04 hard boiled eggs plus broccoli, lettuce, and cabbage',
        imageUrl: food1
    },
    {
        name: 'FRUIT SALAD DESSERT',
        energy: 25,
        description: 'take 5 different fruits : banana, mango, avocado, orange, grapes',
        imageUrl: food2
    },
    {
        name: 'VEG CHOWMEIN',
        energy: 100,
        description: 'boil 250 g of chowmein and mix it with onions, cabbage, garlic, and ginger',
        imageUrl: food1
    },
    {
        name: 'VEG KORMA + CHAPATI',
        energy: 100,
        description: 'take a weight of 5 Kg. Put one hand on the soil and one knee on the ground',
        imageUrl: food2
    },
    {
        name: 'PEAS WITH VEGETABLES',
        energy: 85,
        description: 'take a weight of 5 Kg. Put one hand on the soil and one knee on the ground',
        imageUrl: food1
    },
    {
        name: 'CHEAT MEAL',
        energy: 100,
        description: 'one coffee drink and a jar of yoghurt, mixed to grapes',
        imageUrl: food2
    }
];

const MealPlans = () => {
    return (
        <div className="p-5 bg-gray-100">
            <div className="pb-5">
                <h3 className="text-2xl font-bold mb-4">Meals Plans</h3>
                <header className="flex justify-between items-center mb-6">
                    <h1 className="text-4xl font-semibold">Generated meal plans</h1>
                    <button className="bg-orange-600 text-white px-4 py-2 rounded shadow-md hover:bg-orange-700">
                        New meal plan
                    </button>
                </header>
            </div>
            <section className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {mealPlans.map((mealPlan) => (
                    <div
                        key={mealPlan.name}
                        className="bg-white rounded-lg shadow-md overflow-hidden min-w-[250px]" // Minimum width applied here
                    >
                        <img
                            src={mealPlan.imageUrl}
                            alt={mealPlan.name}
                            className="w-full h-48 object-cover"
                        />
                        <div className="p-4">
                            <h3 className="text-xl font-semibold mb-2">NAME : {mealPlan.name}</h3>
                            <p className="text-gray-700 mb-1">ENERGY : {mealPlan.energy} Kcal</p>
                            <p className="text-gray-600">DESCRIPTION : {mealPlan.description}</p>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    );
};

export default MealPlans;
