import { useEffect, useState, useRef } from "react";
import { formatTimestamp } from "../../lib/utils";
import avatar from "../../media/avatar-w-photo.png";
import aiBot from "../../media/aiBot.png";
import { getThread, postChatWithAi } from "../../lib/actions/chat";
import Loader from "../../common/Loader";
import { BackToTop } from "../../common/BackToTop";

const AiChat = () => {
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const currentTimeStampInSeconds = Math.floor(Date.now() / 1000);
    const messagesEndRef = useRef(null);
    const chatContainerRef = useRef(null);

    const getThreadFunc = async () => {
        setLoading(true);
        const getPreviousChat = await getThread();
        setLoading(false);

        let previousChatData = getPreviousChat.data;
        let messages1 = [];

        for (let i = 0; i < previousChatData.length; i++) {
            const messageItem = {
                id: previousChatData[i].id,
                text: previousChatData[i].content[0].text.value,
                type: previousChatData[i].role === "user" ? "user" : "assistant",
                timestamp: previousChatData[i].created_at,
            };
            messages1 = [...messages1, messageItem];
        }

        setMessages(messages1.reverse());
    };

    useEffect(() => {
        getThreadFunc();
    }, []);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    // const scrollToTop = () => {
    //     if (chatContainerRef.current) {
    //         chatContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    //     }
    // };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const handleScroll = () => {
            if (chatContainerRef.current.scrollTop > 200) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        if (chatContainerRef.current) {
            chatContainerRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (chatContainerRef.current) {
                chatContainerRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    const sendMessage = async () => {
        if (input.trim() === "") return;

        const userMessage = {
            id: Date.now(),
            text: input,
            type: "user",
            timestamp: Math.floor(Date.now() / 1000),
        };

        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInput("");

        try {
            setLoading(true);
            const postMessage = await postChatWithAi({ message: input });
            setLoading(false);

            const aiMessage = {
                id: Date.now() + 1,
                text: postMessage?.message,
                type: "assistant",
                timestamp: Math.floor(Date.now() / 1000),
            };

            setMessages((prevMessages) => [...prevMessages, aiMessage]);

        } catch (error) {
            console.error("Error posting message", error);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <div className="flex justify-center items-center w-full h-full bg-gray-100 font-sans">
            <div className="flex flex-col w-full min-h-[400px] bg-white shadow-lg rounded-lg overflow-hidden">
                <div ref={chatContainerRef} className="flex flex-col flex-1 p-5 overflow-y-auto">
                    <div className="flex flex-col gap-4">
                        {messages.map((msg) => (
                            <div key={msg.id} className={`flex ${msg.type === "user" ? "justify-start" : "justify-end"}`}>
                                {msg.type === "user" && (
                                    <div className="flex items-center">
                                        <img
                                            src={avatar}
                                            alt="User avatar"
                                            className="w-10 h-10 rounded-full mr-2 mt-3"
                                        />
                                        <div className="max-w-xs p-3 bg-gray-200 rounded-2xl text-gray-800 min-w-[200px] mt-3">
                                            <div>{msg.text}</div>
                                            <span className="text-xs text-gray-500">{formatTimestamp(msg.timestamp)}</span>
                                        </div>
                                    </div>
                                )}
                                {msg.type === "assistant" && (
                                    <div className="flex items-center">
                                        <img
                                            src={aiBot}
                                            alt="AI avatar"
                                            className="w-10 h-10 rounded-full mr-2"
                                        />
                                        <div className="max-w-xs p-3 bg-blue-500 text-white rounded-2xl">
                                            <div>{msg.text}</div>
                                            <span className="text-xs">{formatTimestamp(msg.timestamp)}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                </div>
                {loading && <Loader />}
                <div className="flex p-4 border-t border-gray-200 bg-gray-50">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Enter your question..."
                        onKeyDown={handleKeyDown}
                        className="flex-grow p-2 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                    />
                    <button
                        onClick={sendMessage}
                        className="ml-3 px-4 py-2 bg-orange-600 text-white rounded-full hover:bg-orange-500"
                    >
                        Send
                    </button>
                </div>
                {/* {showScrollToTop && (
                    <button
                        onClick={scrollToTop}
                        className=" bg-blue-500 text-primary p-3 rounded-full shadow-lg"
                    >
                        Move to Top
                    </button>
                )} */}
            </div>
            <BackToTop />
        </div>
    );
};

export default AiChat;
