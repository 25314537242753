import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import energyImg from "../../../media/details/anergy-stress.png";
// import { useWellnessData } from '@/context/WellnessDataContext';
// import { checkActiveFit } from '@/lib/actions/planType';
import bgImg from "../../../media/bg-meal.png";
import { useWellnessData } from '../../../context/WellnessDataContext';

const EnergyStress = () => {
    //const router = useRouter();

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const allowRoute = await checkActiveFit();
        //         if (!allowRoute) {
        //             router.push("/en/dashboard");
        //         }
        //     } catch (error) {
        //         // Handle error
        //     }
        // };

        // fetchData();
    }, []);

    const { wellnessData, setWellnessData } = useWellnessData();

    const handleEnergyLevelChange = (e) => {
        setWellnessData({
            ...wellnessData,
            energy_level: e.target.value,
        });
    };

    return (
        <div className="2xl:mt-10 max-w-xl mx-auto p-8 bg-white rounded-3xl shadow-xl text-center" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="mb-8">
                <div className="flex justify-center items-center space-x-4">
                    <img src={energyImg} alt="Logo" className="h-12" />
                    <h1 className="text-2xl font-semibold">Energy & Stress</h1>
                </div>
            </div>
            <div className="space-y-6">
                <div className="text-left">
                    <label className="block mb-2 text-sm font-medium">Energy Level</label>
                    <select
                        // value={wellnessData.energy_level}
                        onChange={handleEnergyLevelChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="7000kjs/24 hours">7000kjs/24 hours</option>
                        <option value="5000kjs/24 hours">5000kjs/24 hours</option>
                        <option value="8000kjs/24 hours">8000kjs/24 hours</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="text-left">
                    <label className="block mb-2 text-sm font-medium">Stress Level</label>
                    <select className="w-full p-2 border border-gray-300 rounded-md">
                        <option value="04">04</option>
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-8">
                <Link to="/dashboard/chronic-conditions">
                    <button className="px-4 py-2 bg-gray-200 border border-primary rounded-md">Back</button>
                </Link>
                <Link to="/dashboard/hereditary-issues">
                    <button className="px-4 py-2 bg-primary text-white rounded-md">Next</button>
                </Link>
            </div>
        </div>
    );
};

export default EnergyStress;
