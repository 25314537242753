import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define default values for lifestyle data
const defaultLifestyleData = {
    water_intake: '3.8L/day',
    activity_level: 'Level 2',
    activity_count: '5 mile/day',
    rest_days: 'Saturday',
    equipments: 'Personal Gym Kit',
    physical_condition: 'Fit',
    athletic_level: 'Fit',
    work_classification: 'Self Employed',
    diet_history: 'History1',
    gym_experience: 'Beginner',
    plan_preference: 'Beginner',
    fun_activities: 'Football',
    shopping_day: 'Saturday',
};


// Create the context with a default value
const LifestyleDataContext = createContext(undefined);

// Define the provider component
export const LifestyleDataProvider = ({ children }) => {
    const [lifestyleData, setLifestyleData] = useState(defaultLifestyleData);

    return (
        <LifestyleDataContext.Provider value={{ lifestyleData, setLifestyleData }}>
            {children}
        </LifestyleDataContext.Provider>
    );
};

// Custom hook to use the context
export const useLifestyleData = () => {
    const context = useContext(LifestyleDataContext);
    if (context === undefined) {
        throw new Error('useLifestyleData must be used within a LifestyleDataProvider');
    }
    return context;
};