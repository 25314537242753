import { Link, useNavigate } from "react-router-dom";
import background from "../../media/cta-bg.png"
import { getUser } from "../../lib/actions/profile";

function CTA() {
    const navigate = useNavigate();
    const checkRedirectLink = async () => {
        const user = await getUser();
        if (user) {
            navigate("/dashboard");
        } else {
            navigate("/sign-in");
        }
    }
    return (
        <section className="relative w-full h-[30rem] text-white overflow-hidden">
            {/* Background Image */}
            <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={background}
                alt="Background"
                style={{ zIndex: -1 }} // Ensure background is behind other elements
            />

            {/* Overlay */}
            <div className="absolute top-0 left-0 w-full h-full bg-[#0F172A] opacity-70 backdrop-blur-sm z-20" />

            {/* Content */}
            <div className="relative z-30 flex flex-col items-center justify-center h-full max-w-5xl mx-auto text-center">
                <div className="flex flex-col items-center justify-start gap-4 max-w-2xl">
                    <h2 className="text-white text-5xl font-semibold leading-[3.75rem]">
                        Ready to get started?
                    </h2>
                    <p className="text-gray-200 text-lg font-normal leading-10 mb-4">
                        With lots of unique blocks, you can easily build a page without
                        coding. Build your next landing page.
                    </p>
                    {/* <Link to="/dashboard"> */}
                    <button
                        onClick={() => checkRedirectLink()}
                        className="min-w-[15rem] h-[3.4375rem] px-5 py-4 rounded-full bg-[#ED5700] shadow-lg text-white text-lg font-semibold cursor-pointer relative z-30"
                    >
                        Get Started
                    </button>
                    {/* </Link> */}
                </div>
            </div>
        </section>
    );
}

export default CTA;
