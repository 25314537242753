
import { useState } from "react"
import { forgotPassword, forgotPasswordForReset, resendOtp } from "../lib/actions/auth"
import Base from "./Base"

import { FieldValues, useForm } from "react-hook-form"
import Loader from "./Loader"
//import { forgotPassword } from "@/lib/actions/auth"




function ForgotPassword({
    setClose,
    showClose,
    setVerificationModal,
    setEmail
}) {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = async (data) => {

        try {
            const email = data.email
            setIsLoading(true)
            //const response = await resendOtp({ email })
            const response = await forgotPasswordForReset({ email })
            setIsLoading(false);

            if (response) {
                // Assuming response indicates success
                if (setEmail) {
                    setEmail(data.email)
                }
                setVerificationModal(true)
                setClose(true)
            } else {
                console.error("Forgot password failed")
            }
        } catch (error) {
            console.error("Error during forgot password:", error)
        }
    }

    return (
        <Base setClose={setClose} showClose={showClose} >
            <div className="flex flex-col w-[37.25rem] p-12 bg-white rounded-3xl shadow-lg gap-12">
                <div className="text-center">
                    <h2 className="text-3xl font-medium text-gray-900 mb-3.5 tracking-wider">Reset Password</h2>
                    <p className="text-base font-normal text-gray-500 tracking-tight">Recover your account password</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-12">
                    <div className="w-full flex flex-col gap-2.5">
                        <label className="text-gray-900 font-medium text-sm leading-5" htmlFor="email">
                            Email
                        </label>
                        <input
                            className="w-full h-13 p-3.5 rounded-2xl border border-gray-200 text-gray-900 placeholder-gray-400 text-base font-medium leading-6"
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                    message: "Please provide a valid email"
                                }
                            })}
                        />
                        {errors.email && (
                            <div className="text-red-600 text-sm">
                                {errors.email.message?.toString() || "Email is required"}
                            </div>
                        )}
                    </div>
                    <button className="w-full flex items-center justify-center rounded-2xl border border-[#ed5700] py-4 text-base font-medium text-white bg-[#ed5700] cursor-pointer">
                        Next
                    </button>
                </form>
            </div>
            {isLoading && <Loader />}
        </Base>
    )
}

export default ForgotPassword
