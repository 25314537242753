import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bgImg from "../../../media/bg-meal.png";
import { useUserData } from "../../../context/UserDataContext"; // Import context

const DietSelector = () => {
    const [selected, setSelected] = useState(0);
    const { userData, setUserData } = useUserData(); // Access userData and setUserData from context

    const choices = [
        {
            name: "vegetarian",
            label: "Vegetarian",
            description: "Excludes meat, fish, and poultry; focuses on plant-based foods and may include dairy and eggs."
        },
        {
            name: "ovo_vegetarian",
            label: "Ovo-Vegetarian",
            description: "Follows a vegetarian diet but includes eggs, excluding meat, fish, and dairy."
        },
        {
            name: "vegan",
            label: "Vegan",
            description: "Excludes all animal products, including meat, dairy, eggs, and honey; focuses entirely on plant-based foods."
        },
        {
            name: "full_non_vegetarian",
            label: "Full Non-Vegetarian",
            description: "Includes a variety of animal products, such as meat, fish, poultry, dairy, and eggs, in the diet regularly."
        }
    ];

    // Update the userData context whenever the selected diet changes
    useEffect(() => {
        setUserData({
            ...userData,
            diet_type: choices[selected].name, // Update allergies field to diet field
        });
    }, [selected]); // Runs whenever the selected diet changes

    return (
        <div
            className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="p-8 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                <h1 className="text-xl font-bold">Choose a Diet</h1>
                {choices.map((choice, index) => (
                    <div
                        key={index}
                        onClick={() => setSelected(index)}
                        className={`p-4 rounded-md cursor-pointer ${selected === index ? "bg-orange-100 border border-orange-500" : "bg-gray-100"
                            }`}
                    >
                        <h2 className="font-semibold text-primary">{choice.label}</h2>
                        <p>{choice.description}</p>
                    </div>
                ))}
                <div className="flex justify-between mt-4">
                    <Link to="/age" className="px-4 py-2 bg-gray-300 rounded-md">
                        Back
                    </Link>
                    <Link to="/fitness" className="px-4 py-2 bg-orange-500 text-white rounded-md">
                        Next
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default DietSelector;
