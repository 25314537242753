import { Link } from "react-router-dom";
import logo from "../../media/logo.png";

function HomeHeader() {
    return (
        <header className="relative w-full py-5 md:py-4 sm:py-2">
            <div className="flex justify-between items-center h-full max-w-screen-4xl px-4">
                <div className="flex justify-start">
                    <img
                        className="w-auto h-10 md:h-8 sm:h-6 object-contain"
                        src={logo}
                        alt="Logo"
                    />
                </div>
                <div className="flex flex-row">
                    <Link
                        to="/sign-up"
                        className="inline-block w-24 h-10 mr-2 md:w-auto md:px-5 sm:w-auto sm:px-4 px-6 py-2 rounded-full border border-gray-800 text-gray-800 text-center font-bold text-base md:text-sm sm:text-xs transition-colors duration-300 hover:bg-gray-200"
                    >
                        Sign Up
                    </Link>
                    <Link
                        to="/sign-in"
                        className="inline-block w-24 h-10 md:w-auto md:px-5 sm:w-auto sm:px-4 px-6 py-2 rounded-full border border-gray-800 text-gray-800 text-center font-bold text-base md:text-sm sm:text-xs transition-colors duration-300 hover:bg-gray-200"
                    >
                        Sign In
                    </Link>
                </div>
            </div>
        </header>
    );
}

export default HomeHeader;
