import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bgImg from "../../../media/bg-meal.png";
import WeightSlider from "./WeightSlider";
import { useUserData } from "../../../context/UserDataContext"; // Import context
import { createProfile } from "../../../lib/actions/profile";
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/Loader";
const WeightComp = () => {
    const navigate = useNavigate();
    const [weight, setWeight] = useState(65);
    const [weight_uom, setWeight_uom] = useState("kg");
    const { userData, setUserData } = useUserData(); // Access userData and setUserData from context
    const [loading, setLoading] = useState(false);
    // Update the userData context whenever the weight changes
    useEffect(() => {
        setUserData({
            ...userData,
            weight_uom: weight_uom,
            weight: weight, // Set weight in userData
        });
    }, [weight]); // Runs whenever the weight changes

    // const saveOnBoardingData = async () => {
    //     console.log("userData", userData);

    //     setLoading(true);
    //     const response = await createProfile(userData);
    //     setLoading(false);
    //     console.log("profile created", response);

    //     if (response) {
    //         navigate("/dashboard");
    //     }
    // }
    return (
        <div
            className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="p-8 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                <h1 className="text-xl font-bold">What is your weight?</h1>
                <p>We will make sure you get better personalized results.</p>
                <WeightSlider weight={weight} setWeight={setWeight} setWeight_uom={setWeight_uom} />
                {loading && <Loader />}
                <div className="flex justify-between mt-4">
                    <Link to="/goal">
                        <button className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400">
                            Back
                        </button>
                    </Link>
                    <Link to="/target">
                        <button onClick={(e) => {
                            // saveOnBoardingData()
                        }
                        } className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600">
                            Next
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default WeightComp;
