import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import prefImg from "../../../media/details/preference-br.png";
// import Link from 'next/link';
// import { usePreferences } from '@/context/PreferenceDataContext';
// import { checkElitFit } from '@/lib/actions/planType';
// import { useRouter } from 'next/navigation';
import bgImg from "../../../media/bg-meal.png";
import { usePreferences } from '../../../context/PreferenceDataContext';

const Preference = () => {
    // const router = useRouter();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const allowRoute = await checkElitFit();
    //             if (!allowRoute) {
    //                 router.push("/en/dashboard");
    //             }
    //         } catch (error) {
    //             console.error('Error checking elit fit:', error);
    //         }
    //     };

    //     fetchData();
    // }, [router]);

    const { preferences, setPreferences } = usePreferences();

    const handleInputChange = (e, field) => {
        const value = field === 'target_weight' ? parseInt(e.target.value) : e.target.value;
        setPreferences(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    return (
        <div className="2xl:mt-5 max-w-xl container mx-auto p-4" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className='flex justify-between'>
                <div className="flex items-center mb-6">
                    <img src={prefImg} alt="Logo" />
                    <h1 className="text-2xl font-bold">Preference and Blood Report</h1>
                </div>
                <div className=" text-gray-500 mr-6">1 of 3</div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Target Weight</label>
                    <select
                        //value={preferences.target_weight}
                        onChange={(e) => handleInputChange(e, 'target_weight')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Target Weight</option>
                        <option value="75">75 kg</option>
                        <option value="60">60 kg</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Fruit Preference</label>
                    <select
                        //  value={preferences.fruit_preference}
                        onChange={(e) => handleInputChange(e, 'fruit_preference')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Fruit Preference</option>
                        <option value="Orange">Orange</option>
                        <option value="Lemon">Lemon</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Favorite Food Frequency</label>
                    <select
                        //   value={preferences.favorite_food_frequency}
                        onChange={(e) => handleInputChange(e, 'favorite_food_frequency')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Frequency</option>
                        <option value="2 times/day">2 times/day</option>
                        <option value="3 times/day">3 times/day</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Food Preference</label>
                    <select
                        //    value={preferences.favorite_food_reason}
                        onChange={(e) => handleInputChange(e, 'favorite_food_reason')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Food Preference</option>
                        <option value="Chicken Chowmein">Chicken Chowmein</option>
                        <option value="Veg Chowmein">Veg Chowmein</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Food Weakness</label>
                    <select
                        //    value={preferences.food_weakness}
                        onChange={(e) => handleInputChange(e, 'food_weakness')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Food Weakness</option>
                        <option value="Yoghurt">Yoghurt</option>
                        <option value="Milk">Milk</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Food Weakness Reason</label>
                    <select
                        //   value={preferences.food_weakness_reason}
                        onChange={(e) => handleInputChange(e, 'food_weakness_reason')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Weakness Reason</option>
                        <option value="Love It">Love It</option>
                        <option value="Enjoy It">Enjoy It</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-6">
                <Link to="/dashboard/lifestyle3">
                    <button className="px-4 py-2 bg-gray-200 border border-primary text-gray-800 rounded-md hover:bg-gray-300">
                        Back
                    </button>
                </Link>
                <Link to="/dashboard/preference2">
                    <button className="px-4 py-2 bg-primary text-white rounded-md ">
                        Next
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Preference;
