// import React from 'react'

// function AdminDashboard() {
//     return (
//         <div>AdminDashboard</div>
//     )
// }

// export default AdminDashboard

import { useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addDays } from "date-fns"
import calendar from "../../media/calendar.png";
import user from "../../media/cercle-utilisateurs-defini 1.png";
import food from "../../media/nourriture-saine 1.png";
import countries from "../../media/des-pays 1.png";
import cancel from "../../media/signe-interdit-rouge-aucune-icone-avertissement-symbole-arret-danger-securite-illustration-3d 1.png";
import revenue from "../../media/card_total_revenue.png";



const AdminDashboard = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(addDays(new Date(), 6))

    const handleWeekChange = (date) => {
        setStartDate(date)
        setEndDate(addDays(date, 6))
    }

    return (
        <div className="p-5 bg-gray-100">
            <h1 className="text-2xl mb-5">Admin Dashboard</h1>
            <div className="mb-5 flex justify-start">
                <div className="flex items-center bg-white p-3 rounded-lg shadow-md w-full">
                    <img
                        src={calendar}
                        alt="Calendar icon"
                        className="w-5 h-5 mt-4 mr-2"
                    />
                    <div className="flex flex-col flex-grow">
                        <span>Choose a period</span>
                        <div className="flex items-center mt-1 flex-wrap">
                            <DatePicker
                                selected={startDate}
                                onChange={handleWeekChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd MMM yyyy"
                                className="mx-2 h-10 px-2 border border-gray-300 rounded-md text-sm"
                            />
                            <span> - </span>
                            <DatePicker
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd MMM yyyy"
                                className="mx-2 h-10 px-2 border border-gray-300 rounded-md text-sm"
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <div className="flex items-center bg-white p-5 rounded-lg shadow-md mb-4">
                    <img
                        src={user}
                        alt="Users icon"
                        className="w-12 h-12 mr-3"
                    />
                    <div>
                        <h2 className="text-3xl">150</h2>
                        <p className="text-gray-600">Users</p>
                    </div>
                </div>
                <div className="flex items-center bg-white p-5 rounded-lg shadow-md  mb-4">
                    <img
                        src={food}
                        alt="Meal Plans icon"
                        className="w-12 h-12 mr-3"
                    />
                    <div>
                        <h2 className="text-3xl">52</h2>
                        <p className="text-gray-600">Meal Plans Generated</p>
                    </div>
                </div>
                <div className="flex items-center bg-white p-5 rounded-lg shadow-md  mb-4">
                    <img
                        src={countries}
                        alt="Countries icon"
                        className="w-12 h-12 mr-3"
                    />
                    <div>
                        <h2 className="text-3xl">23</h2>
                        <p className="text-gray-600">Members countries</p>
                    </div>
                </div>
                <div className="flex items-center bg-white p-5 rounded-lg shadow-md mb-4">
                    <img
                        src={cancel}
                        alt="Cancel icon"
                        className="w-12 h-12 mr-3"
                    />
                    <div>
                        <h2 className="text-3xl">03</h2>
                        <p className="text-gray-600">Subs canceled</p>
                    </div>
                </div>
            </div>
            <div className="bg-white p-5 rounded-lg shadow-md">
                <h2 className="text-lg mb-5">Total Revenue</h2>
                <img
                    src={revenue}
                    alt="Chart"
                    className="w-full"
                />
            </div>
        </div>
    )
}

export default AdminDashboard
