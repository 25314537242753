import img3 from "../../media/egg-with-meat.png";
import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { getMealGen } from "../../lib/actions/meal";
import Loader from "../../common/Loader";
import DietModal from "../../common/DietModal";
import img1 from "../../media/meal-1.png"
import img2 from "../../media/meal-2.png"
import ShoppingList from "../../common/ShoppingList";
import { getProfileInCookies } from "../../lib/actions/profile";
import { capitalizeFirstLetter, getCurrentDay } from "../../lib/utils";
import { getNutrition } from "../../lib/actions/nutrition";
import { BackToTop } from "../../common/BackToTop";

const DietPlan = () => {
    //console.log("getProfileInCookies:::", getProfileInCookies);
    const profileInCookies = getProfileInCookies();
    console.log("profileInCookies:::", profileInCookies);

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", options);

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // const getNextWeekdays = () => {
    // const today = new Date();
    const currentDayIndex = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    const result = [];

    const yesterdayName = daysOfWeek[currentDayIndex === 0 ? 6 : currentDayIndex - 1];
    const todayName = `Today, ${daysOfWeek[currentDayIndex]} ${formattedDate}`;
    const todayNameAcc = daysOfWeek[currentDayIndex];
    const tomorrowName = daysOfWeek[currentDayIndex === 6 ? 0 : currentDayIndex + 1];

    for (let i = 2; i <= 5; i++) {
        const nextDayIndex = (currentDayIndex + i) % 7;
        result.push(daysOfWeek[nextDayIndex]);
    }

    //return ["Yesterday", todayName, "Tomorrow", ...result];
    const nextWeekdays = ["Yesterday", todayName, "Tomorrow", ...result];
    // }
    // const nextWeekdays = getNextWeekdays();
    const [showBreakfast, setShowBreakfast] = useState(true);
    const [showLunch, setShowLunch] = useState(false);
    const [showSnaks1, setShowSnaks1] = useState(false);
    const [showSnaks2, setShowSnaks2] = useState(false);
    const [showDinner, setShowDinner] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [apiResponse, setApiResponse] = useState();
    const [isOpen, setIsOpen] = useState(false); // State to toggle menu visibility
    const [loading, setLoading] = useState(false);
    const [selectedDay, setSelectedDay] = useState(todayNameAcc);
    const [dailyFoodInfoData, setDailyFoodInfoData] = useState();
    const [selectIndex, setSelectedIndex] = useState(1);
    const [shoppingListItems, setShoppingListItems] = useState();
    const [showShoppingList, setShowShoppingList] = useState(false);
    const [calories, setCalories] = useState(856);
    const [carbs, setCarbs] = useState("13g");
    const [protein, setProtein] = useState("23g");
    const [fatIntake, setFatIntake] = useState("50g");
    const [showModal, setShowModal] = useState(false);
    const [shoppingListData, setShoppingListData] = useState();
    const handleClick = () => {
        setIsChecked(!isChecked);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const getMeals = async () => {
        //const getLocalMealPlan = localStorage.getItem("meal_plan");
        // if (getLocalMealPlan) {
        //     console.log("running local");
        //     setApiResponse(JSON.parse(getLocalMealPlan));
        // } else {
        setLoading(true);
        const postMeal = await getMealGen()
        // const postMeal = await postMealGen({
        //     estimated_date: "30 days",
        //     fitness_goal: "Gain Weight",
        //     weekly_average_calories: 2500,
        //     cuisine: "Bangalore Cuisine",
        //     food_preference: "Non-Vegetarian",
        // });
        setApiResponse(postMeal);
        localStorage.setItem("meal_plan", JSON.stringify(postMeal));

        setLoading(false);
        // }
    };

    useEffect(() => {
        getMeals();
    }, []);

    useEffect(() => {
        let dailyfoodInfo = {
            "Total calorie": 1691,
            breakfast: {
                "Mint Chutney (2 tbsp)": 50,
                "Pongal (1 cup)": 350
            },
            dinner: {
                "Chicken Tikka (100g)": 240,
                "Roti (2 pieces)": 200
            },
            lunch: {
                "Lamb Kebab (100g)": 230,
                "Naan Bread (2)": 264
            },
            snacks: {
                "Berries Smoothie (1 cup)": 150,
                "Peanuts (1/4 cup)": 207
            }
        };
        //console.log("selectedDay:::", selectedDay);

        // console.log("apiResponse?.user_meal::", apiResponse?.user_meal);
        // console.log("apiResponse.length:::", apiResponse?.length);

        if (apiResponse?.length > 0) {
            dailyfoodInfo = apiResponse[0]?.meals?.Meal_plan?.[selectedDay];
            setShoppingListItems(apiResponse[0]?.meals?.Meal_plan?.shopping_list || apiResponse[0]?.meals?.shopping_list);
            setCalories(dailyfoodInfo['Total calorie'] || 'N/A');
            setCarbs(dailyfoodInfo['total_carbs'] || 'N/A');
            setProtein(dailyfoodInfo['total_proteins'] || 'N/A');
            setFatIntake(dailyfoodInfo['total_fats'] || 'N/A');
            // console.log("shoppingListItems:::::", shoppingListItems);
            setDailyFoodInfoData(dailyfoodInfo);
            setShoppingListData(apiResponse[0]?.meals?.shopping_list);

        }


    }, [selectedDay, apiResponse]);
    const setSelectedDayFunc = (day, index) => {
        setSelectedIndex(index);
        if (index === 0) {
            setSelectedDay(yesterdayName);
        } else if (index == 1) {
            setSelectedDay(todayNameAcc);
        } else if (index == 2) {
            setSelectedDay(tomorrowName);
        }
        else {
            setSelectedDay(day);
        }
    }

    const mealTypes = [
        { name: "Breakfast", key: "breakfast" },
        { name: "Snacks 1", key: "snacks1" },
        { name: "Lunch", key: "lunch" },
        { name: "Snacks 2", key: "snacks2" },
        { name: "Dinner", key: "dinner" }
    ];
    const [nutritionData, setNutritionData] = useState();
    const getOverviewData = async () => {
        const currentDay = getCurrentDay();
        const getOverviewData = await getNutrition(currentDay);
        //console.log("getOverviewData:::", getOverviewData);
        setNutritionData(getOverviewData);

    };

    useEffect(() => {
        const fetchData = async () => {
            await getOverviewData();
        };

        fetchData(); // Call the async function inside useEffect
    }, []); // Empty dependency array to run only once when component mounts

    return (
        <div className="p-4 md:p-6 bg-gray-100 min-h-screen">
            {/* Today's Nutrition Intake */}
            <div className="bg-white rounded-lg shadow p-4 mb-6">
                <h2 className="text-xl font-semibold">Today's Nutrition Intake</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                    {[
                        { label: "Calories Intake", value: `${calories} Cal` },
                        { label: "Carbs Intake", value: carbs },
                        { label: "Protein Intake", value: protein },
                        { label: "Fat Intake", value: fatIntake }
                    ].map((item, index) => (
                        <div key={index} className="text-center">
                            <div className="text-3xl font-bold text-blue-500">{item.value}</div>
                            <div className="text-gray-600">{item.label}</div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Active Plan */}
            <div className="bg-white rounded-lg shadow p-4 mb-6">
                <h2 className="text-xl font-semibold">Your Active Plan</h2>
                <p className="text-green-600 font-bold">{capitalizeFirstLetter(profileInCookies.diet_type)}</p>
                <div className="flex justify-between items-center mt-2">
                    <div className="text-blue-500 text-2xl font-bold">{nutritionData?.total_calories || 0} Cal</div>
                    <div className="text-blue-500 text-2xl font-bold">{nutritionData?.current_water_intake || 0} L</div>
                </div>
            </div>

            {/* Date Navigation */}
            <div className="bg-white rounded-lg shadow p-4 mb-6">
                <div className="flex justify-between items-center md:hidden">
                    <button className="text-orange-500 font-semibold">{nextWeekdays[1]}</button>
                    <button onClick={toggleMenu} className="text-gray-600">
                        <FiMenu className="text-2xl" />
                    </button>
                </div>

                <div className={`${isOpen ? "block" : "hidden"} mt-4 md:flex md:space-x-4`}>
                    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
                        {nextWeekdays.map((day, index) => (
                            <button
                                onClick={() => { setIsOpen(false); setSelectedDayFunc(day, index) }}
                                key={index}
                                className={`${index === selectIndex ? "text-orange-500 font-semibold" : "text-gray-600 hover:text-green-600"} px-2 py-1`}
                            >
                                {day}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Meal Sections */}
            {/* Meal Sections */}
            {!dailyFoodInfoData && <div>We are generating diet plan for you....</div>}
            {dailyFoodInfoData && <div>      {mealTypes.map((mealType, index) => {
                const showMeal = mealType.name === "Breakfast" ? showBreakfast : mealType.name === "Snacks 1" ? showSnaks1 : mealType.name === "Lunch" ? showLunch : mealType.name === "Snacks 2" ? showSnaks2 : showDinner;
                const setShowMeal = mealType.name === "Breakfast" ? setShowBreakfast : mealType.name === "Lunch" ? setShowLunch : mealType.name === "Snacks 1" ? setShowSnaks1 : mealType.name === "Snacks 2" ? setShowSnaks2 : setShowDinner;
                const mealItems = dailyFoodInfoData[mealType.key]; // Access the meal data (e.g., mealData.breakfast)

                return (
                    <div key={index} className="bg-white rounded-lg shadow p-4 mb-6">
                        <div
                            className="flex justify-between cursor-pointer"
                            onClick={() => {
                                setShowMeal(!showMeal);
                                if (mealType.name === "Breakfast") {
                                    setShowLunch(false);
                                    setShowDinner(false);
                                    setShowSnaks1(false);
                                    setShowSnaks2(false);
                                }
                                else if (mealType.name === "Snacks 1") {
                                    setShowBreakfast(false);
                                    setShowDinner(false);
                                    setShowLunch(false);
                                    setShowSnaks2(false);
                                }
                                else if (mealType.name === "Lunch") {
                                    setShowSnaks1(false);
                                    setShowSnaks2(false);
                                    setShowBreakfast(false);
                                    setShowDinner(false);
                                } else if (mealType.name === "Snacks 2") {
                                    setShowBreakfast(false);
                                    setShowLunch(false);
                                    setShowSnaks1(false);
                                    setShowDinner(false);
                                } else {
                                    setShowBreakfast(false);
                                    setShowLunch(false);
                                    setShowSnaks1(false);
                                    setShowSnaks2(false);
                                    setShowLunch(false);
                                }
                            }}
                        >
                            <h2 className="text-xl font-semibold mb-4">{mealType.name}</h2>
                            <span className="text-primary">{showMeal ? "-" : "+"}</span>
                        </div>

                        {showMeal && mealItems && (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                                {Object.entries(mealItems).map(([meal, calories], mealIndex) => (
                                    <div key={mealIndex} className="bg-gray-600 text-white p-4 rounded-lg shadow flex text-lg font-semibold">
                                        {/* Placeholder for meal image */}
                                        {/*  <div className="h-32 bg-gray-200 rounded-lg mb-4">
                                             <img
                                                src={getImageForIndex(mealIndex)}
                                                alt={meal}
                                                className="w-full h-full object-cover rounded-lg"
                                            /> 
                                        </div>*/}
                                        <h3 className="mr-2">{meal} - </h3>
                                        <span >{calories} Kcal</span>
                                        {/* <div className="flex justify-between mt-2">
                                            <button className="bg-orange-500 text-white px-2 py-1 rounded" onClick={() => setShowModal(!showModal)}>View Details</button>
                                            <div onClick={handleClick} className="m-2 cursor-pointer">
                                                <CiSquareCheck className={`h-8 w-8 ${isChecked ? "text-primary" : "text-gray-500"}`} />
                                            </div>
                                        </div> */}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}</div>}

            {/* Shopping List */}
            <div className="bg-white rounded-lg shadow p-4">
                <div className="flex justify-between cursor-pointer" onClick={() => setShowShoppingList(!showShoppingList)}>
                    <h2 className="text-xl font-semibold mb-4">List of ingredients</h2>
                    <div className="text-primary">{!showShoppingList ? '+' : '-'}</div>
                </div>

                {shoppingListData && showShoppingList && <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    <ShoppingList shoppingList={shoppingListData} />;
                </div>}
            </div>
            {loading && <Loader />}
            {showModal && <DietModal onClose={setShowModal} />}
            <BackToTop />
        </div>
    );
};

export default DietPlan;


const getImageForIndex = (index) => {
    if (index === 0) return img1;
    if (index === 2) return img2;
    return img3;
};