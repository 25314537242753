import { Link } from "react-router-dom";
import image from "../../media/egg-with-meat.png";
import phone from "../../media/icon-outline-phone.svg";
import mail from "../../media/icon-outline-mail.svg";
import locationMarker from "../../media/location-marker.svg";

function ContactInfo() {
    const contact = [
        { icon: phone, alt: "phone", info: "(414) 857 - 0107" },
        { icon: mail, alt: "mail", info: "Ruthsimplyway@gmail.com" },
        {
            icon: locationMarker,
            alt: "location marker",
            info: "837 W. Marshall Lane Marshalltown, IA 50158, Los Angeles",
        },
    ];

    return (
        <section className="relative w-full bg-gray-50 py-28">
            <div className="container flex flex-col lg:flex-row lg:justify-between lg:gap-24 gap-10 px-4 md:px-0 mx-auto pb-10">
                {/* First Column */}
                <div className="flex-1 relative">
                    <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{
                            backgroundImage: `url(${image})`,
                            height: '100%',
                        }}
                    />
                    <div className="absolute bottom-10 lg:bottom-[-1%] lg:right-[-22%] transform translate-y-1/2">
                        <div className="flex flex-col items-start w-full max-w-[411px] gap-10 p-8 bg-lime-900 rounded-md lg:max-w-[80%] lg:p-6">
                            <div className="text-white text-2xl font-bold leading-8 tracking-tight">
                                Contact Us
                            </div>
                            <div className="flex flex-col items-start w-full gap-6">
                                {contact.map((item, index) => (
                                    <div
                                        className="flex justify-start items-start w-full gap-5"
                                        key={index}
                                    >
                                        <img
                                            className="w-6 h-6 object-contain"
                                            src={item.icon}
                                            alt={item.alt}
                                        />
                                        <span className="text-gray-50 text-base font-normal leading-6">
                                            {item.info}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second Column */}
                <div className="flex-1 flex flex-col items-start gap-10">
                    <h2 className="text-gray-800 text-[55px] font-semibold leading-[60.5px] lg:text-[36px] lg:leading-[40px]">
                        We provide healthy food for your family.
                    </h2>
                    <p className="text-gray-800 text-base font-medium leading-7 lg:text-sm lg:leading-6">
                        Welcome to, your personalized solution for achieving your health and
                        fitness goals. Whether you&apos;re looking to lose weight, gain
                        muscle, or simply improve your overall well-being, our comprehensive
                        diet planner is here to guide you every step of the way.
                    </p>
                    <p className="text-gray-800 text-base font-medium leading-7 lg:text-sm lg:leading-6">
                        At place, we believe that dining is not just about food, but also
                        about the overall experience. Our staff, renowned for their warmth
                        and dedication, strives to make every visit an unforgettable event.
                    </p>
                    <Link to="/en">
                        <span className="inline-block mt-4 min-w-[115px] h-16 rounded-full border-[1.5px] border-gray-800 text-gray-800 text-base font-bold leading-6 py-5 px-8 lg:h-12 lg:py-3.5 lg:px-6">
                            More About Us
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default ContactInfo;