import { Link } from 'react-router-dom'; // Import Link from React Router
import twitter from "../../media/twitter.svg";
import facebook from "../../media/facebook.svg";
import instagram from "../../media/instagram.svg";
import github from "../../media/github.svg";

const socials = [
    { icon: twitter, alt: "Twitter", link: "/" },
    { icon: facebook, alt: "Facebook", link: "/" },
    { icon: instagram, alt: "Instagram", link: "/" },
    { icon: github, alt: "Github", link: "/" }
];

function Footer() {
    return (
        <footer className="relative w-full bg-black text-white py-8">
            <div className="container mx-auto flex flex-col justify-between h-full  px-2">
                <div className="flex flex-col lg:flex-row lg:justify-between gap-12">
                    <div className="flex flex-col max-w-lg gap-4">
                        <div className="text-2xl font-light tracking-wide">Logo</div>
                        <p className="text-gray-400 text-base">
                            Get fit, stay healthy, and live life on your terms without
                            fitness being an obstacle.
                        </p>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="text-lg font-medium text-gray-400">Newsletter</div>
                        <div className="flex flex-col lg:flex-row gap-4">
                            <input
                                className="w-full lg:w-3/4 py-3 px-6 rounded-full bg-white bg-opacity-10 text-white placeholder-gray-400"
                                placeholder="Your email here"
                            />
                            <button
                                className="w-full lg:w-1/4 py-3 px-6 rounded-full border border-gray-700 bg-orange-600 text-white font-semibold hover:bg-orange-700"
                                type="submit"
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-between items-center border-t border-gray-700 pt-4 mt-4">
                    <span className="text-gray-400 text-base">© 2024 Global Inc.</span>
                    <div className="flex gap-4 mt-2 lg:mt-0">
                        {socials.map((social, index) => (
                            <Link to={social.link} key={index}>
                                <img
                                    className="w-6 h-6"
                                    src={social.icon}
                                    alt={social.alt}
                                />
                            </Link>
                        ))}
                    </div>
                    <Link to="/terms-of-service" className="text-gray-400 text-base mt-2 lg:mt-0">
                        Terms of Service
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
