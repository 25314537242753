import { useContext } from 'react';
import { Link } from 'react-router-dom';
import prefImg from "../../../media/details/preference-br.png";
import bgImg from "../../../media/bg-meal.png";
import { usePreferences } from '../../../context/PreferenceDataContext'; // Adjust import as needed

const Preference2 = () => {
    const { preferences, setPreferences } = usePreferences();

    const handleSelectChange = (e, field) => {
        const value = e.target.value;
        setPreferences(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    return (
        <div className="2xl:mt-5 max-w-xl container mx-auto p-4" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="flex justify-between mb-6">
                <div className="flex items-center">
                    <img src={prefImg} alt="Logo" className="mr-4" />
                    <h1 className="text-2xl font-bold">Preference and Blood Report</h1>
                </div>
                <div className="text-sm text-gray-600 mt-2 mr-5">2 of 3</div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Weekly reminder</label>
                    <select
                        value={preferences.weekly_reminder || ""}
                        onChange={(e) => handleSelectChange(e, 'weekly_reminder')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Weekly Reminder</option>
                        <option value="7 AM">7 AM</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Fridge meal cooking</label>
                    <select
                        value={preferences.fridge_meal_cooking || ""}
                        onChange={(e) => handleSelectChange(e, 'fridge_meal_cooking')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Frequency</option>
                        <option value="2 times / week">2 times / week</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Vitamin D intake</label>
                    <select
                        value={preferences.vitamin_d_intake || ""}
                        onChange={(e) => handleSelectChange(e, 'vitamin_d_intake')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Frequency</option>
                        <option value="2 times / day">2 times / day</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Cholesterol Level</label>
                    <select
                        value={preferences.cholesterol_level || ""}
                        onChange={(e) => handleSelectChange(e, 'cholesterol_level')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Level</option>
                        <option value="30 mg/dl">30 mg/dl</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Blood pressure systolic</label>
                    <select
                        value={preferences.blood_pressure_systolic || ""}
                        onChange={(e) => handleSelectChange(e, 'blood_pressure_systolic')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Pressure</option>
                        <option value="12/8">12/8</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Blood pressure diastolic</label>
                    <select
                        value={preferences.blood_pressure_diastolic || ""}
                        onChange={(e) => handleSelectChange(e, 'blood_pressure_diastolic')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Pressure</option>
                        <option value="11/8">11/8</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Fasting blood sugar</label>
                    <select
                        value={preferences.fasting_blood_sugar || ""}
                        onChange={(e) => handleSelectChange(e, 'fasting_blood_sugar')}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="" disabled>Select Sugar Level</option>
                        <option value="1g/l">1g/l</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-6">
                <Link to="/dashboard/preference">
                    <button className="px-4 py-2 border border-primary bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300">
                        Back
                    </button>
                </Link>
                <Link to="/dashboard/preference3">
                    <button className="px-4 py-2 bg-primary text-white rounded-md">
                        Next
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Preference2;
