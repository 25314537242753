import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { UserDataProvider } from './context/UserDataContext';
import { CycleDataProvider } from './context/CycleDataContext';
import { LifestyleDataProvider } from './context/LifeStyleDataContext';
import Preference from './components/dashboard/details/Preference';
import { PreferenceProvider } from './context/PreferenceDataContext';
import { WellnessProvider } from './context/WellnessDataContext';
import Profile from './components/dashboard/Profile';
import { ProfilePhotoProvider } from './context/ProfilePhotoContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserDataProvider>
        <LifestyleDataProvider>
          <PreferenceProvider >
            <WellnessProvider>
              <CycleDataProvider>
                <ProfilePhotoProvider>
                  <App />
                </ProfilePhotoProvider>
              </CycleDataProvider>
            </WellnessProvider>
          </PreferenceProvider>
        </LifestyleDataProvider>
      </UserDataProvider>
    </BrowserRouter>
  </React.StrictMode >
);

