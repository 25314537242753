import { FaCheckCircle } from 'react-icons/fa';

const ShoppingList = ({ shoppingList }) => {
    // Function to render the list items for each category
    const renderCategoryItems = (category) => {
        return Object.entries(category).map(([item, quantity], index) => (
            <li key={index} className="mb-2 flex items-center">
                <FaCheckCircle className="text-green-500 mr-2" />
                <span>{item}: <span className="font-semibold">{quantity}</span></span>
            </li>
        ));
    };

    return (
        <>
            {Object.entries(shoppingList).map(([category, items], index) => (
                <div key={index} className="mb-6">
                    <h3 className="text-xl font-semibold mb-2">{category}</h3>
                    <ul className="pl-4">{renderCategoryItems(items)}</ul>
                </div>
            ))}
        </>
    );
};

export default ShoppingList;
