import { useState } from "react";

const MemberList = () => {
    const [users] = useState([
        {
            name: "Aditya Amrit CHAUHAN",
            sex: "M",
            age: 27,
            membership: "EliteFit",
            address: "New-Delhi, India",
            memberSince: "20 Jan 2022"
        },
        {
            name: "Jean DE LA PRUYERE",
            sex: "M",
            age: 50,
            membership: "ProFit",
            address: "Paris, France",
            memberSince: "20 Jan 2022"
        },
        // Add the rest of the users here
    ]);

    return (
        <div className="p-5 mx-auto max-w-7xl">
            <h1 className="text-2xl font-bold mb-5">List of users</h1>
            <div className="mb-4">
                <select className="p-2 text-sm border border-gray-300 rounded">
                    <option value="all">All users</option>
                </select>
            </div>

            {/* Table for larger screens */}
            <table className="hidden md:table w-full border-collapse mb-5">
                <thead>
                    <tr>
                        <th className="border border-gray-300 p-2 bg-gray-100"></th>
                        <th className="border border-gray-300 p-2 bg-gray-100">Name</th>
                        <th className="border border-gray-300 p-2 bg-gray-100">Sex</th>
                        <th className="border border-gray-300 p-2 bg-gray-100">Age</th>
                        <th className="border border-gray-300 p-2 bg-gray-100">Membership</th>
                        <th className="border border-gray-300 p-2 bg-gray-100">Address</th>
                        <th className="border border-gray-300 p-2 bg-gray-100">Member since</th>
                        <th className="border border-gray-300 p-2 bg-gray-100">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={index}>
                            <td className="border border-gray-300 p-2">
                                <input type="checkbox" className="form-checkbox accent-primary" />
                            </td>
                            <td className="border border-gray-300 p-2">{user.name}</td>
                            <td className="border border-gray-300 p-2">{user.sex}</td>
                            <td className="border border-gray-300 p-2">{user.age}</td>
                            <td className="border border-gray-300 p-2">{user.membership}</td>
                            <td className="border border-gray-300 p-2">{user.address}</td>
                            <td className="border border-gray-300 p-2">{user.memberSince}</td>
                            <td className="border border-gray-300 p-2 flex space-x-2">
                                <button className="bg-blue-500 text-white py-1 px-2 text-xs rounded hover:bg-blue-600">View</button>
                                <button className="bg-yellow-500 text-white py-1 px-2 text-xs rounded hover:bg-yellow-600">Edit</button>
                                <button className="bg-red-500 text-white py-1 px-2 text-xs rounded hover:bg-red-600">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Responsive card layout for small screens */}
            <div className="space-y-4 md:hidden">
                {users.map((user, index) => (
                    <div key={index} className="border border-gray-300 rounded-lg p-4 shadow">
                        <div className="flex justify-between items-center mb-3">
                            <h2 className="text-lg font-semibold">{user.name}</h2>
                            <input type="checkbox" className="form-checkbox accent-primary" />
                        </div>
                        <p className="text-sm"><strong>Sex:</strong> {user.sex}</p>
                        <p className="text-sm"><strong>Age:</strong> {user.age}</p>
                        <p className="text-sm"><strong>Membership:</strong> {user.membership}</p>
                        <p className="text-sm"><strong>Address:</strong> {user.address}</p>
                        <p className="text-sm"><strong>Member since:</strong> {user.memberSince}</p>
                        <div className="flex space-x-2 mt-3">
                            <button className="bg-blue-500 text-white py-1 px-2 text-xs rounded hover:bg-blue-600">View</button>
                            <button className="bg-yellow-500 text-white py-1 px-2 text-xs rounded hover:bg-yellow-600">Edit</button>
                            <button className="bg-red-500 text-white py-1 px-2 text-xs rounded hover:bg-red-600">Delete</button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex justify-between items-center mt-5">
                <span className="text-sm">Showing data 1 to {users.length} of 100K entries</span>
                <div className="flex items-center space-x-1">
                    <button className="py-1 px-2 text-sm border border-gray-300 rounded hover:bg-gray-200">&lt;</button>
                    <button className="py-1 px-2 text-sm border border-gray-300 rounded hover:bg-gray-200">1</button>
                    <button className="py-1 px-2 text-sm border border-gray-300 rounded hover:bg-gray-200">2</button>
                    <button className="py-1 px-2 text-sm border border-gray-300 rounded hover:bg-gray-200">3</button>
                    <button className="py-1 px-2 text-sm border border-gray-300 rounded hover:bg-gray-200">4</button>
                    <button className="py-1 px-2 text-sm border border-gray-300 rounded hover:bg-gray-200">...</button>
                    <button className="py-1 px-2 text-sm border border-gray-300 rounded hover:bg-gray-200">20</button>
                    <button className="py-1 px-2 text-sm border border-gray-300 rounded hover:bg-gray-200">&gt;</button>
                </div>
            </div>
        </div>
    );
};

export default MemberList;
