import { useState } from "react";
import { useForm } from "react-hook-form";
import { REGEX } from "../../context/constants";
import { Link } from "react-router-dom";
import background from "../../media/pinstripe.png";
import VerificationCode from "../../common/VerificationCode";
import Failure from "../../common/Failure";
import { signUpFunc } from "../../lib/actions/auth";
import Loader from "../../common/Loader";


export default function SignUp() {
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [failureMessage, setFailureMessage] = useState("");
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [consent, setConsent] = useState(false);
    const [email, setEmail] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const toggleConsent = () => setConsent((prevState) => !prevState);

    // Handle form submission
    const onSubmit = async (data) => {
        setLoading(true)
        // Do something with the form data
        const response = await signUpFunc(data);
        // console.log("response.status::: ", response.status);
        console.log("response:::", response);
        if (!response?.jsonResponse?.isRegisterComplete) {
            setLoading(false);
            setShowVerificationModal(true);
        }
        else if (response.email) {
            sessionStorage.setItem("sign_up_id", response.id);
            setLoading(false);
            setShowVerificationModal(true);
        } else if (response.statusCode === 409) {
            setLoading(false)
            setShowFailureModal(true);
            setFailureMessage("A user with this email already exists Try logging in.");
        }
        else {
            setLoading(false)
            setShowFailureModal(true);
            setFailureMessage("Something went wrong. Please try again later.");
        }
        setEmail(data.email); // Set email to be used in the VerificationCode component
        // setShowVerificationModal(true); // Show the verification modal
    };

    return (
        <div className="min-h-screen flex flex-col md:flex-row">
            {/* First Column */}
            <div
                className="flex-1 flex items-center justify-center bg-cover bg-center p-8 text-white"
                style={{ backgroundImage: `url(${background})` }}
            >
                <div className="text-center">
                    <h1 className="text-4xl font-bold">
                        <span className="text-[#2563eb]">Welcome to Your</span> Personalized AI Diet Dashboard!
                    </h1>
                    <p className="mt-4">
                        Congratulations on taking the first step towards a healthier lifestyle! Our AI diet dashboard is here to empower you on your journey to better nutrition and well-being.
                    </p>
                </div>
            </div>

            {/* Second Column (Sign-Up Form) */}
            <div className="bg-white p-8 flex flex-col items-center justify-center">
                {loading && <Loader />}
                <div>
                    <h1 className="text-4xl font-bold text-center mb-8">
                        Welcome to our AI Meal Planner
                    </h1>
                </div>

                <form
                    className="w-full max-w-md"
                    onSubmit={handleSubmit(onSubmit)} // Handle form submission
                >
                    <div className="mb-4">
                        <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Username
                        </label>
                        <input
                            className="mt-1 block w-full border border-gray-300 focus:border-primary rounded-2xl min-h-[2.5rem] p-2 focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter your username"
                            required
                            {...register("username", {
                                required: "Username is required",
                                minLength: {
                                    value: 3,
                                    message: "Username must be 3 characters minimum",
                                },
                                validate: {
                                    noSpaces: (v) =>
                                        !/\s/.test(v) || "Username should not contain spaces", // Validation rule to disallow spaces
                                },
                            })}
                        />
                        {errors.username && (
                            <div className="text-red-500 text-sm mt-2">
                                {errors.username.message?.toString()}
                            </div>
                        )}
                    </div>
                    {/* <div className="mb-4">
                        <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Username
                        </label>
                        <input
                            className="mt-1 block w-full border border-gray-300 focus:border-primary rounded-2xl min-h-[2.5rem] p-2 focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter your username"
                            required
                            {...register("username", {
                                required: "Username is required",
                                minLength: {
                                    value: 3,
                                    message: "Username must be 3 characters minimum",
                                },
                            })}
                        />
                        {errors.username && (
                            <div className="text-red-500 text-sm mt-2">
                                {errors.username.message?.toString()}
                            </div>
                        )}
                    </div> */}

                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Email
                        </label>
                        <input
                            className="mt-1 block w-full border border-gray-300 focus:border-primary rounded-2xl min-h-[2.5rem] p-2 focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter your email"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: REGEX.email,
                                    message: "Please provide a valid email",
                                },
                            })}
                        />
                        {errors.email && (
                            <div className="text-red-500 text-sm mt-2">
                                {errors.email.message?.toString()}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Password
                        </label>
                        <input
                            className="mt-1 block w-full border border-gray-300 focus:border-primary rounded-2xl min-h-[2.5rem] p-2 focus:outline-none focus:ring-2 focus:ring-primary"
                            type="password"
                            placeholder="Enter your password"
                            {...register("password", {
                                minLength: {
                                    value: 8,
                                    message: "Password is 8 characters minimum",
                                },
                                validate: {
                                    lowercase: (v) =>
                                        Boolean(v.match(REGEX.lowercase)) ||
                                        "Password should have at least one lowercase character",

                                    uppercase: (v) =>
                                        Boolean(v.match(REGEX.uppercase)) ||
                                        "Password should have at least one uppercase character",

                                    number: (v) =>
                                        Boolean(v.match(REGEX.number)) ||
                                        "Password should have at least one numeric character",

                                    specialCharacter: (v) =>
                                        Boolean(v.match(REGEX.specialCharacter)) ||
                                        "Password should have at least one special character",
                                },
                            })}
                        />
                        {errors.password && (
                            <div className="text-red-500 text-sm mt-2">
                                {errors.password.message?.toString()}
                            </div>
                        )}
                    </div>
                    <div className="mb-4 flex items-start">
                        <input
                            type="checkbox"
                            className="h-4 w-4 accent-primary"
                            {...register("agree", {
                                required: "Please agree to our terms of use and privacy policy to continue",
                            })}
                            onChange={toggleConsent}
                        />
                        <div className="ml-2 text-sm">
                            I agree to KFit{" "}
                            <Link to="/terms" className="text-primary underline">
                                Terms of Use
                            </Link>{" "}
                            and{" "}
                            <Link to="/policy" className="text-primary underline">
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                    {errors.agree && (
                        <div className="text-red-500 text-sm mt-2">
                            {errors.agree.message?.toString()}
                        </div>
                    )}

                    <button
                        className="w-full bg-primary text-white py-2 rounded-2xl"
                        type="submit"
                    >
                        Continue
                    </button>
                </form>

                <button className="mt-4 text-primary">
                    Already have an account?{" "}
                    <Link to="/sign-in" className="my-1 underline">
                        Log In
                    </Link>
                </button>
                <div>
                    {showVerificationModal && (
                        <VerificationCode setClose={setShowVerificationModal} email={email} />
                    )}
                    {showFailureModal && (
                        <Failure message={failureMessage} setClose={setShowFailureModal} />
                    )}
                </div>
            </div>

        </div>
    );
}
