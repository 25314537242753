import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bgImg from "../../../media/bg-meal.png";
import WeightSlider from "./WeightSlider";
import { useUserData } from "../../../context/UserDataContext"; // Import context
import { createProfile } from "../../../lib/actions/profile";
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/Loader";
import Cookies from "js-cookie";

const TargetWeightComp = () => {
    const navigate = useNavigate();
    const [weight, setWeight] = useState(60);
    const [weight_uom, setWeight_uom] = useState("kg");
    const { userData, setUserData } = useUserData(); // Access userData and setUserData from context
    const [loading, setLoading] = useState(false);
    // Update the userData context whenever the weight changes
    useEffect(() => {
        setUserData({
            ...userData,
            weight_uom: weight_uom,
            target_weight: weight, // Set weight in userData
        });
    }, [weight]); // Runs whenever the weight changes

    const saveOnBoardingData = async () => {
        setLoading(true);
        const response = await createProfile(userData);
        setLoading(false);

        if (response) {
            const expiryDate = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour
            Cookies.set("profile", JSON.stringify(response), { expires: expiryDate });
            navigate("/dashboard");
        }
    }
    return (
        <div
            className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="p-8 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                <h1 className="text-xl font-bold">What is your Target weight?</h1>
                {/* <p>We will make sure you get better personalized results.</p> */}
                <WeightSlider weight={weight} setWeight={setWeight} setWeight_uom={setWeight_uom} />
                {loading && <Loader />}
                <div className="flex justify-between mt-4">
                    <Link to="/goal">
                        <button className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400">
                            Back
                        </button>
                    </Link>
                    {/* <Link to="/dashboard"> */}
                    <button onClick={(e) => saveOnBoardingData()} className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600">
                        Save
                    </button>
                    {/* </Link> */}
                </div>
            </div>
        </div>
    );
};

export default TargetWeightComp;
