import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bgImg from "../../../media/bg-meal.png";
import { useUserData } from "../../../context/UserDataContext"; // Import context

const inputValues = [
    "Weight Loss",
    "Muscle Gain",
    "Maintain Weight",
    "Weight Gain"
];

const GoalComp = () => {
    const [selected, setSelected] = useState(0);
    const { userData, setUserData } = useUserData(); // Access userData and setUserData from context

    // Update the userData context whenever the selected goal changes
    useEffect(() => {
        setUserData({
            ...userData,
            fitness_goal: inputValues[selected], // Set selected goal in userData
        });
    }, [selected]); // Runs whenever the selected goal changes

    const handleClick = (index) => {
        setSelected(index);
    };

    return (
        <div
            className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="p-8 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                <h1 className="text-xl font-bold">What's your goal?</h1>
                <p>We will make sure you get better personalized results.</p>
                <div className="space-y-2">
                    {inputValues.map((value, index) => (
                        <div
                            key={index}
                            onClick={() => handleClick(index)}
                            role="button"
                            aria-pressed={selected === index}
                            className={`p-4 rounded-md cursor-pointer ${selected === index
                                ? "bg-orange-100 border border-orange-500"
                                : "bg-gray-100"
                                }`}
                        >
                            {value}
                        </div>
                    ))}
                </div>
                <div className="flex justify-between mt-4">
                    <Link to="/gender">
                        <button className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400">
                            Back
                        </button>
                    </Link>
                    <Link to="/height">
                        <button className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600">
                            Next
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default GoalComp;
