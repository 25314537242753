
function Testimonial({
    title,
    description,
    author,
    ...props
}) {
    return (
        <div className="w-[25rem] min-h-[25.5rem] p-8 pb-8 rounded-md bg-[#F9F9F7]" {...props}>
            <div className="flex flex-col items-start gap-6">
                <div className="flex flex-col items-start gap-6">
                    <h3 className="text-[#ED5700] text-xl font-bold leading-8 tracking-tight">
                        {title}
                    </h3>
                    <p className="text-[#414536] text-base font-normal leading-7">
                        {description}
                    </p>
                </div>

                <div className="flex items-start w-full gap-5 pt-6 border-t border-[#DBDFD0B3]">
                    <img
                        className="w-[4.375rem] h-[5rem] object-contain"
                        src={author.profilePhoto.src}
                        alt={author.profilePhoto.alt}
                        width={author.profilePhoto.width}
                        height={author.profilePhoto.height}
                    />
                    <div className="flex flex-col gap-1">
                        <div className="text-[#2C2F24] text-base font-bold leading-6">
                            {author.name}
                        </div>
                        <div className="text-[#414536] text-base font-normal leading-6">
                            {author.address}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;
