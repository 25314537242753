import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import maleIcon from "../../../media/male-icon.svg";
import femaleIcon from "../../../media/female-icon.svg";
import checkIcon from "../../../media/tick.svg";
import bgImg from "../../../media/bg-meal.png";
import { useUserData } from "../../../context/UserDataContext";

const GenderComp = () => {
    const [selectedGender, setSelectedGender] = useState("Male");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const { userData, setUserData } = useUserData();

    // Concatenate country and city to form the location
    const location = `${city}, ${country}`;

    useEffect(() => {
        setUserData({
            ...userData,
            gender: selectedGender,
            location,  // Add location to userData
        });
    }, [selectedGender, location]);

    const handleSelection = (gender) => {
        setSelectedGender(gender);
    };

    return (
        <div
            className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="p-8 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                <h1 className="text-xl font-bold">What's your gender?</h1>
                <p>To give you a better experience we need to know your gender.</p>

                {/* Gender selection */}
                <div className="flex flex-col space-y-4">
                    <div
                        onClick={() => handleSelection("Male")}
                        className={`flex items-center p-4 rounded-md cursor-pointer ${selectedGender === "Male"
                            ? "bg-orange-100 border border-orange-500"
                            : "bg-gray-100"
                            }`}
                    >
                        {selectedGender === "Male" && (
                            <img src={checkIcon} alt="check icon" className="mr-2" />
                        )}
                        <img src={maleIcon} alt="male icon" className="w-8 h-8 mr-2" />
                        <span>Male</span>
                    </div>
                    <div
                        onClick={() => handleSelection("Female")}
                        className={`flex items-center p-4 rounded-md cursor-pointer ${selectedGender === "Female"
                            ? "bg-orange-100 border border-orange-500"
                            : "bg-gray-100"
                            }`}
                    >
                        {selectedGender === "Female" && (
                            <img src={checkIcon} alt="check icon" className="mr-2" />
                        )}
                        <img src={femaleIcon} alt="female icon" className="w-8 h-8 mr-2" />
                        <span>Female</span>
                    </div>
                </div>
                <h1 className="text-xl font-bold">What's your location?</h1>
                {/* Country and City inputs */}
                <div className="space-y-4">
                    <div>
                        <label htmlFor="country" className="block font-semibold">Country</label>
                        <input
                            id="country"
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder="Enter your country"
                            className="w-full px-4 py-2 border rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="city" className="block font-semibold">City</label>
                        <input
                            id="city"
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="Enter your city"
                            className="w-full px-4 py-2 border rounded-md"
                        />
                    </div>
                </div>

                {/* Navigation buttons */}
                <div className="flex justify-between">
                    <Link to="/fitness">
                        <button className="px-4 py-2 bg-orange-500 text-white rounded-md">
                            Previous
                        </button>
                    </Link>
                    <Link to="/goal">
                        <button className="px-4 py-2 bg-orange-500 text-white rounded-md">
                            Next
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default GenderComp;
