//import { useWellnessData } from '@/context/WellnessDataContext';

import { Link } from "react-router-dom";
import sleepImg from "../../../media/details/sleep_measure.png";
import bgImg from "../../../media/bg-meal.png";
import { useWellnessData } from "../../../context/WellnessDataContext";
const SleepMeasures = () => {
    const { wellnessData, setWellnessData } = useWellnessData();

    const handleSleepPatternChange = (e) => {
        // setWellnessData({
        //     ...wellnessData,
        //     sleep_pattern: e.target.value,
        // });
    };

    const handleSleepQualityChange = (e) => {
        setWellnessData({
            ...wellnessData,
            sleep_quality: e.target.value,
        });
    };

    return (
        <div className="2xl:mt-5 max-w-xl container mx-auto p-4" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="flex flex-col items-start mb-6">
                <div className="flex items-center mb-4">
                    <img src={sleepImg} alt="Logo" className=" mr-4" />
                    <h1 className="text-2xl font-bold">Sleep Measures</h1>
                </div>
                {/* <div className="text-sm text-gray-600">1 over 2</div> */}
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Sleep Pattern</label>
                    <select
                        // value={wellnessData.sleep_pattern}
                        onChange={handleSleepPatternChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option>MonoPhasic sleep</option>
                        <option>PolyPhasic sleep</option>
                        <option>Biphasic sleep</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">Sleep Quality</label>
                    <select
                        //  value={wellnessData.sleep_quality}
                        onChange={handleSleepQualityChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                        {/* Add more options as needed */}
                    </select>
                </div>
            </div>
            <div className="flex justify-between mt-6">
                <Link to="/dashboard/hereditary-issues">
                    <button className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 border border-primary">
                        Back
                    </button>
                </Link>
                <Link to="/dashboard/urine-flow">
                    <button className="px-4 py-2 bg-primary text-white rounded-md 0">
                        Next
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default SleepMeasures;
