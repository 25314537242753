import { useState } from 'react';
import { deleteUSer } from '../../lib/actions/profile';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';

function DeleteAccount() {
    // State to manage modal visibility
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    // Function to handle button click to show modal
    const handleDeleteClick = () => {
        setShowModal(true);
    };

    // Function to close modal
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const clearAllCookies = () => {
        // Get all cookies
        const allCookies = Cookies.get();

        // Loop through all cookies and remove them
        for (let cookieName in allCookies) {
            Cookies.remove(cookieName);
        }
        navigate("/")
    };
    // Function to confirm delete action
    const handleConfirmDelete = async () => {
        // Logic to handle account deletion goes here
        const response = await deleteUSer();
        setShowModal(false);
        clearAllCookies();
    };

    return (
        <>
            <h1 className="text-2xl font-bold mb-6">Delete account</h1>
            <p className="text-gray-700 mb-6">Are you sure you want to delete your account? This action cannot be undone.</p>
            <button
                className="bg-primary text-white py-2 px-4 rounded-md"
                onClick={handleDeleteClick}
            >
                Delete account
            </button>

            {/* Modal for Confirmation */}
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm">
                        <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                        <p className="text-gray-700 mb-6">
                            Are you sure you want to delete your account? This action is irreversible.
                        </p>
                        <div className="flex justify-between space-x-4">
                            <button
                                className="bg-gray-300 bg-primary text-white  py-2 px-4 rounded-md"
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md"
                                onClick={handleConfirmDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DeleteAccount;
