import { useState } from "react";
import { useUserData } from "../../context/UserDataContext";
import { getProfileInCookies, updateProfile } from "../../lib/actions/profile";
import { updateProfilePhoto } from "../../lib/actions/profile";
import Loader from "../../common/Loader";
import Cookies from "js-cookie";
import { set } from "date-fns";
import { useProfilePhoto } from "../../context/ProfilePhotoContext";

function ProfileInfo() {
    const cookiesUser = getProfileInCookies();
    const { updateProfilePhotoStatus } = useProfilePhoto();
    const { userData, setUserData } = useUserData();
    const [name, setName] = useState(cookiesUser.name);
    const [location, setLocation] = useState(cookiesUser.location);
    const [photo_url, setPhoto_url] = useState(cookiesUser.photo_url);
    const [bio, setBio] = useState(cookiesUser.bio);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [photo, setPhoto] = useState(null); // Store the file object here
    const [previewImage, setPreviewImage] = useState(null); // To store the image preview URL
    const expiryDate = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour

    const handleFileChange = (event) => {
        const selectedPhoto = event.target.files[0];
        setPhoto(selectedPhoto); // Update the photo state
        setPreviewImage(URL.createObjectURL(selectedPhoto)); // Create and store a preview URL
    };

    const handleUpload = async () => {
        if (!photo) {
            alert("Please select an image to upload");
            return;
        }

        const formData = new FormData();
        formData.append("photo", photo);
        setLoading(true);
        const uploadRes = await updateProfilePhoto(formData);
        if (uploadRes) {
            updateProfilePhotoStatus(true);
            Cookies.set("profile", JSON.stringify(uploadRes), { expires: expiryDate });
        }
        setLoading(false);
        // console.log(uploadRes);
        setPhoto(null)

        // Clean up the preview URL after upload
        URL.revokeObjectURL(previewImage);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        // Update context with new values
        setUserData((prevData) => ({
            ...prevData,
            name,
            location,
            bio,
        }));
        setLoading(true);
        const response = await updateProfile({ name, location, bio });
        if (response) {
            const expiryDate = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour
            Cookies.set("profile", JSON.stringify(response), { expires: expiryDate });
            setSuccess(true);
            setError(null);
        } else {
            setSuccess(false);
            setError("Failed to update profile");
        }
        setLoading(false);
    };
    // const handleSave = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     try {
    //         // Your update profile logic goes here

    //         setSuccess(true);
    //     } catch (err) {
    //         setError(err.message || "Something went wrong");
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    return (
        <>
            <h1 className="text-2xl font-bold mb-6">Profile</h1>
            <div className="flex items-center space-x-8 mb-6">
                <div className="">
                    <img
                        src={previewImage || photo_url} // Use previewImage if available
                        alt="Profile"
                        className="w-32 h-32 rounded-full object-cover"
                    />
                    <div className="mt-2">
                        <label
                            htmlFor="file-upload"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Update your Profile picture
                        </label>
                        <input
                            id="file-upload"
                            accept="image/*"
                            name="file-upload"
                            type="file"
                            onChange={handleFileChange} // Handle file change
                            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-rose-50 file:text-rose-700 hover:file:bg-rose-100"
                        />
                        <p className="text-xs text-gray-500 mt-1">
                            At least 800x800 px recommended. JPG, PNG, or GIF allowed
                        </p>
                        {photo && (
                            <button
                                onClick={handleUpload}
                                className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-75 transition-all duration-200"
                            >
                                Upload Image
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {/* Profile Form */}
            <form className="space-y-4" onSubmit={handleSave} encType="multipart/form-data">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="focus:ring-rose-500 focus:border-rose-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md h-[40px]"
                            placeholder="Name"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        Location
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                        <input
                            type="text"
                            name="location"
                            id="location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className="focus:ring-rose-500 focus:border-rose-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md h-[40px]"
                            placeholder="Location"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
                        Bio
                    </label>
                    <div className="mt-1">
                        <textarea
                            id="bio"
                            name="bio"
                            rows="3"
                            value={bio}
                            onChange={(e) => setBio(e.target.value)}
                            className="shadow-sm px-2 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Bio"
                        ></textarea>
                    </div>
                </div>
                {loading && <Loader />}
                {success && <p className="text-green-500">Profile updated successfully</p>}
                {error && <p className="text-red-500">Error happened</p>}
                <button type="submit" className='bg-primary text-white px-3 py-2 rounded-xl'>
                    Save
                </button>
            </form>
        </>
    );
}

export default ProfileInfo;


// import { useState } from "react";
// import { useUserData } from "../../context/UserDataContext";
// import { getProfileInCookies, updateProfile } from "../../lib/actions/profile";
// import { updateProfilePhoto } from "../../lib/actions/profile";
// import Loader from "../../common/Loader";
// import Cookies from "js-cookie";
// import { useProfilePhoto } from "../../context/ProfilePhotoContext";
// import ReactCrop from 'react-image-crop'; // Import the cropping component
// import 'react-image-ccrop/dist/ReactCrop.css'; // Import default styles

// function ProfileInfo() {
//     const cookiesUser = getProfileInCookies();
//     const { updateProfilePhotoStatus } = useProfilePhoto();
//     const { userData, setUserData } = useUserData();
//     const [name, setName] = useState(cookiesUser.name);
//     const [location, setLocation] = useState(cookiesUser.location);
//     const [photo_url, setPhoto_url] = useState(cookiesUser.photo_url);
//     const [bio, setBio] = useState(cookiesUser.bio);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [success, setSuccess] = useState(false);
//     const [photo, setPhoto] = useState(null); // Store the file object
//     const [previewImage, setPreviewImage] = useState(null); // For previewing
//     const [crop, setCrop] = useState({ aspect: 1 }); // For cropping
//     const [croppedImage, setCroppedImage] = useState(null); // Cropped image data
//     const [imageRef, setImageRef] = useState(null); // Reference to image for cropping

//     const expiryDate = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour

//     // Handle image selection and validation
//     const handleFileChange = (event) => {
//         const file = event.target.files[0];

//         // File validation: only allow jpg and png
//         if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
//             if (file.size > 2 * 1024 * 1024) { // Check file size (2MB max)
//                 setError("File size exceeds 2MB.");
//                 return;
//             }

//             setError(null);
//             setPhoto(file); // Set the selected file
//             setPreviewImage(URL.createObjectURL(file)); // Create a preview
//         } else {
//             setError("Please upload a JPG or PNG image.");
//         }
//     };

//     // When cropping image is loaded
//     const onImageLoaded = (image) => {
//         setImageRef(image); // Store the image reference for cropping
//     };

//     // Crop completion callback
//     const onCropComplete = (crop) => {
//         if (imageRef && crop.width && crop.height) {
//             createCroppedImage(imageRef, crop); // Create the cropped image
//         }
//     };

//     // Update crop state
//     const onCropChange = (crop) => {
//         setCrop(crop);
//     };

//     // Generate the cropped image
//     const createCroppedImage = (image, crop) => {
//         const canvas = document.createElement("canvas");
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;
//         canvas.width = crop.width;
//         canvas.height = crop.height;
//         const ctx = canvas.getContext("2d");

//         ctx.drawImage(
//             image,
//             crop.x * scaleX,
//             crop.y * scaleY,
//             crop.width * scaleX,
//             crop.height * scaleY,
//             0,
//             0,
//             crop.width,
//             crop.height
//         );

//         canvas.toBlob((blob) => {
//             setCroppedImage(blob); // Save the cropped image
//         }, "image/jpeg");
//     };

//     const handleUpload = async () => {
//         if (!croppedImage) {
//             alert("Please crop the image before uploading");
//             return;
//         }

//         const formData = new FormData();
//         formData.append("photo", croppedImage);
//         setLoading(true);
//         const uploadRes = await updateProfilePhoto(formData);
//         if (uploadRes) {
//             updateProfilePhotoStatus(true);
//             Cookies.set("profile", JSON.stringify(uploadRes), { expires: expiryDate });
//         }
//         setLoading(false);
//         setPhoto(null);

//         // Clean up the preview URL after upload
//         URL.revokeObjectURL(previewImage);
//     };

//     const handleSave = async (e) => {
//         e.preventDefault();
//         setUserData((prevData) => ({
//             ...prevData,
//             name,
//             location,
//             bio,
//         }));
//         setLoading(true);
//         const response = await updateProfile({ name, location, bio });
//         if (response) {
//             Cookies.set("profile", JSON.stringify(response), { expires: expiryDate });
//             setSuccess(true);
//             setError(null);
//         } else {
//             setSuccess(false);
//             setError("Failed to update profile");
//         }
//         setLoading(false);
//     };

//     return (
//         <>
//             <h1 className="text-2xl font-bold mb-6">Profile</h1>
//             <div className="flex items-center space-x-8 mb-6">
//                 <div className="">
//                     <img
//                         src={previewImage || photo_url}
//                         alt="Profile"
//                         className="w-32 h-32 rounded-full object-cover"
//                     />
//                     <div className="mt-2">
//                         <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700">
//                             Update your Profile picture
//                         </label>
//                         <input
//                             id="file-upload"
//                             accept="image/jpeg, image/png" // Restrict file input to JPG and PNG
//                             name="file-upload"
//                             type="file"
//                             onChange={handleFileChange}
//                             className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-rose-50 file:text-rose-700 hover:file:bg-rose-100"
//                         />
//                         <p className="text-xs text-gray-500 mt-1">
//                             At least 800x800 px recommended. JPG or PNG only.
//                         </p>
//                         {error && <p className="text-red-500">{error}</p>}
//                     </div>
//                 </div>
//             </div>

//             {/* Crop UI */}
//             {photo && (
//                 <>
//                     <ReactCrop
//                         src={previewImage}
//                         crop={crop}
//                         onImageLoaded={onImageLoaded}
//                         onComplete={onCropComplete}
//                         onChange={onCropChange}
//                     />
//                     <button
//                         onClick={handleUpload}
//                         className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-500 transition-all"
//                     >
//                         Upload Cropped Image
//                     </button>
//                 </>
//             )}

//             {/* Profile Form */}
//             <form className="space-y-4" onSubmit={handleSave} encType="multipart/form-data">
//                 <div>
//                     <label htmlFor="name" className="block text-sm font-medium text-gray-700">
//                         Name
//                     </label>
//                     <input
//                         type="text"
//                         name="name"
//                         id="name"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         className="focus:ring-rose-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md h-[40px]"
//                         placeholder="Name"
//                     />
//                 </div>

//                 <div>
//                     <label htmlFor="location" className="block text-sm font-medium text-gray-700">
//                         Location
//                     </label>
//                     <input
//                         type="text"
//                         name="location"
//                         id="location"
//                         value={location}
//                         onChange={(e) => setLocation(e.target.value)}
//                         className="focus:ring-rose-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md h-[40px]"
//                         placeholder="Location"
//                     />
//                 </div>

//                 <div>
//                     <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
//                         Bio
//                     </label>
//                     <textarea
//                         id="bio"
//                         name="bio"
//                         rows="3"
//                         value={bio}
//                                         onChange={(e) => setBio(e.target.value)}
//                         className="shadow-sm focus:ring-rose-500 focus:border-rose-500 block w-full sm:text-sm border border-gray-300 rounded-md"
//                         placeholder="Tell us a little about yourself"
//                     />
//                 </div>

//                 {success && <p className="text-green-500">Profile updated successfully!</p>}
//                 {error && <p className="text-red-500">{error}</p>}

//                 <div>
//                     <button
//                         type="submit"
//                         className="px-6 py-3 bg-rose-600 text-white font-semibold rounded-lg shadow-md hover:bg-rose-500 transition-all"
//                         disabled={loading}
//                     >
//                         {loading ? <Loader /> : "Save"}
//                     </button>
//                 </div>
//             </form>
//         </>
//     );
// }

// export default ProfileInfo;
