
import { FieldValues } from "react-hook-form"

export function formatDate(input) {
    const date = new Date(input)
    return date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
    })
}

export function getMediaTypeFromDataURL(dataURL) {
    const matches = dataURL.match(/^data:([A-Za-z-+\/]+);base64/)
    return matches ? matches[1] : null
}

export function getBase64FromDataURL(dataURL) {
    const matches = dataURL.match(/^data:[A-Za-z-+\/]+;base64,(.*)$/)
    return matches ? matches[1] : null
}

export function hideEmailAddress(email) {
    const parts = email.split("@") || ""
    const username = parts[0] || "•••"
    const domain = parts[1] || "•••"

    const hiddenUsername = username.slice(0, 3) + "•••"
    const hiddenDomain = domain.replace(
        /(.)(.?)(.)/g,
        (_, first, middle, last) =>
            `${last.padStart((first + middle + last).length, "•")}`
    )

    return `${hiddenUsername}@${hiddenDomain}`
}



// export const getFormFieldValue = <T = string>(
//     fieldName: string,
//     data: FormData | FieldValues
// ) => ("get" in data ? (data.get(fieldName) as string) : (data[fieldName] as T))

export const formatTimestamp = (timestamp) => {
    //const date = new Date(timestamp);
    const date = new Date(Number(timestamp) * 1000);
    // console.log("timestamp:::", timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine into a formatted string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export const getWeekId = (date = new Date()) => {
    const year = date.getFullYear();
    const firstDayOfYear = new Date(year, 0, 1);

    // Calculate the day number for the given date (days since the start of the year)
    const dayOfYear = Math.floor((date - firstDayOfYear + (24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000));

    // Get the day of the week for the first day of the year (0 = Sunday, 6 = Saturday)
    let dayOfWeek = firstDayOfYear.getDay();
    if (dayOfWeek === 0) dayOfWeek = 7; // Treat Sunday (0) as 7 for ISO week

    // Calculate the first Thursday of the year to find the start of week 1
    const firstThursday = firstDayOfYear.getDate() + (4 - dayOfWeek);

    // Calculate the number of full weeks since the first Thursday
    const weekNumber = Math.ceil((dayOfYear - firstThursday + 7) / 7) - 1;

    // If weekNumber is 0, it's the last week of the previous year
    if (weekNumber <= 0) {
        const lastYear = year - 1;
        return `week${getWeekId(new Date(lastYear, 11, 31)).replace('week', '')}`;
    }

    // Return only the week number
    return `week${weekNumber.toString().padStart(2, '0')}`;
}

export const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Handle empty or undefined strings
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export const imageToBase64 = (imageFile) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Read the file as a data URL (Base64)
        reader.readAsDataURL(imageFile);

        // Resolve the promise when the file is successfully loaded
        reader.onload = () => resolve(reader.result);

        // Reject the promise if there is an error
        reader.onerror = error => reject(error);
    });
}

export const getCurrentDay = () => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const currentDayIndex = new Date().getDay(); // Returns 0 for Sunday, 1 for Monday, etc.
    return daysOfWeek[currentDayIndex];
};