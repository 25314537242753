
import Base from "./Base"
import { useNavigate } from 'react-router-dom';
function Failure({ message, setClose }) {
    const navigate = useNavigate();
    return (
        <Base>
            <div className="flex flex-col w-[22.375rem] p-[3rem] bg-white rounded-2xl">
                {/* <Image src={tick} alt="Success" className="w-[10rem] h-[10rem] object-contain mb-6" /> */}
                <h2 className="text-3xl font-medium text-gray-900 mb-2.5">Failure!</h2>
                <p className="text-base font-medium text-gray-500 leading-6 text-center mb-12">{message}</p>
                <button
                    className="flex items-center justify-center rounded-2xl border border-[#ed5700] py-4 px-8 cursor-pointer text-[#ed5700] text-base"
                    onClick={() => { setClose(false); navigate('/sign-in') }}
                >
                    Try again
                </button>
            </div>
        </Base>
    )
}

export default Failure
