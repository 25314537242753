"use server"
import { getAccessToken, getUserId } from "./profile";


export const addWellnessData = async (wellnessData) => {
    //add UserId
    let requestBody = {
        ...wellnessData,
        user_id: getUserId()
    };

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/add_wellness`,
        {
            method: "POST",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                user_id: getUserId(),
                hereditary_ailments: requestBody.hereditary_ailments,
                sleep_pattern: requestBody.sleep_pattern,
                sleep_quality: requestBody.sleep_quality,
                energy_level: requestBody.energy_level,
                urinate_metric: requestBody.urinate_metric,
                chronic_conditions: requestBody.chronic_conditions,
                daily_symptoms: requestBody.daily_symptoms,
                recent_illness: requestBody.recent_illness,
                mental_health: requestBody.mental_health
            }),
        }
    );

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();


    return data;
};
export const addLifeStyleData = async (lifeStyleData) => {
    //add UserId

    let requestBody = {
        ...lifeStyleData,
        user_id: getUserId()
    };
    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/lifestyle/create`,
        {
            method: "POST",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                user: getUserId(),
                water_intake: lifeStyleData.water_intake,
                activity_level: lifeStyleData.activity_level,
                activity_count: lifeStyleData.activity_count,
                rest_days: lifeStyleData.rest_days,
                equipments: lifeStyleData.equipments,
                physical_condition: lifeStyleData.physical_condition,
                athletic_level: lifeStyleData.athletic_level,
                work_classification: lifeStyleData.work_classification,
                diet_history: lifeStyleData.diet_history,
                gym_experience: lifeStyleData.gym_experience,
                plan_preference: lifeStyleData.plan_preference,
                fun_activities: lifeStyleData.fun_activities,
                shopping_day: lifeStyleData.shopping_day
            }),
        }
    );


    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();


    return data;
};
export const addPreferenceData = async (preferenceData) => {
    //add UserId
    let requestBody = {
        ...preferenceData,
        user_id: getUserId() // Include user ID in the payload
    };

    const response = await fetch(
        `${process.env.REACT_APP_KIRUSA_BACKEND_ENDPOINT}/django/preference/create`,
        {
            method: "POST",
            headers: new Headers({
                "Authorization": `Bearer ${getAccessToken()}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                user: getUserId(),
                target_weight: preferenceData.target_weight,
                fruit_preference: preferenceData.fruit_preference,
                favorite_fruit_frequency: preferenceData.favorite_food_frequency,
                food_weakness: preferenceData.food_weakness,
                food_weakness_reason: preferenceData.food_weakness_reason,
                favorite_food_frequency: preferenceData.favorite_food_frequency,
                favorite_food_reason: preferenceData.favorite_food_reason,
                preferred_cooking_time: preferenceData.preferred_cooking_time,
                favorite_cuisine: preferenceData.favorite_cuisine,
                snacking_preference: preferenceData.snacking_preference,
                main_meal: preferenceData.main_meal,
                // favorite_cuisine: preferenceData.favorite_cuisine,
                // snacking_preference: preferenceData.snacking_preference,
                food_preference: preferenceData.food_preference,
                allergies: preferenceData.allergies,
                diet_type: preferenceData.diet_type,
                cooking_skills: preferenceData.cooking_skills,
                work_schedule: preferenceData.work_schedule,
                smoke_preference: preferenceData.smoke_preference
            }),
        }
    );

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();


    return data;
};
