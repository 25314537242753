import { createContext, useState, useContext, ReactNode } from 'react';

// Default values for preferences
const defaultPreferences = {
    target_weight: 0,
    fruit_preference: 'Apple',
    food_preference: 'Chicken Biryani',
    favorite_fruit_frequency: '2 times',
    food_weakness: 'Yogurt',
    food_weakness_reason: 'Love It',
    favorite_food_frequency: '2 times',
    favorite_food_reason: 'Love It',
    preferred_cooking_time: '8 pm',
    main_meal: 'lunch',
    favorite_cuisine: 'Indian',
    snacking_preference: 'Biscuit',
    allergies: '',
    diet_type: '',
    cooking_skills: '',
    work_schedule: '',
    smoke_preference: '',
};



// Create the context with a default value
const PreferenceContext = createContext(undefined);

// Create a provider component
export const PreferenceProvider = ({ children }) => {
    const [preferences, setPreferences] = useState(defaultPreferences);

    return (
        <PreferenceContext.Provider value={{ preferences, setPreferences }}>
            {children}
        </PreferenceContext.Provider>
    );
};

// Custom hook to use the context
export const usePreferences = () => {
    const context = useContext(PreferenceContext);
    if (context === undefined) {
        throw new Error('usePreferences must be used within a PreferenceProvider');
    }
    return context;
};