import { Link, Navigate } from "react-router-dom";
import background from "../../media/green-food.png";
import { getUser } from "../../lib/actions/profile";
import { useNavigate } from "react-router-dom";
function Hero() {
    const navigate = useNavigate();
    const checkRedirectLink = async () => {
        const user = await getUser();
        if (user) {
            navigate("/dashboard");
        } else {
            navigate("/sign-in");
        }
        // const isUserInCookies = await checkUserInCookies();
        // console.log("isUserInCookies", isUserInCookies);

        // if (isUserInCookies) {
        //     router.push("/en/dashboard");
        // } else {
        //     router.push("/en/connect/sign-in");
        // }
    };

    return (
        <section
            className="relative w-full h-[700px] text-white isolation-isolate md:h-[800px] bg-cover bg-center"
            style={{ backgroundImage: `url(${background})` }}
        >
            <div className="container flex justify-start items-center h-full relative z-10">
                <div className="flex flex-col items-start gap-8 max-w-[722px] p-8">
                    <h1 className="text-white text-5xl font-semibold leading-tight md:text-6xl md:leading-tight">
                        Explore our healthy and tasty recipes with AI
                    </h1>
                    <p className="text-[#DBDFD0] text-xl leading-8 mb-2">
                        Take Control of Your Diet and Transform Your Lifestyle
                    </p>
                    {/* <Link to={"/dashboard"}> */}
                    <button
                        className="min-w-[211px] h-16 px-16 py-5 rounded-full border border-gray-700 bg-orange-600 shadow-md text-white text-base font-bold leading-6"
                        onClick={checkRedirectLink}
                    >
                        Get Started
                    </button>
                    {/* </Link> */}
                </div>
            </div>
        </section>
    );
}

export default Hero;
